@import (reference) '../../styles/colors.less';

.imgWrapper {
  border-radius: 50%;
  overflow: hidden;
  padding: 10px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.ring {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -10px;
  border-radius: inherit;

  &.bronze {
    background: linear-gradient(to right, @ambassador_bronze, lighten(@ambassador_bronze, 20%));
  }

  &.silver {
    background: linear-gradient(to right, @ambassador_silver, lighten(@ambassador_silver, 20%));
  }

  &.gold {
    background: linear-gradient(to right, @ambassador_gold, lighten(@ambassador_gold, 20%));
  }

  &.blue {
    background: linear-gradient(to right, @ambassador_blue, lighten(@ambassador_blue, 20%));
  }
}

.img {
  border-radius: 50%;
  position: relative;
  aspect-ratio: 1;
}
.imgDefault {
  border-radius: 50%;
  background: #d9d9d9;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;