@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

.dot {
  display: inline-block;
  border-radius: 50%;
  flex: 0 0 auto;
}

.bronze {
  background: @ambassador_bronze;
}

.silver {
  background: @ambassador_silver;
}

.gold {
  background: @ambassador_gold;
}

.blue {
  background: @ambassador_blue;
}

.dot__bordered {
  border: solid 0.5px white;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;