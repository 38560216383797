@import (reference) '../../styles/utilities.less';
@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../components/omnia_button/omnia_button.less';

.map {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  min-height: 100%; // must be within a sized wrapper
  isolation: isolate;

  [mapboxgl-children] {
    position: absolute;
    top: 0;
    left: 0;
  }
  :global {
    .awxgl-tooltip {
      z-index: 299;
    }
  }
}

.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  :global {
    .mapboxgl-ctrl-bottom-right > .mapboxgl-ctrl-attrib.mapboxgl-compact {
      &:after {
        right: auto;
        left: -250px;
      }
    }
    .mapboxgl-ctrl-top-left,
    .mapboxgl-ctrl-bottom-right {
      z-index: 300;
    }
    .mapboxgl-ctrl-bottom-right {
      .mapboxgl-ctrl-attrib.mapboxgl-compact {
        display: none;
      }
    }
  }
}

.wrapper__noSatelliteToggle {
  :global {
    .mapboxgl-ctrl-bottom-right > .mapboxgl-ctrl-attrib.mapboxgl-compact {
      &:after {
        right: 0;
      }
    }
  }
}
.wrapper__noGeocoder {
  @media @sm {
    :global {
      .mapboxgl-ctrl-top-left {
        top: 50px;
      }
    }
  }
}

.wrapper__updatedMapControls {
  :global {
    .mapboxgl-ctrl-top-right {
      top: 36px;
      right: 12px;
      z-index: 300;

      & > div:nth-child(2) {
        top: -27px;
        margin: 0 !important;
        position: relative;
      }
    }
    .mapboxgl-ctrl .mapboxgl-ctrl-group {
      z-index: 300;
    }
    .mapboxgl-ctrl-bottom-right {
      bottom: -12px;

      @media @sm {
        bottom: -1px;
      }

      > div {
        margin: 0 !important;
        right: 12px;
        position: relative;
      }
      // geolocation button
      > div:nth-child(1) {
        border-radius: 0 0 8px 8px;
        // only 1px shows, but I'm not sure why
        border-top: 1px solid #d7d7db;

        @media @sm {
          border-radius: 8px;
          border-top: none;
        }
      }
      // zoom & pan buttons
      > div:nth-child(2) {
        border-radius: 8px 8px 0 0;
        top: -44px !important;

        @media @sm {
          top: -144px !important;
        }
      }

      .mapboxgl-ctrl-group {
        box-shadow: none;
        background: transparent;

        button {
          background-color: #fafafacc;
          backdrop-filter: blur(6px);
          width: 44px;
          height: 44px;
          border: none;
        }
        button + button {
          border-top: 1px solid #a1a1aa80;
        }
      }
    }
    .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib.mapboxgl-compact {
      display: block;
    }
    .mapboxgl-ctrl-attrib.mapboxgl-compact {
      opacity: 0;
    }
  }
}

.lakeDetails {
  position: absolute;
  top: 6px;
  right: 6px;

  @media @sm {
    top: 12px;
    right: 12px;
  }

  z-index: 400;
  :global {
    .ant-card {
      border-radius: 4px;
    }
  }
}

.lakeDetails__geoCoderCentered {
  top: 54px;
}

.legend {
  position: absolute;
  z-index: 99;
  bottom: 35px;
  left: 0;

  @media @sm {
    right: auto;
    bottom: auto;
    top: 12px;
    left: 265px;
    margin-right: 12px;
  }
}

.legend__isWebview {
  bottom: 35px;

  @media @sm {
    left: 100px;
  }
}

.legend__noGeocoder {
  @media @sm {
    left: 12px;
  }
}

.satelliteToggle {
  position: absolute;
  z-index: 300;
  right: 12px;
  bottom: 12px;
  padding: 6px;
  font-size: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;

  @media @sm {
    bottom: 32px;
  }

  :global {
    .ant-switch {
      min-width: 40px;
    }
  }
}
.waterTempToggle {
  position: absolute;
  z-index: 300;
  right: 117px;
  bottom: 12px;
  padding: 6px;
  font-size: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;

  @media @sm {
    bottom: 32px;
  }

  :global {
    .ant-switch {
      min-width: 40px;
    }
  }
}
.new {
  position: absolute;
  top: -6px;
  left: -6px;
}

.upgradeButtons {
  background: white;
  display: grid;
  font-size: 14px;
  place-content: center;
  padding: 10px 6px;
  position: absolute;
  z-index: 299;
  bottom: 18px;
  left: 12px;
  border-radius: 8px;
  font-weight: 500;
  border: 1.5px solid #d8d8d8;

  @media @sm {
    display: none;
  }

  > span {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 6px;
  }
}

.trialButton {
  padding: 4px 8px;
  margin-bottom: 6px;
}

.upgradeButton {
  padding: 4px 8px;

  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 45%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: shimmer 10s infinite;
  }

  @keyframes shimmer {
    30% {
      left: -150%;
    }
    40% {
      left: 100%;
    }
    50% {
      left: 150%;
    }
    100% {
      left: 150%;
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;