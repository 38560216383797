.img {
  margin-bottom: 12px;
}

.title {
  margin-bottom: 0;
  line-height: 1.2;

  em {
    font-weight: bold;
    font-style: normal;
  }
}
.counties {
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
}

.link {
  &:hover {
    text-decoration: underline;

    .counties {
      text-decoration: underline;
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;