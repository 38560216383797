.item {
  display: flex;
  padding: 24px;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  background-color: #fff;
}

.img {
  margin-right: 12px;
  width: 30%;
  flex-shrink: 0;
  flex-grow: 0;
}

.title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 12px;
}

.price {
  font-weight: bold;
  margin-bottom: 12px;
}

.priceCompare {
  opacity: 0.5;
  margin-left: 0.3em;
  white-space: nowrap;
}

.price__sale {
  color: #d8363e;
}

.input {
  width: 70px;
  margin-right: 12px;
}

.remove {
  appearance: none;
  border: 0;
  padding: 0;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0.6;
  background-color: transparent;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.upsell {
  background-color: #f7f7f7;
  padding: 12px;
}
.upsellProduct {
  background-color: #fff;
  border: 1px solid rgba(222, 222, 222, 1);
  padding: 12px;
  position: relative;

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-left: -10px;
  }
  &:before {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(222, 222, 222, 1);
    border-width: 11px;
    margin-left: -11px;
  }
}

.upsellHeading {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;

  span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
  }
}
.upsellContent {
  display: flex;
  gap: 12px;
  align-items: center;
}
.upsellLeft,
.upsellRight {
  width: calc(50% - 6px);
}
.upsellLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.upsellImg {
  width: 76px;
}
.upsellTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}
.upsellPrice {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: right;
  margin-bottom: 12px;
}
.inputWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  gap: 10px;

  button {
    width: 50%;
  }
}
.upsellClose {
  position: absolute;
  top: 12px;
  right: 12px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;