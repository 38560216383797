@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/colors.less';
@import (reference) '../../styles/typography.less';

@heroHeight: 320px;

.fishingReportCard {
  font-size: 12px;
  padding: 12px;
  box-shadow: 0 2px 0 rgb(0 0 0 / 15%);
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  border: 1px lightgrey solid;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.displayNone {
  display: none;
}

.card {
  list-style: none;
  border-radius: 8px;
  width: 323px;
  height: auto;
  cursor: pointer;
}

.hero {
  margin: -12px -12px 8px;
}

.mediaContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  align-items: center;
  justify-content: center;
  height: @heroHeight;
  transition: height 350ms ease;
}

.imgBackground {
  transition: height 350ms ease;
  position: absolute;
  filter: blur(15px);
  object-fit: cover;
  width: 100%;
  height: @heroHeight;
}

.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: @heroHeight;
  width: 100%;
}

.image {
  position: relative;
  margin-inline: auto;
  cursor: pointer;
}

.reportDetails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.listDetails {
  display: flex;
  flex-direction: column;
  padding-inline-start: 0;
  margin-bottom: 0;

  li {
    line-height: unset;
    width: 100%;
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    font-size: 14px;

    p {
      margin-bottom: 5px;
    }
  }

  li:last-of-type {
    border-bottom: none;
  }

  .species {
    padding-top: 2px;
    .detail {
      display: flex;
      flex-wrap: wrap;
    }
    img {
      width: 40px;
      margin-right: 6px;
    }
  }

  .technique {
    p:last-child {
      text-align: right;
    }
    p:first-child {
      padding-right: 12px;
    }
  }
}

.reportProperty {
  font-weight: 600;
}

.seasonIcon {
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.7);
}

.subDetails {
  display: flex;
  align-items: center;
  gap: 0 15px;
  padding-bottom: 16px;
  min-width: 290px;
}

.outingDate {
  padding-top: 4px;
  :global {
    .anticon-calendar {
      color: #657d7d;
    }
  }
}

.productCard {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  overflow: hidden;
}

.product {
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex: 1;
}

.productImgContainer {
  margin-top: 5px;
  position: relative;
  margin-right: 5px;
  aspect-ratio: 1;
  width: 94px;
}

.productImg {
  border: solid 1px #dcdcdc;
  border-radius: 8px;
  background-color: #ffffff;
  &:hover {
    cursor: pointer;
  }
}

.hotbaitContainer {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 2px;
  border-top-left-radius: 8px;
  background-color: #fb2422;
  color: #fff;
  clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 0% 100%);
  font-size: 11.5px;
  padding-inline: 4px 10px;
  .fire {
    color: #fff;
    font-size: 7px;
    margin-right: 4px;
    margin-top: -1px;
  }
}

.cardContents {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  height: unset;
  margin-bottom: 1px;
  flex: 1;

  p,
  s {
    font-size: 12px;
    line-height: 1.2em;
    @media @sm {
      font-size: clamp(12px, 1.1667vw, 14px);
    }
    margin-bottom: 0;
  }
  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    margin-bottom: 5px;
    color: #000000;
    font-size: 12.5px;
    line-height: 1.2em;

    &:hover {
      text-decoration: underline;
    }

    @media @sm {
      font-size: 14px;
      margin-bottom: unset;
    }
  }

  .price {
    color: #657d7d;
    font-size: 12px;
    line-height: 1.2em;
    @media @sm {
      margin-top: 2px;
      font-size: 14px;
    }
  }
}

.description {
  flex: 1;
}

.sale {
  display: flex;
  .price {
    margin-right: 5px;
    color: #d8363e;
  }
}

.productCardFooter {
  :global {
    .anticon-shopping-cart,
    .anticon-mail {
      display: none;
      @media @md {
        display: block;
        margin-right: 1px;
      }
    }
    .anticon-mail {
      font-size: 13px;
      width: 13px;
    }
    .anticon-shopping-cart {
      width: 14px;
    }
  }
}

.chipContainer {
  position: absolute;
  bottom: 6px;
  left: 6px;
  z-index: 3;
  display: flex;
  width: max-content;
  padding: 3px 9px;
  gap: 10px;
}

.chipContainer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  z-index: -1;
}

.chipDetails {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  color: #657d7d;
  gap: 1px;

  :global {
    .anticon-environment {
      color: #495f5f;
      margin-right: 2px;
      svg {
        width: 0.9rem;
        @media @sm {
          width: 1rem;
        }
      }
    }
    .anticon-calendar {
      color: #495f5f;
      position: relative;
      top: 0.05em;
      svg {
        width: 1.1em;
      }
    }
  }
}

.userName {
  display: block;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 2px;
}

.chipDetail {
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 4px;

  a {
    &:first-letter {
      text-transform: capitalize;
    }
    .textColor(currentColor);
    &:hover {
      text-decoration: underline;
    }
  }
}

.chipDetail__date {
  .chipDetail();
  align-items: unset;
  span:nth-child(2) {
    margin-right: 4px;
    margin-top: 1px;
  }
}

.reportedOnLake {
  display: flex;
  align-items: center;
  gap: 3px;
  line-height: 1.2;
  margin-bottom: 0;
  cursor: pointer;
  .textColor(rgba(0, 0, 0, 0.7));
  font-size: 14px;

  em {
    text-decoration: underline;
    font-style: normal;
  }
}

.expandIcon {
  appearance: none;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 2px;
  right: 3px;
  border-radius: 50%;
  z-index: 5;

  svg {
    cursor: pointer;
    transition: background-color ease-in-out 150ms;
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }
}

.imgBackground__error {
  background-color: #efefef;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;