@import (reference) './media.less';
@import (reference) './mixins.less';

/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local(''),
    url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-100.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local(''),
    url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-100italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-100italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local(''),
    url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-300italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local(''),
    url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local(''),
    url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-500italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local(''),
    url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-700italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('https://omnia-fishing.imgix.net/fonts/roboto-v27-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  min-height: 100%;
}

// body {
//   // font-family: 'Roboto', sans-serif;
// }
// font weights loaded:
//   300 light
//   400 normal
//   600 semibold
//   700 bold

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

.site {
  display: flex;
  min-height: 100dvh;
  flex-direction: column;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;

  > * {
    flex: 1;
  }
}

.contentWrapper {
  width: 100%;
  max-width: 1354px;
  margin-inline: auto;
  padding-inline: 12px;
  position: relative;
}

.contentWrapper__noPadding {
  padding-inline: 0;
}

.contentWrapper__mobileNoPadding {
  padding-inline: 0;

  @media @sm {
    padding-inline: 12px;
  }
}

.contentWrapper__desktopNoPadding {
  @media @sm {
    padding-inline: 0;
  }
}

.contentWrapper__noOverflow {
  overflow: hidden;
}

.contentWrapperBreakout {
  margin-inline: -12px;
}

.link {
  color: #006b99;
  cursor: pointer;

  &:hover {
    color: #1b81a6;
  }
  &:active {
    color: #004c73;
  }
}

.link__light {
  color: #c0ecff;
  cursor: pointer;

  &:hover {
    color: darken(#c0ecff, 10%);
  }
  &:active {
    color: darken(#c0ecff, 15%);
  }
}

.h1 {
  .fluid-type(25px, 30px);
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 0.5em;
}

.h2 {
  .fluid-type(18px, 25px);
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: 0.5em;
}

:global {
  .overflow-hidden {
    overflow: hidden;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;