@import (reference) '../../styles/media.less';

.unmounted {
  display: flex;
  position: relative;
}
.carousel {
  overflow: hidden;
}

.carousel__fadedEdges {
  @media @sm {
    :global {
      .flicking-arrow-next {
        background: linear-gradient(270deg, #ffffff 23%, rgba(255, 255, 255, 0) 98.3%);
        width: 130px;
        height: 102%;
      }
      .flicking-arrow-prev {
        background: linear-gradient(90deg, #ffffff 23%, rgba(255, 255, 255, 0) 98.3%);
        width: 130px;
        height: 102%;
      }
    }
  }
}

.carousel__newArrows {
  :global {
    .flicking-arrow-prev {
      &:before {
        content: url('../../assets/active_arrow.png');
        width: unset;
        height: unset;
        background-color: unset;
        transform-origin: unset;
        transform: rotateY(180deg) scale(0.6);
        top: 44%;
        left: 0;
        @media @sm {
          transform: rotateY(180deg) scale(0.85);
        }
      }
      &:after {
        display: none;
      }
    }
    .flicking-arrow-next {
      &:before {
        content: url('../../assets/active_arrow.png');
        width: unset;
        height: unset;
        background-color: unset;
        transform: scale(0.6);
        transform-origin: unset;
        top: 44%;
        right: 0;
        @media @sm {
          transform: scale(0.85);
        }
      }
      &:after {
        display: none;
      }
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;