@import (reference) '../../../../styles/utilities.less';
@import (reference) '../../../../styles/mixins.less';

.lakeDetails__card {
  width: 350px;
}
.details {
  padding: 12px 16px;
}

.h1 {
  font-size: 22px;
  color: #28424f;
  line-height: 1.2;
}

.heart {
  margin-left: 0.3em;
  cursor: pointer;

  &.heart__favorite {
    svg path {
      fill: #d73d27;
    }
  }
}
.fire {
  color: #fb2422;
}

.counties {
  margin-bottom: 12px;
}

.p {
  color: #242b33;
  opacity: 0.45;
  font-size: 12px;
}

.close {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px;
  font-size: 1.6em;
  cursor: pointer;
}

.quickDetails {
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
}

.quickDetail {
  margin-bottom: 0;
  width: 50%;
  display: flex;
  align-items: center;
  gap: 3px;
}

.quickDetailIcon {
  width: 18px;
  display: inline-block;
}
.quickDetailIcon__favorite {
  color: #f3a626;
}

.buttonGroup {
  .flexColumn(@gap: 12px);
}

.plusIcon {
  width: 16px;
  font-size: 16px;
  @media @sm {
    margin-top: -1px;
  }
}

.switchButtonOrder {
  order: 0;
}

.followButton {
  order: 1;
}

.waterTemp {
  margin-bottom: 10px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;