@import (reference) '../../styles/media.less';

.select.select {
  width: 100%;

  .resultImg {
    display: none;
    @media @sm {
      display: block;
    }
  }

  &:global {
    &.ant-select-single .ant-select-selector {
      height: 33px;
      :local {
        .resultImg {
          display: none;
        }
      }
    }
    &.ant-select-multiple .ant-select-selector {
      // hides the ant multi select chips in search box
      .ant-select-selection-item {
        display: none;
      }
      // makes width current search input not stop after 4 characters
      .ant-select-selection-search-input {
        width: fit-content !important;
      }
      .ant-select-selection-search {
        width: max-content !important;
      }
    }
  }
  :global {
    .ant-select-selection-item {
      height: 36px;

      // revert the em highlight below
      em {
        font-weight: normal;
      }
    }
    .ant-select-item-option-content {
      white-space: normal;
    }
    .anticon-check {
      margin-top: 9px;
    }
  }
}

.result {
  display: flex;
  align-items: center;
  min-height: 34px;

  em {
    font-weight: bold;
    font-style: normal;
  }

  span {
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.resultImg {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;