@import (reference) '../../styles/media.less';

.rewards {
  padding: 0 6px 12px 6px;

  @media @sm {
    text-align: right;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  @media @sm {
    flex-wrap: nowrap;
  }
}

.item {
  white-space: nowrap;

  &:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.35);
  }
}

.link {
  display: block;
  padding: 0 6px;
  font-weight: bold;
}
.link__active {
  color: rgba(0, 0, 0, 0.65);
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;