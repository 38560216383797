@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';
@import (reference) '../omnia_button/omnia_button.less';

.nav {
  display: flex;
}

.background {
  background-color: #f4f4f4;
}

.userOverview {
  background-color: #c9e3f0;
  padding-bottom: 0;

  @media @sm {
    padding-bottom: 30px;
  }
}

.lowerSection {
  padding-top: 36px;
  padding-bottom: 0;
  @media @sm {
    padding-top: 48px;
    padding-bottom: 96px;
  }
}

.top {
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  @media @sm {
    flex-direction: row;
  }
}
.userInfo {
  flex: 1;
  display: flex;
  margin-bottom: 24px;

  @media @sm {
    margin-bottom: 0;
  }
}

.rewards {
  flex: 0 0 auto;
  background-color: #042930;
  color: #fff;
  padding: 18px 24px;
}
.rewardsHeading {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 12px;
}
.rewardsTotal {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: right;
}

.totalCredit {
  color: #e84b25;
}

.premiumExpires {
  padding-top: 12px;
}

.avatar {
  flex: 0 0 auto;
  padding-top: 6px;
}

.profile {
  margin-left: 12px;
}

.profileItemList {
  display: flex;
}

.name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0;
}
.titles {
  text-transform: uppercase;
  margin-bottom: 0;
}
.userSpecies {
  margin-bottom: 12px;
}

.profileActions {
  margin-bottom: 24px;
  font-weight: bold;

  :global {
    .ant-divider-vertical {
      margin: 0px 7px;
    }
  }
}

.divider__dark {
  background-color: currentColor;
}

.fishingReports {
  list-style: none;
  margin: 0;
  padding: 0;
}

.fishingReport {
  padding: 12px 0;

  & + & {
    border-top: 1px solid #d9d9d9;
  }
}

.fishingReportsVideo {
  max-width: 800px;
  margin-inline: auto;
  margin-bottom: 24px;
}

.fishingReportsVideoClose {
  font-size: 20px;
  cursor: pointer;
}

.tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #bfbfbf;
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  background-color: #f4f4f4;

  .tabs__left & {
    text-align: left;
  }
}

.tabContent {
  padding: 4px 0 24px;
}

.tab {
  display: inline-block;
  padding-block: 8px;
  margin-right: 18px;
  border-bottom: 3px solid #f4f4f4;
  cursor: pointer;
  position: relative;

  @media @sm {
    margin-right: unset;
    margin-inline: 9px;
  }
  .tabs__small & {
    padding-block: 8px;
  }
}

.tab__active {
  color: @omnia_orange_primary;
  border-bottom-color: @omnia_orange_primary;
}

.tabHeading {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin: 0;
  .antialias();
  font-size: 16px;
  .roboto(700);
  line-height: 120%;

  @media @sm {
    font-size: 18px;
  }

  .tab__active & {
    color: @omnia_orange_primary;
  }
}

.redCircle {
  background-color: #d81a1a;
  box-shadow: 0 0 0 200vmax #d81a1a;
  clip-path: circle(71%);
  margin-left: 3px;
  width: 8px;
  @media @sm {
    width: 12px;
    margin-left: 5px;
  }
}

.showMoreReportsButton {
  align-self: center;
  margin-top: 36px;
}

.fishingReportsListWrapper {
  display: flex;
  flex-direction: column;
}

.deleteReportModal {
  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-alert-warning {
      padding: 36px 26px 36px 18px;
    }

    .anticon-exclamation-circle {
      font-size: 20px;
      margin-top: 3px;
      @media @sm {
        margin-top: 0;
      }
    }
    .ant-alert-message {
      font-size: 17px;
      line-height: 22px;
      font-weight: 500;
    }
    .ant-alert {
      align-items: flex-start;
    }
  }
}

.emptyItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px 0;
  text-align: center;
  @media @sm {
    margin-top: 16px;
    padding: 24px 0 12px;
  }
  > div {
    display: flex;
    width: 64px;
    height: 64px;
    padding: 14px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    font-size: 36px;
    color: rgba(74, 115, 129, 1);
  }

  p {
    .roboto();
    color: #000;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
    span {
      color: #8c8c8c;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }
}

.mapWrapper {
  position: relative;
  border-radius: 8px;
  width: 100%;
  @media @sm {
    height: auto;
    width: 70%;
    aspect-ratio: 2/1;
  }
}

.map {
  :global {
    .mapboxgl-map {
      border-radius: 8px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      max-height: 93dvh;
      .mapbox-improve-map {
        display: none;
      }
    }
  }

  height: calc(90dvh);
  @media @sm {
    height: auto;
  }
}

.loading {
  margin-top: 40px;
}

.upgradeButton {
  height: 44px;
  margin-left: 16px;
  margin-top: 4px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;