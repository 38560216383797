@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/mixins.less';

.errorBackground {
  background: url('./error-hero.jpg');
  background-color: #002b3a;
  background-blend-mode: overlay;
  background-position: center 30%;
  display: flex;
  background-size: cover;
  justify-content: center;
}

.errorPageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0 85px 0;
  margin: 10%;
}

.errorText {
  .termina();
  text-align: center;
  color: #fff;

  & .header {
    font-size: 28px;
    margin-bottom: 0.5em;
  }
  & .subheader {
    font-weight: 400;
    font-size: 22px;
  }
  @media @md {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.errorButtons {
  display: flex;
  gap: 8px;
  margin-top: 20px;
}

.contactLink {
  color: #fff;
  text-decoration: underline;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;