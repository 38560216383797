@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.container.container {
  :global {
    .anticon-star,
    .anticon-bell {
      width: 17px;
      font-size: 16px;
      margin: 0;
      flex-shrink: 0;
    }
  }
}

:global {
  .loggedInFollowBtn {
    border-radius: 8px;
    .ant-popover-inner {
      background: #104051;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
    }
    .ant-popover-inner-content {
      background: #104051;
      border-radius: 8px;
    }

    .ant-popover-arrow-content {
      background-color: #104051;
    }

    .ant-radio-button-wrapper {
      background: #ffffff !important;
      color: #000000d9 !important;
    }

    .ant-form {
      label {
        color: #ffffff;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
      .ant-form-item-control-input {
        justify-content: center;
        .ant-form-item-control-input-content {
          flex: unset;
        }
      }
    }
  }
  /* for the stupid triangle / arrow atop the popover */
  .loggedInFollowBtn.ant-popover-placement-bottomRight,
  .loggedInFollowBtn.ant-popover-placement-bottom,
  .loggedInFollowBtn.ant-popover-placement-bottomLeft {
    .ant-popover-arrow-content {
      transform: translateY(12px) rotate(45deg);
      --antd-arrow-background-color: #104051;
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;