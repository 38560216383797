@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

.wrapper {
  position: relative;
}

.list {
  list-style: none;
  padding: 0;
  margin-right: -12px;
  margin-top: 16px;
  @media @sm {
    margin-top: 24px;
    margin-right: 0;
  }
}

.fishingReportCardWrapper {
  padding-right: 14px;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.viewFullReportButton {
  margin-top: 18px;
  margin-inline: auto;
  width: fit-content;
  padding: 6px 14px;
  gap: 4px;
}

.fishingReportCard {
  font-size: 12px;
  padding: 12px;
  margin-top: 10px; // this is to make sure the full shadow shows
  box-shadow: 0 2px 0 rgb(0 0 0 / 15%);
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  border: 1px lightgrey solid;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.carousel.carousel {
  :global {
    .flicking-arrow-prev {
      &:before {
        top: 44%;
      }
    }
    .flicking-arrow-next {
      &:before {
        top: 44%;
      }
    }
  }
}

.cardContainer__viewMore {
  display: flex;
  justify-content: center;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  height: 100%;
  background-color: #efefef;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  width: 323px;
}

.cardContent__viewMore {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 75%;
  button {
    padding: 12px;
    span {
      font-size: 15px;
    }
  }
  p {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    margin-top: 6px;
    line-height: 18px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;