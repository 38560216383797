@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../product_variant_selector/product_variant_selector.less';

.header {
  display: grid;
  place-content: center;
  p {
    margin-block: 8px 0;
    .roboto(700);
    color: #000;
    font-size: 16px;
    line-height: 1.25em;
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  border-bottom: 1px solid #f0f0f0;
  height: 50px;
}

.inputs {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 14px 14px 10px;

  :global {
    .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
      border-radius: 8px 0 0 8px;
    }
    .ant-input-search
      > .ant-input-group
      > .ant-input-group-addon:last-child
      .ant-input-search-button:not(.ant-btn-primary),
    .ant-input-group-addon:last-child {
      border-radius: 0 8px 8px 0;
      border-color: #d9d9d9;
    }
    .ant-select {
      padding-left: 12px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 8px;
    }
  }
}

.nonColorSelectors {
  display: flex;
  flex-wrap: wrap;
  padding-left: 17px;
}

.nonColorOptionsLabel {
  padding: 2px 6px;
  font-size: 12px;
  min-width: 40px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;