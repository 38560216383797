@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

.header {
  background-color: @omnia_blue_primary;
  color: #fff;
  font-size: 14px;

  @media @sm {
    display: none;
  }
}

.headerContent {
  display: flex;
  align-items: center;
}

.closeIcon {
  font-size: 24px;
}

.nav {
  list-style: none;
  margin: 0 0 0 auto;
  padding: 0;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  font-size: 16px;
  margin-bottom: -3px;
}

.navListItem {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.nav_link {
  .roboto();
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 15px 8px;
  transition: all 300ms ease-out;
  cursor: pointer;

  &:hover {
    color: rgba(255, 255, 255, 0.65);
    background-color: rgba(255, 255, 255, 0.12);
  }
  &.nav_link__userInfo {
    flex-direction: column;
    align-items: flex-end;
  }
}

.nav_link__selected,
.nav_link__selected:hover,
.nav__listItem__search__focused {
  background-color: #fff;
  color: rgba(40, 66, 79, 0.85);

  &:focus {
    text-decoration: none;
  }
}
.cartIcon {
  margin-right: 6px;
  font-size: 20px;
}

.lockIcon {
  font-size: 18px;
}

.userIcon {
  font-size: 21px;
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.hammy {
  font-size: 28px;
  margin-right: 21px;
  margin-top: 5px;
}

.cartWrapper {
  position: relative;
  padding-top: 1.5px;
  margin-bottom: -1.5px;
  svg {
    color: #fff;
    font-size: 21px;
  }
}

.logoLink {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-right: 36px;
  margin-left: -7px;
}

.carouselContent {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.carouselIcon {
  margin-right: 0.1em;
  font-size: 30px;
}
.authActions {
  display: flex;
  .nav_link {
    gap: 4px;
    .termina();
    span {
      margin-bottom: -5px;
    }
  }
}

.searchWrapper {
  width: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
  padding: 12px;
  background: #042930;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: all 150ms ease-out;
}
.searchWrapper__focused {
  opacity: 1;
  pointer-events: all;
}

.drawer {
  flex: 1;
}
.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #042930;
  color: #fff;
  padding: 0 12px;
}

.drawerNav {
  width: 100%;
}

.section {
  padding: 12px 0;

  & + & {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
}
.sectionHeading {
  text-transform: uppercase;
  font-weight: 300;
  display: inline-block;
  padding: 0 12px;
  font-size: 14px;
}

.list {
  padding: 0;
  margin: 0;
  width: 100%;
}
.navItem {
  display: block;
  .roboto(500);

  :global {
    .ant-collapse-header-text {
      font-size: 16px;
    }
  }
}
.navLink {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: @omnia_blue_primary;
  font-size: 16px;

  &:active {
    background-color: #042930;
    color: #fff;
  }
}

.collapse.collapse {
  background-color: #fff;
  border: none;
  font-size: 12px;

  &:global {
    > .ant-collapse-item {
      border: none;
    }

    > .ant-collapse-item > .ant-collapse-header {
      padding: 8px 12px;
    }
  }

  :global {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
}

.navGroupList {
  padding: 0;
  list-style: none;
  margin: 0;
  background-color: #c1cbca70;
}

.navGroupListLink {
  padding: 8px 20px;
  display: block;
  transition: all 200ms ease-out;
  font-size: 14px;
  color: @omnia_blue_primary;
  &:active {
    background-color: #042930;
    color: #fff;
  }
}

.brandsLink {
  padding: 8px 12px;
  display: block;
  font-size: 16px;
  color: @omnia_blue_primary;
}

.mobileDropdown {
  display: flex;
  background: #fff;
  height: auto;
  width: 100vw;
  margin-top: 5px;
  justify-content: space-evenly;
  padding: 13px 60px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  .roboto();
}

.dropdownLogin {
  display: flex;
  gap: 4px;
  svg {
    color: @omnia_orange_primary;
  }
  a {
    color: @omnia_blue_primary;
    font-weight: 500;
  }
}

.widgetWrapper {
  padding: 11px;
}

.popover.popover {
  z-index: 301;
  padding: 0;
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }
  }
}
.dashboardLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.navBadge {
  font-size: 10px;
  display: inline-flex;
  align-items: baseline;
  span span {
    margin-top: 3px;
  }
}

.dot {
  position: absolute;
  right: 66px;
  top: 31px;
}

.dealsIcon {
  color: #8c8c8c;
  margin-left: 2px;
}

.subheader {
  display: block;
  border-bottom: 1px #c1cbca solid;
  @media @sm {
    display: none;
  }
}

.reportButton {
  padding: 12px;
  svg {
    font-size: 1.1em;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;