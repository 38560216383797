@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.list {
  list-style: none;
  margin: 0;
  display: flex;
  padding: 6px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.15), 0px 6px 10px rgba(0, 0, 0, 0.07),
    0px 1px 18px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  flex-direction: column;
  position: relative;
  transition: width ease-in-out 200ms;
  width: 37px;
  @media @sm {
    width: unset;
    position: unset;
    flex-direction: row;
    align-items: center;
    padding: 7px 10px;
  }
}

.list__open {
  width: 96px;
}

.listText__open {
  overflow: hidden;
  transition: all ease-in-out 50ms 165ms;
  opacity: 1;
  line-height: 1.2;
}

.listText__closed {
  overflow: hidden;
  transition: opacity ease-in-out 100ms;
  opacity: 0;
  line-height: 0;
}

.item {
  .roboto();
  font-size: 10px;
  display: flex;
  align-items: center;
  line-height: 1.2;

  &:not(:first-child) {
    margin-top: 0.35em;
    @media @sm {
      margin-top: 0;
      margin-left: 1em;
    }
  }
}

.pin {
  margin-right: 0.3em;
  flex: 0 0 auto;
}

.openIconBackground {
  background: #f2f2f2;
  clip-path: circle(50% at 103% 50%);
  height: 27px;
  width: 18px;
  position: absolute;
  top: 37px;
  right: 0px;
  color: #737373;
  svg {
    margin-left: 11px;
    margin-top: 9px;
  }
}

.rotatedArrow {
  transition: transform linear 250ms;
  transform: rotate(180deg);
}

.rotatedArrow__reverse {
  transition: transform linear 250ms;
  transform: rotate(0deg);
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;