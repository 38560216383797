@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/seasons.less';
@import (reference) '../../styles/colors.less';

@heroHeight: 337px;

.fishingReportCard {
  font-size: 12px;
  padding: 12px;
  margin-top: 10px; // this is to make sure the full shadow shows
  box-shadow: 0 2px 0 rgb(0 0 0 / 15%);
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  position: relative;
}

.fishingReportCard__noProducts {
  border-radius: 8px;
  padding-bottom: 0;
  margin-bottom: -10px;
}

.fishingReportCard__noFooter {
  border-radius: 8px;
}

.commentsAndProducts {
  display: flex;
  flex-direction: column;
}

.cardFooter {
  display: grid;
  place-content: center;
  height: 50px;
  font-size: 16px;
  border-top: 1px solid #d0d0d0;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 2px 9px rgb(0 0 0 / 15%);
  position: relative;
  background: #f0f0f0;
}

.hero {
  margin: -12px -12px 8px;
}

.mapContainer {
  position: relative;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  max-height: @heroHeight;
  .shareButton {
    position: absolute;
  }
}

.map {
  max-height: @heroHeight;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mediaContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33dvh;
  transition: height 300ms ease;

  @media @sm {
    height: min(@heroHeight, 43dvh);
    min-width: 506px;
  }
}

.mediaContainer__fullComments {
  height: 10dvh;
  transition: height 300ms ease;
  @media @sm {
    height: 245px;
  }
}

.mediaContainer__error {
  background-color: #efefef;
}

.imgBackground {
  transition: height 300ms ease;
  height: min(@heroHeight, 43dvh);
  position: absolute;
  filter: blur(15px);
  object-fit: cover;
  width: 100%;
}

.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33dvh;
  @media @sm {
    height: min(@heroHeight, 43dvh);
  }
}

.image {
  position: relative;
  margin-inline: auto;
  cursor: pointer;
}

.videoImage {
  position: relative;
  margin-inline: auto;
  cursor: pointer;
  width: 33dvh;
  height: 33dvh;
  @media @sm {
    width: min(@heroHeight, 43dvh);
    height: min(@heroHeight, 43dvh);
  }
}

.image__belowMinHeight {
  height: 33dvh;
  z-index: 1;
  @media @sm {
    width: min(@heroHeight, 43dvh);
    height: min(@heroHeight, 43dvh);
  }
}

.expandIcon {
  appearance: none;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 2px;
  left: 40px;
  border-radius: 50%;
  z-index: 5;

  svg {
    cursor: pointer;
    transition: background-color ease-in-out 150ms;
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }
}

.video {
  position: relative;
  margin-right: 12px;
}

.seeMediaChip {
  position: absolute;
  background-color: #000000;
  .roboto();
  color: #fff;
  font-size: 11px;
  bottom: 4px;
  right: 4px;
  padding: 3px 6px;
  border-radius: 4px;
  line-height: 22px;
  z-index: 5;
  cursor: pointer;
}

.playIcon {
  width: 15px;
  pointer-events: none;
}

.reportDetails {
  display: flex;
  flex-direction: column;
}

.commentsWrapper {
  margin-block: 6px 8px;
}

.comments {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  max-width: 506px;
  -webkit-line-clamp: 2;
}

.comments__showFull {
  overflow: visible;
  -webkit-line-clamp: unset;
}

.carouselWrapper {
  overflow: hidden;
  padding: 6px 0 0;
  max-width: 506px;
  margin-inline: -12px;
  padding-inline-start: 0;
  margin-bottom: 0;

  @media @sm {
    padding: 6px 0 0;
    margin-inline: -7px;
  }

  :global {
    .flicking-viewport {
      padding-left: 6px;
    }
    .flicking-arrow-prev {
      background: linear-gradient(90deg, #ffffff 23%, rgba(255, 255, 255, 0) 98.3%);
      width: 65px;
      &:before {
        top: 30px;
        left: -13px;
        transform: scale(0.65) rotateY(180deg);
      }
    }
    .flicking-arrow-next {
      background: linear-gradient(270deg, #ffffff 23%, rgba(255, 255, 255, 0) 98.3%);
      width: 65px;
      &:before {
        top: 30px;
        right: -13px;
        transform: scale(0.65);
      }
    }
  }
}

.productCard {
  display: flex;
  flex-direction: column;
  width: 259px;
  list-style-type: none;
  @media @sm {
    width: 57%;
  }
}

.product {
  margin-right: 4px;
  .flexRow(@gap: 5px);
  flex: 1;
}

.imgLink {
  margin-top: 5px;
}

.productImgContainer {
  position: relative;
  margin-right: 5px;
  aspect-ratio: 1;
  width: 111px;
  @media @sm {
    width: 125px;
  }
}

.productImg {
  border: solid 1px #dcdcdc;
  border-radius: 8px;
  background-color: #ffffff;
}

.hotbaitContainer {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 2px;
  border-top-left-radius: 8px;
  background-color: #fb2422;
  color: #fff;
  clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 0% 100%);
  font-size: 11.5px;
  padding-inline: 4px 10px;
  .fire {
    color: #fff;
    font-size: 7px;
    margin-right: 4px;
    margin-top: -1px;
  }
}

.cardContents {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 5px;

  @media @sm {
    height: unset;
    padding: 0 8px 0 5px;
  }
  p,
  s {
    font-size: 12px;
    line-height: 1.2em;
    @media @sm {
      font-size: clamp(12px, 1.1667vw, 14px);
    }
    margin-bottom: 0;
  }
  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    margin-bottom: 5px;
    color: #000000;
    font-size: 12.5px;
    line-height: 1.4em;

    &:hover {
      text-decoration: underline;
    }

    @media @sm {
      font-size: 14px;
      margin-bottom: unset;
    }
  }

  .price {
    color: #657d7d;
    font-size: 12px;
    line-height: 1.2em;
    @media @sm {
      margin-top: 2px;
      font-size: 14px;
    }
  }
}

.description {
  flex: 1;
}

.sale {
  display: flex;
  .price {
    margin-right: 5px;
    color: #d8363e;
  }
}

.productCardFooter {
  :global {
    .anticon-shopping-cart,
    .anticon-mail {
      display: none;
      @media @md {
        display: block;
        margin-right: 1px;
      }
    }
    .anticon-mail {
      font-size: 13px;
      width: 13px;
    }
    .anticon-shopping-cart {
      width: 14px;
    }
  }

  button {
    width: 125px;
  }
}

.bigPlayIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chipDetailsContainer__withBorder {
  border: 0.5px rgb(177, 177, 177) solid;
  border-radius: 8px;
}

.shareReportButton {
  left: 4px;
  right: unset;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;