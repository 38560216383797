@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

.ProductsNav {
  position: relative;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid #c1cbca;

  display: none;
  @media @sm {
    display: block;
  }

  @media print {
    display: none;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 12px;
}

.section__products {
  overflow: hidden;
}
.sectionHeading {
  text-transform: uppercase;
  font-weight: 300;
}

.list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  text-align: center;

  @media @sm {
    margin: 0 0 0 -12px;
  }
}

.listItem {
  & + & {
    margin-left: 8px;
  }
}

.link {
  .roboto(500);
  color: @omnia_blue_primary;
  .antialias();
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  text-transform: capitalize;
  white-space: nowrap;
  padding-top: 8px;
  padding-bottom: 24px;
  padding-inline: 10px;
  @media @md {
    padding-inline: clamp(4px, 1vw, 12px);
  }

  &:hover,
  &.link__selected {
    color: @omnia_orange_primary;
    text-decoration: underline !important;
  }

  .icon {
    display: none;
    @media @md {
      display: block;
    }
  }
}

.link__lake {
  @media @sm {
    padding-left: 12px;
  }
}

.icon {
  color: #e84b25;
  min-width: 16px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;