.result {
  display: flex;
  align-items: center;
}
.resultImg {
  width: 50px;
  margin-right: 12px;
  border-radius: 7px;
  overflow: hidden;
  flex: 0 0 auto;
}
.resultText {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}

.resultText__noImage {
  font-size: 14px;
  display: flex;
  strong {
    margin-right: 4px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;