@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.contentList {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0 -12px;
}

.content {
  width: 50%;
  padding: 12px;

  @media @sm {
    width: 25%;
    h3 {
      .termina(500);
      font-size: 20px;
      letter-spacing: 1.12px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
}

.productDetailsContainer {
  transition: height 0.3s ease-in-out;
  overflow: hidden;

  h3 {
    .termina();
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.045em;
    line-height: 24px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;