@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';

.fishingReportCard {
  padding: 12px 18px;
  box-shadow: 0px 2px 9px rgb(0 0 0 / 15%);
  background-color: #fff;
  position: relative;
  font-size: 14px;
  border-radius: 0 8px 8px 0;
  max-width: calc(100% - 330px);
  @media @lg {
    max-width: calc(100% - 400px);
  }
  @media @xl {
    max-width: 930px;
  }
}

.productsCarouselRow {
  padding-top: 12px;
  overflow: hidden;
  margin-right: 0;
}

.rowTwo {
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  @media @lg {
    margin-top: 0;
  }
}

.userDetails {
  color: #657d7d;
}

.reportDetails {
  display: flex;
  gap: 0 12px;
  padding-top: 4px;
  padding-bottom: 0;
  flex-direction: row;
  justify-content: space-between;
}

.reportDetailsColumnOne {
  width: 100%;
  flex: 1 1 auto;
}

.outingDate {
  color: #657d7d;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 1px;
  p {
    margin-bottom: 0;
  }
}

.waterTemp {
  color: #657d7d;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 1px;
  p {
    margin-bottom: 0;
    display: flex;
  }
  .thermIcon {
    margin-top: 2px;
  }
}

.reportDetailsColumnTwo {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
}

.details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-inline-start: 0;
  @media @lg {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, max-content));
    justify-content: space-evenly;
    gap: 12px 24px;
    margin-right: 0;
    margin-left: auto;
    width: 100%;
  }
  @media @xl {
    grid-template-columns: repeat(auto-fill, minmax(123px, max-content));
  }
  li {
    line-height: unset !important;
    width: 100%;
    margin-bottom: 6px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    @media @lg {
      justify-content: flex-start;
      border-bottom: none;
      width: auto;
      flex-direction: column;
    }
  }

  li:last-of-type {
    border-bottom: none;
  }

  .species {
    .detailsDetail {
      display: flex;
      flex-wrap: wrap;
    }
    img {
      width: 40px;
      margin-right: 6px;
    }
  }
}

.detailsCategory {
  font-weight: 600;
  margin-bottom: 0;
  @media @lg {
    margin-bottom: unset;
  }
}

.detailsDetail {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  @media @lg {
    margin-bottom: unset;
  }
}

.seasonIcon {
  position: relative;
  top: 3px;
  margin-right: 7px;
}

.spring {
  &:after {
    content: url('../../_svg_src/icon/seasons/spring-icon.svg');
    width: 16px;
    height: 16px;
  }
}

.summer {
  &:after {
    content: url('../../_svg_src/icon/seasons/summer-icon.svg');
    width: 16px;
    height: 16px;
  }
}

.fall {
  &:after {
    content: url('../../_svg_src/icon/seasons/fall-icon.svg');
    width: 16px;
    height: 16px;
  }
}

.winter {
  &:after {
    content: url('../../_svg_src/icon/seasons/winter-icon.svg');
    width: 16px;
    height: 16px;
  }
}

.ice {
  &:after {
    content: url('../../_svg_src/icon/seasons/ice-icon.svg');
    width: 16px;
    height: 16px;
  }
}

.subDetails {
  display: flex;
  align-items: center;
  gap: 0 15px;
  padding-bottom: 16px;
  min-width: 290px;
}

.productCard {
  display: flex;
  margin-right: 4px;
  @media @sm {
    max-width: 85%;
  }
  @media @md {
    max-width: 73%;
  }
  @media @lg {
    max-width: 50%;
  }
  @media @xl {
    max-width: 33%;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;