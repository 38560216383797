@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.modal {
  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-card-body {
      padding: 0;
    }
  }
  .heading {
    .termina();
    font-size: 26px;
    line-height: 1.2em;
    text-align: center;
    margin-bottom: 24px;

    @media @sm {
      font-size: 28px;
    }
  }
  .blurb {
    text-align: center;
    line-height: 22px;
    width: 80%;
  }
}

.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signupCard.signupCard {
  border-radius: 8px;
  padding: 40px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media @sm {
    padding: 40px 60px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;