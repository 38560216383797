@import (reference) '../../styles/typography.less';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.logo {
  margin-bottom: 5px;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-name: fade;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  max-width: 30px;
}

.text {
  .termina();
  font-weight: 500;
  font-size: 12px;
  opacity: 0.9;
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;