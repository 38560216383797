@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';
@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';

.ambassador {
  display: flex;
  gap: 12px;
}
.details {
  display: flex;
  flex-direction: column;
  color: @omnia_blue_primary;
}
.name {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
  a {
    text-decoration: underline;
    display: block;
    .textColor(@omnia_blue_primary);
  }
}
.role {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 3px;
}

.locationLine {
  .typography(15px, 16px, @faded_text_color, 5px);
  .flexRow(@gap: 6px, @align-items: baseline);
  flex-wrap: wrap;
  gap: 7px 9px;
}

.location {
  flex: 0 0 auto;

  :global {
    .anticon-environment {
      margin-right: 2px;
      font-size: 17px;
    }
  }
}

.followers {
  .typography(15px, 16px, @faded_text_color, 5px);
  @media @sm {
    margin-left: 8px;
  }
  strong {
    color: #000;
    font-weight: 600;
  }
}

.followers__mobile {
  strong {
    margin-right: 7.5px;
  }
}

.followers__singleDigit {
  margin-left: 3px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;