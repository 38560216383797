.spring() {
  &:after {
    content: url('../_svg_src/icon/seasons/spring-icon.svg');
    width: 16px;
    height: 16px;
  }
}

.summer() {
  &:after {
    content: url('../_svg_src/icon/seasons/summer-icon.svg');
    width: 16px;
    height: 16px;
  }
}

.fall() {
  &:after {
    content: url('../_svg_src/icon/seasons/fall-icon.svg');
    width: 16px;
    height: 16px;
  }
}

.winter() {
  &:after {
    content: url('../_svg_src/icon/seasons/winter-icon.svg');
    width: 16px;
    height: 16px;
  }
}

.ice() {
  &:after {
    content: url('../_svg_src/icon/seasons/ice-icon.svg');
    width: 16px;
    height: 16px;
  }
}
