@import (reference) '../../styles/media.less';

.sectionContainer {
  display: flex;
  flex-direction: column;
  @media @md {
    flex-direction: row;
  }
  @media @lg {
    flex-direction: column;
    flex: 0 0 68%;
  }
  @media @xl {
    flex-direction: row;
    flex: 1;
  }
}

.carouselItem {
  border-radius: 8px;

  img {
    border-radius: 4px;
    width: 95px;
    height: 55px;

    @media @sm {
      width: 90px;
      height: 50px;
    }
  }
}

.backButton {
  margin-bottom: 20px;
  @media @sm {
    left: 10px;
    top: 24px;
    width: 27px;
    height: 27px;
  }
  @media @md {
    width: 32px;
    height: 32px;
  }
}

.nextButton {
  margin-top: 20px;
  @media @sm {
    right: 10px;
    top: 4px;
    width: 27px;
    height: 27px;
  }
  @media @md {
    width: 32px;
    height: 32px;
  }

  :global {
    .anticon-down {
      margin-top: 2px;
      @media @md {
        margin-top: 6px;
      }
    }
  }
}

@heroHeightVerticalCarousel: 457.75px;
@heroHeightHorizontalCarousel: 385px;
@heroBorderRadHorizontalCarousel: 8px 8px 0 0;
@heroBorderRadVerticalCarousel: 8px 0 0 8px;

.heroContainer {
  @media @md {
    flex: 1;
  }
  @media @lg {
    flex: unset;
  }
  @media @xl {
    flex: 1;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  img {
    border-radius: @heroBorderRadHorizontalCarousel;
    width: 100%;
    @media @sm {
      border-radius: @heroBorderRadHorizontalCarousel;
      height: @heroHeightHorizontalCarousel;
    }
    @media @md {
      height: @heroHeightVerticalCarousel;
      border-radius: @heroBorderRadVerticalCarousel;
    }
    @media @lg {
      border-radius: @heroBorderRadHorizontalCarousel;
      height: @heroHeightHorizontalCarousel;
      margin-right: -2px;
    }
    @media @xl {
      height: @heroHeightVerticalCarousel;
      border-radius: @heroBorderRadVerticalCarousel;
    }
  }
}

@thumbsContainerHorizontalRadius: 0 0 8px 8px;
@thumbsContainerVerticalRadius: 0 8px 8px 0;
.thumbs {
  background: #104051;
  border-radius: @thumbsContainerHorizontalRadius;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 9px;

  @media @sm {
    padding-inline: 7%;
    padding-block: unset;
  }

  @media @md {
    padding-inline: 12px;
    border-radius: @thumbsContainerVerticalRadius;
    flex-direction: column;
    height: @heroHeightVerticalCarousel;
  }
  @media @lg {
    border-radius: @thumbsContainerHorizontalRadius;
    padding-inline: 7%;
    flex-direction: row;
    height: 100%;
  }
  @media @xl {
    border-radius: @thumbsContainerVerticalRadius;
    justify-content: center;
    padding-inline: 12px;
    flex-direction: column;
    height: @heroHeightVerticalCarousel;
  }
}

.thumbs__viewport {
  overflow: hidden;
  margin-inline: 12px;
  position: relative;
  @media @md {
    margin-block: 12px;
    margin-inline: 0;
  }
  @media @lg {
    margin-block: 0;
    margin-inline: 12px;
  }
  @media @xl {
    margin-block: 12px;
    margin-inline: 0;
  }
}

.verticalGradient {
  top: unset;
  right: unset;
  left: 0;
  height: 50px;
  width: 100%;
  background: linear-gradient(to top, rgba(16, 64, 81, 1), rgba(16, 64, 81, 0) 50px);
}

.horizontalGradient {
  top: 0;
  right: 0;
  background: linear-gradient(to left, rgba(16, 64, 81, 1), rgba(16, 64, 81, 0) 50px);
  width: 50px;
  height: 100%;
  left: unset;
}

.thumbs__viewport:after {
  content: '';
  position: absolute;
  bottom: 0;
  .horizontalGradient();
  @media @md {
    .verticalGradient();
  }
  @media @lg {
    .horizontalGradient();
  }
  @media @xl {
    .verticalGradient();
  }
}

.thumbs__container {
  display: flex;
  flex-direction: row;
  @media @md {
    flex-direction: column;
    height: 350px;
  }
  @media @lg {
    flex-direction: row;
    height: auto;
  }
  @media @xl {
    flex-direction: column;
    height: 350px;
  }
}
.thumbs__slide {
  flex: 0 0 min(34%, 112px);
  min-width: 0;
  min-height: 0;
  margin-left: 24px;
  position: relative;

  @media @sm {
    flex: 0 0 106px;
  }
  @media @md {
    flex: 0 0 23%;
    margin-left: unset;
  }

  @media @lg {
    flex: 0 0 106px;
    margin-left: 24px;
  }
  @media @xl {
    flex: 0 0 23%;
    margin-left: unset;
  }
}
.thumbs__slide__button {
  background-color: transparent;
  touch-action: manipulation;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  opacity: 0.7;
}
.thumbs__slide__selected .thumbs__slide__button {
  opacity: 1;
}
.thumbs__slide__img {
  display: block;
  width: 100%;
  border-radius: 6px;
  height: 60px;
  width: 106px;
  @media @md {
    height: 70px;
    width: 124px;
  }
  @media @lg {
    height: 60px;
    width: 106px;
  }
  @media @xl {
    height: 70px;
    width: 124px;
  }
}

.carouselButton {
  border-radius: 50%;
  background: white;
  color: #000;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border: none;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;