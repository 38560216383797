@import (reference) '../../styles/typography.less';

.badge__new {
  .roboto(600);
  color: #fff;
  .antialias();
  font-size: 12px;
  line-height: 1;
  padding: 2px 4px;
  border-radius: 4px;
  background: #d81a1a;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;