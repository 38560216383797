@import (reference) '../../styles/media.less';

.clarityPopover {
  :global {
    .ant-popover-inner-content {
      width: 100vw;
      @media @sm {
        width: unset;
      }
    }
  }
}

.dotContainer {
  display: inline-flex;
  margin: 0 0.5em;
}
.dot {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  z-index: 2;
  &.last {
    z-index: 1;
    margin-left: -0.6em;
  }

  &.zero {
    background: #b0f2fe;
  }
  &.one {
    background: #a2dae0;
  }
  &.two {
    background: #99c9cb;
  }
  &.three {
    background: #8fb8b6;
  }
  &.four {
    background: #86a6a1;
  }
  &.five {
    background: #7d968d;
  }
  &.six {
    background: #738578;
  }
  &.seven {
    background: #6a7464;
  }
  &.eight {
    background: #61634e;
  }
  &.nine {
    background: #57523a;
  }
  &.ten {
    background: #49391b;
  }
}
.scaleLegend {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  font-size: 11px;
  margin-bottom: 0.5em;
}

.infoSvg {
  overflow: visible;
  margin: 0 0.5em;
}
.scale {
  width: 100%;
  @media @sm {
    width: 300px;
  }
  height: 30px;
  border: 1px #d9d9d9;
  border-style: solid solid solid none;

  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #b0f2fe, #49391b);
}

.scale .values {
  display: flex;
  flex-grow: 1;
  font-size: 12px;
  height: 30px;

  justify-content: center;
  align-items: center;
  color: #fff;
  border-left: 1px #d9d9d9 solid;
}

.active {
  border: 2px solid #e84b25;
}
.active__singleValue {
  border-left: 2px #e84b25 solid !important;
}
.nextToActive__singleValue {
  border-left: none !important;
}
.range {
  border-right-width: 0px;
}
.rangeStart {
  border-left: 2px #e84b25 solid !important;
}
.rangeEnd {
  border-right: 2px #e84b25 solid !important;
}

.dotContainer__widgetOnly,
.firstAndLast__widgetOnly {
  display: none;
}

.scaleLegend__widgetOnly {
  width: 100%;
  @media @sm {
    width: 300px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;