@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/media.less';

.productSidebar {
  padding: 21px 12px;
  border: 3px solid transparent;
  background-color: #eee;
  .roboto();
  position: relative;
}

.productSidebar__selected {
  align-items: center;
  background-color: #fff;

  @media @sm {
    border: none;
    &:after {
      content: none;
    }
  }
}
.productSidebar__live {
  border-color: rgba(255, 165, 0, 1);
  background-color: #fff;

  &:after {
    content: 'Live';
    position: absolute;
    left: 9px;
    top: 4px;
    color: rgba(255, 165, 0, 1);
  }
}

.content {
  display: flex;
}
.content__selected {
  flex-direction: row;
  align-items: center;
}

.productSidebarImg {
  width: 70px;
  flex: 0 0 auto;
  cursor: pointer;

  img {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.1));
  }

  .productSidebar__selected & {
    width: 50px;
    margin-bottom: 6px;

    @media @sm {
      width: 110px;
    }
  }
}
.productSidebarText {
  padding: 0 12px;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.productSidebarButtonPrice {
  flex: 0 0 auto;
  text-align: center;
  max-width: 150px; // maybe find a better way to wrap price on 2 lines

  .productSidebar__selected & {
    display: flex;
    flex-direction: column-reverse;
  }
}

.productSidebarTitle {
  font-size: 18px;
  line-height: 1.2;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productSidebarPrice {
  font-size: 16px;
}
.price__sale {
  color: #d8363e;
}
.productSidebarPriceCompare {
  opacity: 0.5;
  margin-left: 0.3em;
  white-space: nowrap;
}
.productSidebarJump {
  font-size: 14px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: underline;

  .productSidebar__selected & {
    display: none;
  }
}
.productSidebarJumpIcon {
  font-size: 1.8em;
}

.productSidebarButton {
  margin-bottom: 12px;
}

.comments {
  background-image: url('./chat_bubble.svg');
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 53px;
  padding-left: 64px;
  margin-top: 12px;
  text-align: left;
  .roboto();
  font-weight: normal;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;