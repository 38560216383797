@import (reference) '../../styles/typography.less';
@import (reference) '../product_card/product_chip.less';

.headingWrapper {
  display: flex;
  justify-content: space-between;
}

.heading {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.84px;
  line-height: 1.33;
  margin-bottom: 12px;
}

.brandImg {
  flex: 0 0 auto;
  width: 100px;
  margin-left: 12px;
}
.brandImg__small {
  width: 50px;
}

.brandImgLink {
  display: block;
  background-position: right center;
  background-size: contain;
  height: 100%;
  background-repeat: no-repeat;
}

.subTitleSection {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.variantTitle {
  .termina(500);
  font-size: 12px;
  letter-spacing: 0.56px;
  line-height: 18px;
  margin-bottom: 7px;
  margin-right: 12px;
}

.pillsWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  align-items: baseline;
}

.pill {
  display: inline-block;
  padding: 4px 10px;
  cursor: pointer;
  font-size: 12px;
  .textColor(#fff);
  background-color: #657d7d;
  border-radius: 15px;
  margin-bottom: 10px;
}

.newBadgeContainer {
  margin-right: auto;
  margin-left: 8px;
}

.variantNewBadge {
  margin-left: 5px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;