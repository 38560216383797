@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../components/omnia_button/omnia_button.less';

.page {
  padding-bottom: 60px;
  @media @sm {
    padding-bottom: 90px;
  }
}

.breadcrumb.breadcrumb {
  .roboto();
  li:last-child {
    a {
      .textColor(rgba(0, 0, 0, 0.45));
    }
  }
  font-size: 15px;
  margin-block: 12px 24px;
}

.divider50 {
  margin: 50px 0 18px;
}

.contentList {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0 -12px;
}

.content {
  width: 50%;
  padding: 12px 12px 48px;

  @media @sm {
    width: 25%;
    h3 {
      .termina(500);
      font-size: 20px;
      letter-spacing: 1.12px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
}

.sectionHeading {
  .termina();
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.045em;
  line-height: 24px;
  padding: 18px 0 24px;
  border-top: 1px solid #c1cbca;
}

.sectionHeading__recentMedia {
  padding: 60px 0 12px;
  h3 {
    .termina();
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.045em;
    line-height: 24px;
    margin-bottom: 0;
  }
}

.productTags {
  margin-bottom: 14px;
  @media @sm {
    margin-bottom: 50px;
  }
}

.panelsContainer.panelsContainer {
  padding-top: 50px;
  :global {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      font-size: 20px;
      right: 0;
    }
    .ant-collapse-header-text {
      .termina();
      color: #000000;
      font-size: 18px;
      letter-spacing: 0.045em;
      line-height: 24px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 18px 0;
      border-top: 1px solid #c1cbca;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
}

.showMoreButton {
  display: flex;
  justify-content: center;
  padding: 25px 0;
  button {
    height: 40px;
  }
}

.productDetailsContainer {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

.productDetailsContainer__faded {
  mask-image: linear-gradient(to bottom, white 60%, transparent 100%);
}

.productGridContainer {
  :global {
    .ant-collapse-content-active {
      margin-bottom: 25px;
    }
  }
}

.fishingReports.fishingReports {
  padding-bottom: 18px;

  :global {
    .ant-collapse-content {
      .ant-collapse-content-box {
        border-bottom: 1px solid #c1cbca;
      }
    }
    .ant-collapse-header {
      border-bottom: 1px solid #c1cbca;
    }
    .ant-collapse-icon-position-right
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      top: 20px;
    }
    .ant-collapse-header[aria-expanded='true'] {
      border-bottom: unset;
    }
  }
}

.discontinued {
  padding: 12px;
  border: 2px solid #ffc500;
  border-radius: 8px;
  margin-bottom: 24px;

  p {
    display: flex;
    align-items: center;
  }

  p:first-child {
    font-weight: 700;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
.discontinuedIcon {
  color: #ffc500;
  font-size: 24px;
  margin-right: 0.3em;
}

.eolReplacementButton {
  margin-bottom: 12px;
}

.itemIcon {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0em;
  margin-bottom: 9px;
  img {
    width: 60px;
  }
  .itemName {
    display: block;
    .roboto(700);
    margin-left: 9px;
    .textColor(#000000d9);
  }
  .itemCount {
    display: block;
    .roboto(400);
    margin-left: 4px;
    .textColor(#000000d9);
  }
}

.showMoreItemsButton {
  margin: 0 0 0 -12px;
  padding: 12px;
}

.itemIcon__withCount {
  .itemIcon();
  &:hover {
    cursor: pointer;
  }
}

.itemIcon__techniques {
  .itemIcon();
  margin-bottom: 0;
  img {
    width: 45px;
  }
}

.itemIcon__techniquesWithCount {
  .itemIcon__techniques();
  &:hover {
    cursor: pointer;
  }
}

.lowerContainer.lowerContainer {
  padding-top: 50px;
  :global {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      font-size: 20px;
      right: 0;
    }
    .ant-collapse-header-text {
      .termina();
      color: #000000;
      font-size: 18px;
      letter-spacing: 0.045em;
      line-height: 24px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 18px 0;
      border-top: 1px solid #c1cbca;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;