@import (reference) '../../styles/typography.less';

.reportButton {
  padding: 2px 6px;
  .roboto(500);
  line-height: 20px;
  border-radius: 59px;
  background: #fff;
  position: absolute;
  bottom: -25px;
  left: -25px;
  z-index: 10;
  color: #006b99;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;