@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.header {
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  @media @sm {
    align-items: baseline;
    flex-direction: row;
  }
}
.h1 {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 12px;
  line-height: 1.2;

  @media @sm {
    font-size: 65px;
  }
}
.nav {
  flex: 0 0;
  padding-bottom: 5px;
  line-height: 2;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;