@import (reference) '../../styles/mixins.less';

.modalFooter {
  .flexRow(@justify-content: flex-end, @gap: 10px);
}

.modal.modal {
  top: 10px;

  :global {
    .ant-modal-body {
      padding-top: 9px;
    }
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-modal-header {
      border-radius: 8px 8px 0 0;
      padding: 10px 20px;
    }
    .ant-modal-close {
      top: -4px;
      right: -1px;
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;