@import (reference) '../../styles/typography.less';

.tag {
  .termina();
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  font-size: 8px;
  color: #000000;
  padding: 10px 14px;
  margin-right: 12px;
  border-radius: 15px;
  background-color: #c1cbca;
}
.icon {
  font-size: 1.5em;
  margin-left: 0.3em;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;