.flashNotification {
  color: white;

  :global {
    .ant-notification-notice-close {
      color: currentColor;
    }
  }
}
.title {
  color: white;
}

.flashNotification__success {
  background-color: #308800;
}
.flashNotification__error {
  background-color: #d81a1a;
}
.flashNotification__info {
  background-color: #186ade;
}
.flashNotification__warning {
  background-color: #ffc500;
  color: #000;

  .title {
    color: #000;
  }
}

.closeSvg {
  overflow: visible;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;