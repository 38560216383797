@import (reference) '../../styles/media.less';

.wrapper {
  :global {
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-modal-close {
      top: -5px;
      right: -5px;
    }
    .ant-modal-body {
      max-height: 73.5dvh;
      background: rgb(233 238 236);
      border-radius: 12px 12px 0 0;
      @media @sm {
        background: #fff;
        max-height: 81.5dvh;
      }
      overflow-y: scroll;
      padding: 12px 12px 0;
    }
    .ant-modal-footer {
      background: #ffffff;
      padding: 12px;
      border-radius: 0 0 8px 8px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  @media @sm {
    flex-direction: row;
  }

  a {
    width: 100% !important;
  }
}

.footerButtons {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
}

.table.table {
  padding-top: 24px;

  ul {
    width: calc(100vw - 36px);
    @media @sm {
      width: clamp(551px, 87vw, 635px);
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;