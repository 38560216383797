@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.formWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #f9fbfd;
  border-radius: 8px;

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.card {
  position: relative;
  @media @sm {
    padding: 20px 80px 60px;
  }
}

.form {
  width: 325px;
  background-color: #fff;
}
.authLogo {
  margin: auto auto 40px;
}

.alert {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.fieldset {
  display: flex; // weird safari spacing fix
}

.checkboxes {
  :global {
    .ant-checkbox-wrapper {
      padding: 4px 0;
      display: flex;
    }
  }
}

.label {
  :global {
    .ant-form-item-label {
      padding: 0;

      > label {
        font-weight: bold;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

.headingText {
  .termina();
  line-height: 1.2em;
  text-align: center;
  font-size: 26px;
  margin-bottom: 24px;

  @media @sm {
    font-size: 28px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;