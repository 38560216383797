@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  z-index: 999; // should be less than 1000, .ant-drawer z-index
}
.content {
  padding: 12px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  width: 100%;
  text-align: center;

  @media @sm {
    width: auto;
    text-align: left;
  }

  :global {
    :not(.ant-form-inline) .ant-form-item {
      margin-bottom: 12px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  .termina(500);
  margin-bottom: 6px;
}

.clear {
  .roboto;
  font-weight: normal;
}

.inputCampaign {
  @media @sm {
    width: 300px;
  }
}
.inputHash {
  @media @sm {
    width: 250px;
  }
}

.linkDetails {
  display: flex;
  color: #006b99;
  width: 100%;
  padding-top: 16px;
  flex-direction: column;

  @media @sm {
    flex-direction: row;
  }
}
.url {
  flex: 1;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  padding: 4px 10px;
}

.copyButton.copyButton {
  color: #006b99;
}

.toggleContainer {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
  padding: 12px;
  width: 100%;
  pointer-events: none;
}

.toggle {
  position: relative;
  pointer-events: all;
  :global {
    .anticon-close {
      position: absolute;
      top: -8px;
      right: -8px;
      padding: 2px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 50%;
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;