.button {
  position: fixed;
  width: 72px;
  height: 36px;
  right: 20px;
  bottom: 20px;
  background: #ffffff;
  border: 1px solid #d3d3d3 !important;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 5;
  &:focus {
    color: #000000;
    border-color: #d3d3d3;
  }
  &:hover {
    color: #000000;
    border-color: #000000 !important;
  }
}

.antText {
  margin-left: 3px !important;
}

.fadeIn {
  animation: fadeInAnimation 200ms ease-in;
}
.fadeOut {
  animation: fadeOutAnimation 220ms ease-in;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;