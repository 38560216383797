@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.userContainer {
  display: flex;
}

.userImageContainer {
  margin-top: 4px;
}

.userDetails {
  margin-left: 10px;
  :global {
    .anticon-environment {
      color: #657d7d;
      margin-top: 2px;
      svg {
        width: 1.05rem;
        @media @sm {
          width: 1rem;
        }
      }
    }
    .anticon-calendar {
      color: #657d7d;
      margin-right: 7px;
      position: relative;
      top: 0.05em;
      svg {
        width: 1.1em;
      }
    }
  }
}

.userDetails__lakePage {
  display: flex;
  flex-direction: column;
  @media @sm {
    gap: 2px 0;
  }
}

.userName {
  font-size: 14px;
  line-height: 22px !important;
  margin-bottom: 0;
  margin-top: 1px;
  font-weight: 600;
  color: #fff;

  a {
    &:first-letter {
      text-transform: capitalize;
    }
    text-decoration: underline;
    display: block;
    .textColor(currentColor);
  }

  @media @sm {
    font-size: 15px;
    color: #000;
  }
}

.userName__lakePage {
  color: #000;
}

.role {
  margin-bottom: 0;
  font-size: 15px;
  color: currentColor;
  font-weight: 400;
  line-height: 20px !important;
  display: flex;
  align-items: center;
  gap: 4px;
}

.lakeLink {
  a {
    display: flex;
  }
  :global {
    .anticon {
      margin-top: 2px;
    }
  }
}

.lakeLink__hasMedia {
  a {
    span {
      color: #ffffff;
    }
  }
  :global {
    .anticon {
      color: #ffffff;
    }
  }
}
.waterbodyLink {
  .textColor(currentColor);
}

.waterbodyName {
  font-size: 15px;
  text-decoration: underline;
  font-weight: 400;
  line-height: 22px;
  margin-left: 4px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;