.promo {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
}

.img {
  margin-right: 12px;
  width: 30%;
  flex-shrink: 0;
  flex-grow: 0;
}

.currentPromo {
  margin-bottom: 6px;
}

.promoText {
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 0;
}

.title {
  p {
    margin-bottom: 6px;
  }
  a {
    color: #006b99;
    text-decoration: underline;
  }
}

.upsell {
  margin-bottom: 0;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;