@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../omnia_button/omnia_button.less';
.tableContainer {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

.tableContainer__faded {
  mask-image: linear-gradient(to bottom, white 60%, transparent 100%);
}

.table {
  width: 100%;
  @media @sm {
    border: 1px solid #dfe6eb;
    background-color: #fff;
  }
}

.thead {
  text-transform: uppercase;
  display: none;
  .termina();
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.56px;
  line-height: 18px;

  @media @sm {
    display: block;
  }

  .cell {
    background: #c1cbca1a;
    padding-top: 10px;
    padding-bottom: 10px;
    .roboto(500);
    font-size: 13px;
  }
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 24px;
  position: relative;
  border: 1px solid #dfe6eb;

  @media @sm {
    border: none;
    margin-bottom: 0;
  }

  &.hidden {
    opacity: 0;
  }
}

.cell {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #dfe6eb;

  &:first-child,
  &:last-child {
    width: 100%;
    flex: auto;

    @media @sm {
      width: auto;
      flex: 1 1;
    }
  }

  &:last-child {
    &.hasReject {
      @media @sm {
        width: 150px;
      }
    }
    @media @sm {
      width: 110px;
      flex: auto;
    }
  }
}
.cellContent {
  width: 100%;
  text-align: center;
  padding: 12px;
  background-color: #fff;
  font-size: 15px;
  letter-spacing: 0.7px;
  line-height: 18px;
  margin-block: auto;
}

.cellHeader {
  display: block;
  padding: 4px;
  background: rgba(223, 230, 235, 0.5);
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  width: 100%;
  text-align: center;

  @media @sm {
    display: none;
  }
}

.magnifyContainer {
  margin-inline: auto;
  margin-top: 7px;
  margin-bottom: 5px;
  max-width: 125px;
  position: relative;
  @media @sm {
    max-width: 100px;
  }

  > div {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    img {
      border-radius: 8px;
    }
  }
  &:hover {
    .newBadge {
      transition: z-index 0.4s ease-in-out;
      z-index: -1;
    }
  }
}

.newBadge {
  transition: z-index 0.6s ease-in-out;
  position: absolute;
  bottom: 5px;
  right: 4px;
  z-index: 0;
}

.imgZoomed {
  z-index: 1000;
  background-color: #fff;
  border-radius: 8px;
  img {
    max-width: none;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  :global {
    .ant-btn > span {
      font-size: 12px;
    }
  }
}

.quantityInput.quantityInput {
  width: 60px;
  margin-right: 12px;
  border-width: 1.5px;
  border-color: #00000060;
  border-radius: 8px;
  &.disabled {
    border-color: unset;
  }

  :global {
    .ant-input-number-handler-wrap {
      border-radius: 0 8px 8px 0;
    }
  }
}

.addToCart {
  flex: 1;
}

.fire {
  position: absolute;
  top: 5px;
  left: 5px;
  color: transparent;
  stroke-width: 2;
  stroke: #1b81a6;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1;

  &:hover,
  &.hot {
    color: #d0021b;
    stroke: #d0021b;
  }
}

.outOfStock {
  color: #d0021b;
}

.pills {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:empty) {
    padding-top: 6px;
  }
}

.pill {
  padding: 4px 10px;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background-color: #657d7d;
  border-radius: 15px;

  & + & {
    margin-top: 6px;
  }
}

.lakeList {
  margin: 0 0 0 1.3em;
  padding: 0;
}

.priceQuantity {
  display: flex;
  justify-content: center;
  align-items: center;
}
.priceContainer {
  .roboto(500);
  font-size: 14px;
  letter-spacing: 0.84px;

  margin-top: 1em;
  margin-bottom: 0.7em;
}

.priceContainer__sale {
  margin-top: 0;
}
.originalPrice {
  .roboto();
  color: #000;
  font-size: 14px;
}

.priceCompare {
  margin-left: 0.3em;
  white-space: nowrap;
  color: #657d7d;
}
.sale {
  color: #d8363e;
}
.price {
  display: flex;
  flex-direction: column;
  line-height: 20px;
  font-size: 17px;
}

.noColors {
  text-align: center;
  margin: 0;
  font-size: 20px;
  padding: 24px;
}

.clarityFilter {
  margin-bottom: 12px;

  @media @sm {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
.claritySlider {
  flex: 1;

  @media @sm {
    padding-left: 24px;
  }
}

.userWaterbodiesWrapper {
  width: 100%;
}
.userWaterbodies {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  margin: 0 -6px 12px -6px;
  padding: 0;
}
.userWaterbody {
  padding: 12px;
  margin: 0 6px;
  box-shadow: 0 0 0 1px #d9d9d9;
  cursor: pointer;
  display: flex;
  width: calc(25% - 12px);
  border-radius: 2px;

  &:hover {
    background-color: #f0f0f0;
  }
}
.userWaterbody__disabled {
  opacity: 0.5;
}
.userWaterbody__selected {
  box-shadow: 0 0 0 3px #016b99;
  background-color: #f0f0f0;
}
.userWaterbodyImg {
  width: 50%;
}
.userWaterbodyText {
  margin-bottom: 0;
  font-size: 18px;
  margin-left: 12px;
}
.userWaterbodyName {
  display: block;
  line-height: 1.2;
}
.userWaterbodyClarity {
  display: block;
  text-transform: uppercase;
  font-size: 13px;
}
.link {
  cursor: pointer;
  &:hover {
    color: #282829ba;
  }
}
.clarityLabel {
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5em;
  @media @sm {
    display: inline-flex;
    flex-direction: row;
  }
}
.actionsContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 180px;

  @media @sm {
    max-width: 200px;
  }
}

.actionsButtonWrapper {
  width: 100%;
  margin: 1em 0;
}

.showMoreButton {
  display: flex;
  justify-content: center;
  padding: 25px 0 0;
  button {
    height: 40px;
  }
}

.reportBadgeWidgetContainer {
  background-color: #fff;
  border-radius: 8px;
  .tertiary();
  .medium();
  &:hover {
    background-color: #fff;
  }
  > span {
    font-size: 12px;
    margin-left: 6px;
    margin-left: 4px;
  }
  .textColor(rgba(0, 0, 0, 0.85));
  margin-bottom: 0;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;