@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/seasons.less';
@import (reference) '../../styles/colors.less';

@heroHeight: 327px;
@heroHeightCollapsed: 225px;

.fishingReportCard {
  font-size: 12px;
  padding: 12px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media @sm {
    height: 764px;
  }
}

.fishingReportCard__noProducts {
  border-radius: 8px;
  padding-bottom: 0;
  margin-bottom: -10px;
}

.commentsAndProducts {
  display: flex;
  flex-direction: column;
}

.hero {
  margin: -12px -12px 8px;
}

.mapContainer {
  position: relative;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  height: @heroHeight;
  .shareButton {
    position: absolute;
  }
}

.map {
  height: @heroHeight;
  img {
    width: 100%;
    height: 100%;
  }
}

.mediaContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  align-items: center;
  justify-content: center;
  height: @heroHeight;
  transition: height 350ms ease;
}

.heroContainer__fullComments {
  height: @heroHeightCollapsed;
  transition: height 350ms ease;
}

.imgBackground {
  transition: height 350ms ease;
  position: absolute;
  filter: blur(15px);
  object-fit: cover;
  width: 100%;
  height: @heroHeight;
}

.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: @heroHeight;
  width: 100%;
}

.image {
  position: relative;
  margin-inline: auto;
  cursor: pointer;
}

.image__landscape {
  width: 100%;
}

.videoImage {
  position: relative;
  margin-inline: auto;
  cursor: pointer;
}

.expandIcon {
  appearance: none;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 2px;
  right: 38px;
  border-radius: 50%;
  z-index: 5;

  svg {
    cursor: pointer;
    transition: background-color ease-in-out 150ms;
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }
}

.video {
  position: relative;
  margin-right: 12px;
}

.seeMediaChip {
  position: absolute;
  background-color: #000000;
  .roboto();
  color: #fff;
  font-size: 11px;
  top: 4px;
  left: 4px;
  padding: 3px 6px;
  border-radius: 4px;
  line-height: 22px;
  z-index: 5;
  cursor: pointer;
  opacity: 0.85;
}

.playIcon {
  width: 15px;
  pointer-events: none;
}

.reportDetails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.listDetails {
  display: flex;
  flex-direction: column;
  padding-inline-start: 0;
  margin-bottom: 0;

  li {
    line-height: unset !important;
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    font-size: 14px;
    @media @sm {
      font-size: 15px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  li:last-of-type {
    border-bottom: none;
  }

  .species {
    padding-top: 2px;
    .detail {
      display: flex;
      flex-wrap: wrap;
    }
    img {
      width: 40px;
      margin-right: 6px;
    }
  }

  .technique {
    p:last-child {
      text-align: right;
    }
    p:first-child {
      padding-right: 12px;
    }
  }
}

.reportProperty {
  font-weight: 600;
}

.seasonIcon {
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.7);
}

.waterTemp {
  color: #657d7d;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 1px;
  p {
    margin-bottom: 0;
    display: flex;
  }
  .thermIcon {
    margin-top: 2px;
  }
}

.subDetails {
  display: flex;
  align-items: center;
  gap: 0 15px;
  padding-bottom: 16px;
  min-width: 290px;
}

.waterTempMobile {
  display: flex;
  .thermIcon {
    margin-top: 4px;
    margin-right: 3px;
  }
}

.commentsWrapper {
  margin-block: 6px;
  @media @sm {
    margin-block: 6px 8px;
  }
}

.comments {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #000000;
  max-height: 40px;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  transition: max-height 200ms ease;
  font-size: 13px;

  @media @sm {
    font-size: 14px;
    line-height: 20px;
  }
}

.comments__expanded {
  -webkit-box-orient: vertical;
  max-height: 440px; // max height set to intentionally allow natural full height and animation to work
  line-clamp: 7;
  -webkit-line-clamp: 7;
  transition: max-height 350ms ease 200ms;
}

.carouselWrapper {
  overflow: hidden;
  margin-inline: -12px;
  padding-inline-start: 0;
  margin-bottom: 0;
  margin-top: auto;

  :global {
    .flicking-viewport {
      padding-left: 12px;
    }
    .flicking-arrow-prev {
      background: linear-gradient(90deg, #ffffff 23%, rgba(255, 255, 255, 0) 98.3%);
      width: 65px;
      &:before {
        top: 30%;
        left: -10px;
        transform: scale(0.55) rotateY(180deg);
      }
    }
    .flicking-arrow-next {
      background: linear-gradient(270deg, #ffffff 23%, rgba(255, 255, 255, 0) 98.3%);
      width: 65px;
      &:before {
        top: 30%;
        right: -10px;
        transform: scale(0.55);
      }
    }
  }
}

.mediaContainer__error {
  background-color: #efefef;
}

.productCard {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: clamp(288px, 23vw, 335px);
  align-self: flex-end;
  margin-right: 4px;
  height: 116px;
  @media @sm {
    height: 130px;
  }
}

.bigPlayIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.commentsModalWrapper {
  :global {
    .ant-modal-content {
      z-index: 9;
      width: fit-content;
      margin-inline: auto;
      border-radius: 8px;
    }
    .ant-modal-body {
      padding: 6px;
    }
    .ant-modal-close {
      left: -18px;
      top: -20px;
      background-color: #fff;
      right: unset;
      border-radius: 50%;
    }

    .ant-modal-close-x {
      background: white;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(0, 0, 0, 0.7);
      border-radius: 50%;
      font-size: 13px;
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
    .plyr--video {
      aspect-ratio: unset;
    }
  }
}

.commentsModal {
  color: #000000;
  font-size: 14px;
  line-height: 21px;
  padding: 12px;

  strong {
    display: flex;
    gap: 5px;
  }
  a {
    &:first-letter {
      text-transform: capitalize;
    }
    text-decoration: underline;
    display: block;
    .textColor(currentColor);
  }
}

.commentsButtonsWrapper {
  display: flex;
  justify-content: space-between;

  button {
    font-size: 13px;
  }
}

.chipDetailsContainer__withBorder {
  border: 0.5px rgb(177, 177, 177) solid;
  border-radius: 8px;
}

.newBadge {
  .newBadge();
  top: 4px;
  left: 6px;
}

.badge__reportedDate {
  .roboto(600);
  .antialias();
  line-height: 1.5;

  position: absolute;
  background: rgba(230, 230, 230, 1);
  font-size: 14px;
  top: 1px;
  left: 1px;
  color: rgba(0, 0, 0, 0.8);
  height: min-content;
  padding: 5px 12px 5px 7px;
  border-radius: 8px 0 0 0;
  clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 0% 100%);
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;