@import (reference) '../../styles/typography.less';

.switch {
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  &.flipped {
    flex-direction: row-reverse;
  }
}

.text {
  margin-right: 0.5em;
  .roboto();
  .flipped & {
    margin-right: 0;
    margin-left: 0.5em;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;