@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';
@import (reference) '../../styles/media.less';
@import (reference) '../../styles/seasons.less';

.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}

.pill {
  display: inline-block;
  padding: 4px 10px;
  cursor: pointer;
  font-size: 12px;
  .textColor(#fff);
  background-color: #657d7d;
  border-radius: 15px;
  margin-bottom: 10px;
}

.popover {
  width: 100%;
  z-index: 99;
  @media @sm {
    width: 90%;
  }
  @media only screen and (min-width: 840px) {
    width: unset;
  }
  :global {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-arrow-content {
      margin-bottom: 1.5px;
    }
    .ant-popover-inner {
      background-color: #104051;
      border-radius: 8px;
    }
    .ant-popover-arrow-content::before {
      background: #104051;
    }
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  transform-origin: unset;
  @media @sm {
    flex-direction: row;
  }
}
.allReports {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  background-color: #19566b;
  border-top: 1px solid #4a7381;
  margin: 10px -16px -16px -16px;
  padding: 4px 0px;
  border-radius: 0 0 8px 8px;

  button {
    padding: 0;
    opacity: 0.85;
    .roboto();
    color: #fff;
    font-size: 14px;
  }
  :global {
    .ant-btn > span {
      text-decoration: underline;
      font-weight: bold;
    }
    .ant-btn-link:hover {
      color: #fff;
    }
  }
}
.allReports:hover {
  background-color: #4a7381;
}

.reportByUserList,
.nearbyList {
  list-style: none;
  list-style-type: none;
  padding: 0;
}
.reportByUser,
.nearbyLake {
  display: flex;
  flex-direction: row;
  color: #fff;
  padding: 5px;
}

.listHeader {
  .roboto(700);
  color: #fff;
}

.reportInfoList,
.nearbyInfo {
  list-style: none;
  display: flex;
  flex-direction: column;
}
.reportInfoList {
  padding: 0 16px;
}

.nearbyInfo {
  justify-content: center;
  padding: 0 12px;
}
.reportInfo {
  display: inline-flex;
  color: #cadbe1;
  align-items: center;
  flex-wrap: wrap;
}
.userInfo {
  color: #fff;
}

.link__disabled {
  pointer-events: none;
}

.lakeLink {
  a {
    display: flex;
    align-items: center;
  }
}
.waterbodyName,
.viewAll,
.viewReport,
.ambassadorName,
.reportCount {
  font-size: 15px;
  color: #ffffff;
  text-decoration: underline;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}
.reportCount {
  color: #cadbe1;
}
.waterbodyName {
  display: flex;
  flex-wrap: wrap;
}
.userImg {
  width: 64px;
  overflow: unset;
  img {
    border-radius: 50%;
  }
}

.listHeader {
  color: #ffffff;
}

.divider {
  height: auto;
  border-color: #4a7381 !important;
}

.mapContainer {
  width: 60px;
  min-width: 60px;
  img {
    border-radius: 8px;
    width: 60px;
  }
}
.role {
  display: flex;
  align-items: baseline;
  .dot {
    height: 16px;
    width: 16px;
    display: inline-flex;
    border-radius: 50%;
    position: relative;
    top: 0.16em;
    margin-left: -1.3em;
    z-index: 2;
  }
}

.bronze {
  background: @ambassador_bronze;
}

.silver {
  background: @ambassador_silver;
}

.gold {
  background: @ambassador_gold;
}

.blue {
  background: @ambassador_blue;
}

.speciesImg {
  margin: 0 6px;
  width: 48px;
}

.seasonIcon {
  position: relative;
  top: 3px;
  margin-right: 7px;
}

.spring {
  .spring();
}

.summer {
  .summer();
}

.fall {
  .fall();
}

.winter {
  .winter();
}

.ice {
  .ice();
}

.star {
  color: #f3a626;
  top: 3px;
  margin-left: 7px;
}

.loading__dark {
  color: #fff;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;