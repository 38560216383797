.pinWrapper {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 100ms ease-out;
  transform-origin: left top; // used when we need to scaling pins to resize them

  &:after {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    border-radius: 50%;
  }
}

.pinWrapper__sm {
  width: 40px;
  height: 40px;
}
.pinWrapper__lg {
  width: 52px;
  height: 52px;
}
.pinWrapper__selected {
  background-color: #bfbfbf;
  z-index: 1;

  &::after {
    background-color: #bfbfbf;
    animation: pinPulse 1.5s infinite ease-out;
  }
}

@keyframes pinPulse {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

.pin {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid white;
  filter: drop-shadow(0px 2px 2px #00000040);
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 8px 6px 0 6px;
    border-color: #fff transparent transparent transparent;
    transform: translateX(-50%);
    bottom: -8px;
    left: 50%;
    position: absolute;
  }
}
.pin__sm {
  width: 28px;
  height: 28px;
  background-color: #002b3a;
}
.pin__lg {
  width: 40px;
  height: 40px;
  background-color: #e84b25;
}
.pin__featured {
  background-color: #308800;
}
.pin__with_summary {
  background: radial-gradient(40.66% 83.3% at 46.63% 69.84%, #002bff 0%, #ff2a00 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}
.pin__favorite {
  background-color: #f3a626;
}
.pin__selected {
  background-color: #3400e1;
}

.pinMedia {
  display: block;
  position: absolute;
  top: -2px;
  left: -8px;
  background-color: #8c8c8c;
  border-radius: 80px;
  border: 2px solid white;
  width: 12px;
  height: 12px;

  &::after {
    content: '';
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 3px 2px 0 2px;
    border-color: #fff transparent transparent transparent;
    transform: translate(-40%, -50%) rotate(-90deg) scale(130%);
    top: 50%;
    left: 50%;
    position: absolute;
  }
}
.pinReportCount {
  display: block;
  position: absolute;
  top: -2px;
  left: calc(100% - 10px);
  background-color: #002b3a;
  border-radius: 80px;
  border: 2px solid white;
  font-size: 9.6px;
  line-height: 1.2;
  font-weight: 700;
  padding: 0 3px;
}

.svgLake {
  .pin__sm & {
    width: 14px;
    min-width: 14px;
  }
  .pin__lg & {
    width: 21px;
    min-width: 21px;
  }
}
.svgStar svg {
  width: 22px;
  min-width: 22px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;