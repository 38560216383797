@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/media.less';

.chipContainer {
  position: absolute;
  bottom: 6px;
  left: 6px;
  z-index: 3;
  display: flex;
  width: max-content;
  padding: 3px 9px;
  gap: 10px;
}

.chipContainer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  z-index: -1;
}

.chipDetails {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  color: #657d7d;
  gap: 1px;

  :global {
    .anticon-environment {
      color: #495f5f;
      svg {
        width: 0.9rem;
        @media @sm {
          width: 1rem;
        }
      }
    }
    .anticon-calendar {
      color: #495f5f;
      position: relative;
      top: 0.05em;
      svg {
        width: 1.1em;
      }
    }
  }
}

.reportedOnLake {
  display: flex;
  align-items: center;
  gap: 3px;
  line-height: 1.2;
  margin-bottom: 0;
  cursor: pointer;
  .textColor(rgba(0, 0, 0, 0.7));
  font-size: 14px;

  em {
    text-decoration: underline;
    font-style: normal;
  }
}

.userImageContainer {
  margin-top: 4px;
  color: #495f5f;
}

.userName {
  display: block;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 2px;

  a {
    &:first-letter {
      text-transform: capitalize;
    }
    text-decoration: underline;
    display: block;
    .textColor(currentColor);
  }
}

.chipDetail {
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.chipDetail__date {
  .chipDetail();
  align-items: unset;
  span:nth-child(2) {
    margin-right: 4px;
    margin-top: 1px;
  }
}

.lakeLink {
  margin-top: 1px;
  a {
    display: flex;
    .textColor(rgba(0, 0, 0, 0.7));
  }
  :global {
    .anticon {
      color: rgba(0, 0, 0, 0.7);
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;