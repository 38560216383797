// node_modules/@egjs/flicking/dist/flicking.css
:global {
  .flicking-viewport {
    position: relative;
    overflow: hidden;
  }

  .flicking-camera {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    z-index: 1;
  }
  .flicking-camera > * {
    flex-shrink: 0;
  }

  .flicking-viewport.vertical {
    display: inline-flex;
  }
  .flicking-viewport.vertical > .flicking-camera {
    display: inline-flex;
    flex-direction: column;
  }

  .flicking-viewport.flicking-hidden .flicking-camera > * {
    visibility: hidden;
  }
}

// node_modules/@egjs/flicking-plugins/dist/arrow.css
:global {
  .flicking-arrow-prev,
  .flicking-arrow-next {
    position: absolute;
    top: 50%;
    width: 64px;
    height: 64px;
    cursor: pointer;
    transform: translateY(-50%);
    z-index: 2;
    transition: opacity 300ms ease-out;
  }

  .flicking-arrow-prev.is-circle,
  .flicking-arrow-next.is-circle {
    background-color: #f2a65e;
    border-radius: 50%;
  }

  .flicking-arrow-disabled.is-circle {
    background-color: rgb(10 10 10 / 10%);
  }

  .flicking-arrow-prev.is-circle::before,
  .flicking-arrow-prev.is-circle::after,
  .flicking-arrow-next.is-circle::before,
  .flicking-arrow-next.is-circle::after {
    background-color: white;
  }

  .flicking-arrow-prev {
    left: 0;
  }
  .flicking-arrow-next {
    right: 0;
  }

  .flicking-arrow-prev.is-outside {
    left: -74px;
  }
  .flicking-arrow-next.is-outside {
    right: -74px;
  }

  .flicking-arrow-prev::before,
  .flicking-arrow-prev::after,
  .flicking-arrow-next::before,
  .flicking-arrow-next::after {
    content: '';
    width: 24px;
    height: 6px;
    position: absolute;
    background-color: #f2a65e;
  }
  .flicking-arrow-prev::before {
    top: 50%;
    left: 22px;
    transform: rotate(-45deg);
    transform-origin: 0% 50%;
  }
  .flicking-arrow-prev::after {
    top: calc(50% - 4px);
    left: 22px;
    transform: rotate(45deg);
    transform-origin: 0% 50%;
  }
  .flicking-arrow-next::before {
    top: 50%;
    right: 22px;
    transform: rotate(45deg);
    transform-origin: 100% 50%;
  }
  .flicking-arrow-next::after {
    top: calc(50% - 4px);
    right: 22px;
    transform: rotate(-45deg);
    transform-origin: 100% 50%;
  }

  .flicking-arrow-disabled {
    cursor: default;
    opacity: 0;
    pointer-events: none;
  }

  .flicking-arrow-disabled::before,
  .flicking-arrow-disabled::after,
  .flicking-arrow-disabled::before,
  .flicking-arrow-disabled::after {
    background-color: #e6e6e6;
  }
}

// node_modules/@egjs/flicking-plugins/dist/pagination.css
:global {
  .flicking-pagination {
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
    text-align: center;
    z-index: 2;
  }

  .flicking-pagination-bullets,
  .flicking-pagination-scroll {
    font-size: 0;
  }

  .flicking-pagination-scroll {
    left: 50%;
    transform: translate(-50%);
    white-space: nowrap;
    overflow: hidden;
  }

  .flicking-pagination-scroll .flicking-pagination-slider {
    transition: 0.2s transform;
  }
  .flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-slider,
  .flicking-pagination-scroll.flicking-pagination-uninitialized .flicking-pagination-bullet {
    transition: none;
  }

  .flicking-pagination-bullet {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: rgb(10 10 10 / 10%);
    cursor: pointer;
    font-size: 1rem;
  }

  .flicking-pagination-scroll .flicking-pagination-bullet {
    vertical-align: middle;
    position: relative;
    transition: 0.2s transform;
  }

  .flicking-pagination-bullet-active {
    background-color: #f2a65e;
  }

  .flicking-pagination-scroll .flicking-pagination-bullet {
    vertical-align: middle;
    position: relative;
    transition: 0.2s transform, 0.2s left;
    transform: scale(0);
  }

  .flicking-pagination-scroll .flicking-pagination-bullet-active {
    transform: scale(1);
  }

  .flicking-pagination-scroll .flicking-pagination-bullet-prev,
  .flicking-pagination-scroll .flicking-pagination-bullet-next {
    transform: scale(0.66);
  }

  .flicking-pagination-scroll .flicking-pagination-bullet-prev2,
  .flicking-pagination-scroll .flicking-pagination-bullet-next2 {
    transform: scale(0.33);
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;