@import '../../styles/typography.less';

.underline {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}
.currentColor {
  .textColor(currentColor);
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;