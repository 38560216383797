@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../components/omnia_button/omnia_button.less';

.sectionWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'title'
    'hero'
    'selector';

  @media @sm {
    grid-template-columns: calc(50% - 2.5vw) calc(50% - 2.5vw);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'hero title'
      'hero selector';
    row-gap: 0;
    column-gap: 5vw;
  }

  .heroContainer {
    grid-area: hero;
    @media @sm {
      max-width: 48vw;
    }
    @media @xl {
      max-width: 42vw;
    }
  }

  .sticky {
    @media @sm {
      position: sticky;
      top: 0;
    }
  }

  .titleContainer {
    grid-area: title;
  }

  .selectorContainer {
    margin-top: 20px;
    @media @sm {
      margin-top: 0;
    }
    grid-area: selector;
  }
}

.widgetsWrapper {
  display: flex;
  gap: 6px;
  margin-block: 6px;
  @media @sm {
    gap: 12px;
    margin-block: 6px 12px;
  }
}

.fishingReportsWidgetButton {
  .tertiary();
  .small();
  .textColor(rgba(0, 0, 0, 0.85));
  font-weight: 400;
  &:hover {
    background-color: #fff;
  }
  > span {
    padding-block: 4px;
  }
}

.reportBadgeWidgetContainer {
  background-color: #fff;
  border-radius: 8px;
  .tertiary();
  .medium();
  &:hover {
    background-color: #fff;
  }
  > span {
    font-size: 12px;
    margin-left: 6px;
    margin-left: 4px;
  }
  .textColor(rgba(0, 0, 0, 0.85));
  margin-bottom: 0;
}

.selectorContainer {
  width: auto;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;