@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

.ambassadorCard {
  background: #fff;
  max-width: 550px;
  display: flex;
  margin: -4px 0px;
  padding: 0;
  width: 100vw;
  flex-direction: column;
  @media @sm {
    padding: 1em 0;
    width: auto;
    flex-direction: row;
  }
}
.upper {
  display: flex;
  flex-grow: 1;
}

.firstContainer {
  margin: 1em 0 1em 1em;
}
.userPremium {
  display: flex;
  align-items: baseline;
}

.svgPremium {
  margin-right: 0.35em;
}

.secondContainer {
  padding: 1em;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  @media @sm {
    display: block;
  }
}

.displayName {
  .termina();
  font-size: 14px;
  flex-wrap: wrap;
}
.userFavorites {
  color: rgba(0, 0, 0, 0.5);
}
.userInfoItem {
  display: flex;
  align-items: baseline;
}
.lower {
  display: flex;
  margin: 0 1em;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  @media @sm {
    border-top: none;
  }
}
.thirdContainer {
  padding: 6px 12px 0;
  .termina();
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  font-size: 13px;
  justify-content: space-between;
  @media @sm {
    flex-grow: 0;
    font-size: 12px;
    justify-content: center;
  }
}

.baseNav {
  display: inline-flex;
  justify-content: space-between;
  @media @sm {
    display: block;
  }
}

.userInfo {
  padding: 0;
  list-style: none;
  display: inline;
  margin: 0;
  font-size: 10px;
  @media @sm {
    font-size: 13px;
  }
}

.dashNav {
  display: flex;
  padding: 0.5em 0;
  justify-content: center;
  @media @sm {
    justify-content: flex-start;
  }
}
.dashNavIcon {
  margin-right: 0.5em;
}

.emailIcon {
  margin-right: 0.5em;
  padding-top: 3px;
  font-size: 18px;
}

.ordersIcon {
  margin-right: 0.5em;
  margin-left: -2px;
  font-size: 17px;
}

.profileNav {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: space-evenly;
  @media @sm {
    flex-direction: row;
  }
}
.navLink {
  .roboto();
  color: rgba(0, 0, 0, 0.85);
  margin: 0 0.25em;
  font-style: normal;
  font-size: 11px;
  text-decoration-line: underline;
  &.navLink__info {
    font-size: 10px;
  }
}

.verticalLine {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}
.dot {
  margin-right: 0.5em;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;