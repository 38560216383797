@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/mixins.less';

.wrapper {
  display: flex;
}

.video {
  flex: 1 1 auto;
  position: relative;
  :global {
    .plyr__menu {
      display: none;
    }
    @media @sm {
      .plyr__menu {
        display: block;
      }
    }
  }
}

.video__noItems.video__noItems {
  :global {
    .plyr {
      border-radius: 8px;
    }
  }
}
.products {
  flex: 0 0 auto;
  width: 400px;
  position: relative;
  border: 1px solid #ddd;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.products__mobile {
  flex: 0 0 auto;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid #ddd;
  position: relative;
  height: 200px;
}
.products__mobile__proCtas {
  height: 295px;
}

.products__shortMobile {
  height: 150px;
}

.products__mobile__noProducts {
  display: none;
}

.videoPlaceholder {
  padding-top: 56.25%;
  background-color: #ddd;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  @media @sm {
    border-top-right-radius: unset;
    border-bottom-left-radius: 8px;
  }
}

.videoPlaceholder__noItems {
  border-radius: 8px;
}

.mux {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  transition: all 300ms ease-out;
  transform-origin: bottom right;
}

.productList {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  @media @sm {
    border-top-right-radius: 8px;
    border-bottom-left-radius: unset;
  }
}

.products__noProducts {
  display: none;
}

.product {
  & + & {
    border-top: 1px solid #ddd;
  }
}

.proCtaItem {
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #ddd;
  margin-bottom: -1px;
}
.proCtaButtons {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.liveBadge {
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(0, 182, 45, 1);
  display: inline-flex;
  gap: 2px;
  margin-left: 12px;
}

.videoSummary {
  line-height: 21px;
  text-align: left;
  .roboto();
  font-size: 14px;
  font-weight: 300;
}

.publicationDate {
  .roboto();
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 19.2px;
  padding-top: 2px;
}

.title {
  .roboto(500);
  font-size: 20px;
  padding-top: 10px;
  margin-bottom: 0;
  line-height: 24px;

  a {
    background-color: #fb2422;
    color: #ffffff;
    border-radius: 4px;
    padding: 0 6px;
    font-size: 14px;
  }
}

.unDockButton {
  border: none;
  position: absolute;
  top: -16px;
  left: -21px;
  background: transparent;
  font-size: 14px;
  cursor: pointer;
  span {
    background-color: white;
    border-radius: 50%;
    border: 0.25px solid black;
    padding: 8px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;