@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';

.product {
  margin-right: 4px;
  .flexColumnResponsive();
  flex: 1;
}

.product__mobileRow {
  .flexRow(@gap: 5px);
  flex: 1;
  margin-right: 4px;
}

.imgLink {
  margin-top: 5px;
  @media @sm {
    max-height: 100px;
  }
}

.productImgContainer {
  position: relative;
  margin-right: 5px;
  aspect-ratio: 1;
  width: 100px;
}

.productImg {
  border: solid 1px #dcdcdc;
  border-radius: 8px;
  background-color: #ffffff;
}

.hotbaitContainer {
  display: flex;
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: #fb2422;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  padding: 2px 4px;
  .fire {
    color: #fff;
    font-size: 8px;
    margin-right: 3px;
    @media @sm {
      font-size: 8px;
    }
  }
}

.cardContents {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media @sm {
    height: unset;
    padding: 0 8px 0 5px;
  }
  p,
  s {
    font-size: 12px;
    line-height: 18px;
    @media @sm {
      font-size: clamp(12px, 1.1667vw, 14px);
      line-height: 20px;
    }
    margin-bottom: 0;
  }
  .title {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    max-height: 82px;
    max-width: 100px;
    margin-bottom: 5px;
    text-decoration: underline;
    color: #000000;
    // leave for article.tsx font overrides
    font-size: 14px;
    line-height: 20px;

    @media @sm {
      margin-bottom: unset;
      max-width: 213px;
      -webkit-line-clamp: 2;
      max-height: 42px;
    }
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .title__mobileRow {
    max-width: 150px;
    -webkit-line-clamp: 2;
  }
  .price {
    color: #657d7d;
    // leave for article.tsx font overrides
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
  }
}

.description {
  flex: 1;
}

.sale {
  display: flex;
  .price {
    margin-right: 5px;
    color: #d8363e;
  }
}

.cardFooter {
  :global {
    .anticon-shopping-cart,
    .anticon-mail {
      display: none;
      @media @md {
        display: block;
        margin-right: 1px;
      }
    }
    .anticon-mail {
      font-size: 13px;
      width: 13px;
    }
    .anticon-shopping-cart {
      width: 14px;
    }
  }

  button {
    width: 125px;
    @media @sm {
      width: unset;
    }
    @media @md {
      padding: 6px clamp(6px, 1em, 20px);
      min-width: clamp(85px, 8.75em, 145px);
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;