@import (reference) '../../../styles/media.less';
@import (reference) '../../../styles/typography.less';
@import (reference) '../../../styles/colors.less';

.mediaSection {
  display: flex;
  flex-direction: column;
  @media @sm {
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
  }
}

.videoContainer,
.imgContainer {
  width: 100%;
  @media @sm {
    width: unset;
    margin-left: 12px;
  }
  position: relative;
  overflow: hidden;
  .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
    height: 210px;
    @media @sm {
      height: 116px;
    }
  }
}

.imgBackground {
  height: 210px;
  @media @sm {
    width: 174px;
    height: 116px;
  }
}
.video {
  position: relative;
  margin-right: 12px;
}

.overlayFade {
  background: linear-gradient(to bottom, transparent 35%, #000000 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.expandIcon {
  appearance: none;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 2px;
  right: 34px;
  border-radius: 50%;
  z-index: 5;
  @media @sm {
    top: 4px;
    right: 4px;
  }
  svg {
    cursor: pointer;
    transition: background-color ease-in-out 150ms;
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }
}

.userDetailsWrapper {
  position: absolute;
  bottom: 6px;
  left: 12px;
  z-index: 3;
  color: #fff;
}

.videoLabel {
  display: flex;
  position: absolute;
  background-color: #000000;
  .roboto();
  color: #fff;
  font-size: 11px;
  top: 4px;
  left: 4px;
  align-items: center;
  gap: 0 4px;
  padding: 3px;
  border-radius: 4px;
  height: 25px;
  z-index: 5;
}

.playIcon {
  width: 15px;
  pointer-events: none;
}

.desktopPlayIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.editWrapper {
  height: 95%;
  box-sizing: border-box;
  border: 1px solid #104051;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #c9e3f0;
  span {
    color: @omnia_blue_primary;
    font-weight: 500;
    margin-top: 8px;
  }
  button {
    width: min-content;
    padding: 4px 8px;
  }
  @media @sm {
    height: 116px;
    flex: 1;
    margin-left: 10px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;