@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.skeletonable() {
  .skeleton & {
    color: transparent;
    background-color: #efefef;
  }
}

.sectionTitle {
  color: transparent;
  background-color: #efefef;
  width: fit-content;
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 16px;
  .termina();
}

.wrapper {
  position: relative;
}

.loading {
  position: absolute;
  left: 0;
  top: 55%;
  transform: translateY(-50%);
  z-index: 99;
}

.list {
  list-style: none;
  padding: 0;
  margin-inline: -12px;
  @media @sm {
    margin-right: 0;
  }
}

.item {
  padding-inline: 12px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}
.product {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-bottom: 3px; // for edge of button
}

.imgWrapper {
  margin-inline: auto;
  position: relative;
  margin-bottom: 12px;
  display: block;
  max-width: 100%;
}

.productImg {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  .skeletonable();
  height: 100%;
}

.productTitle {
  .roboto(500);
  font-size: 16px;
  line-height: 1.2;
  margin-block: 12px 6px;
  .skeletonable();
}

.fishingReportIcon {
  min-width: 16px;
}

.price {
  .skeletonable();
  margin-bottom: 0;
}

.cartIcon.cartIcon {
  margin-right: 2px;
  font-size: 17px;
  width: 17px;
}

.productButtons {
  margin-block: 14px 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reportButton {
  display: flex;
  align-items: center;
  padding-block: 6px;
  margin-bottom: 6px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;