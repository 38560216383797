.imgWrapper {
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.img {
  border-radius: 50%;
  aspect-ratio: 1/1;
}
.imgDefault {
  border-radius: 50%;
  background: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;