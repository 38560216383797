.quickDetails {
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
}

.quickDetail {
  margin-bottom: 0;
  width: 50%;
}

.quickDetailIcon {
  width: 18px;
  display: inline-block;
}

.fire {
  color: #fb2422;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;