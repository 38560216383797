@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

.commentsSection {
  @media @sm {
    width: 50%;
    flex: 1;
  }
  @media @md {
    flex: unset;
  }
  .seoTag {
    .roboto();
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 2px;
    margin-top: 0;
    @media @sm {
      margin-bottom: 0;
    }
  }
}

.commentsWrapper {
  margin-bottom: 24px;
  font-size: 15px;
  @media @sm {
    margin-bottom: 14px;
  }
}

.editWrapper {
  height: 95%;
  box-sizing: border-box;
  border: 1px solid #104051;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #c9e3f0;
  span {
    color: @omnia_blue_primary;
    font-weight: 500;
    margin-top: 8px;
  }
  button {
    width: min-content;
    padding: 4px 8px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;