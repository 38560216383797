.publishDates {
  display: flex;
  gap: 10px;
  margin-bottom: 0;
  color: #000000d9;
  flex-wrap: wrap;
  strong {
    font-weight: 500;
  }
  .published {
    margin-right: 10px;
  }

  :global {
    .anticon-calendar {
      color: #000000d9;
      margin-top: 2px;
      margin-right: -4px;
      svg {
        width: 1.1em;
      }
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;