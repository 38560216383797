@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

.wrapper {
  display: flex;
  position: relative;
  padding-right: 50px; // width of overflow menu button
}
.invisible {
  visibility: hidden;
}
.overflowButton.overflowButton {
  position: absolute;
  right: 0;
  font-size: 24px;
  background-color: #fff;
  margin-top: 4.5px;
}

.dropdown {
  background-color: #eceae9;
  background-image: url('./menu-bg.png');
  background-repeat: repeat;
  margin-top: -4px;
  min-width: 250px;
  display: flex;
}
.subcategories {
  padding: 24px 32px;
}

.dropdownList {
  list-style: none;
  margin: 0 0 10px 0;
  padding: 0;
  column-gap: 3em;
}

.dropdownLink,
.dropdownSidebarLink {
  .roboto(500);
  color: @omnia_blue_primary;
  display: block;
  text-transform: capitalize;
  padding: 10px 0;
  font-size: 14px;

  &:hover {
    color: #e84b25;
    text-decoration: underline;
  }
}

.dropdownSidebar {
  background-color: #c1cbca;
  padding: 24px 32px;
}
.dropdownSidebarTitle {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}

.dropdownSidebarList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  text-align: center;
  overflow: hidden;

  @media @sm {
    margin: 0 0 0 -12px;
  }
}

.listItem {
  & + & {
    margin-left: 8px;
  }
}

.link {
  .roboto(500);
  color: @omnia_blue_primary;
  .antialias();
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  padding: 16px 10px;
  text-transform: capitalize;
  white-space: nowrap;

  @media @sm {
    font-size: 14px;
    padding: 9px 12px;
  }

  &:hover,
  &.link__selected {
    color: #e84b25;
    text-decoration: underline !important;
  }
}

.dealsIcon {
  display: block;
  margin-top: 3px;
  svg {
    color: #8c8c8c;
    font-size: 15px;
    min-width: 15px;
  }
}

.dropdownOverlay {
  :global {
    .ant-dropdown-menu-title-content {
      display: flex;
      gap: 6px;
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;