@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}

.reportsWidgetButton {
  display: flex;
  align-items: center;
  .roboto();
  font-weight: 400;
  font-size: 14px;
  padding: 4px 5px;
  margin-top: 12px;
}

.reportsIcon {
  margin: 0 5px 0 0;
}

.popover {
  z-index: 1001; // +1 from .ant-modal-wrap
  border-style: none;
  color: #fff;

  :global {
    .ant-popover-inner-content {
      width: 100vw;
      @media @sm {
        width: unset;
      }
    }
    .ant-popover-arrow-content {
      margin-bottom: 1.5px;
    }
    .ant-popover-inner {
      background-color: #104051;
      border-radius: 8px;
    }
    .ant-popover-arrow-content::before {
      background: #104051;
    }
  }
}

.popover__hiddenArrow {
  :global {
    .ant-popover-arrow {
      display: none;
    }
  }
}

.sectionHeader {
  font-weight: 500;
  margin-bottom: 2px;
  color: #fff;
}

.popoverContainer {
  display: flex;
  flex-direction: column;
  @media @sm {
    flex-direction: row;
  }
  .speciesSection,
  .techniqueSection {
    margin-left: 0;
    margin-top: 15px;
    @media @sm {
      margin-left: 12px;
      margin-top: 0;
    }
  }
}

.topItem {
  display: flex;
  align-items: center;

  .productImg {
    max-width: 25px;
    margin-right: 6px;
    border: 1px solid #6d8b96;
    border-radius: 8px;
    background-color: white;
  }

  .topItemName {
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
    .roboto();
    text-align: left;
    width: 23ch;
  }
  :global {
    .ant-btn > span {
      color: #fff;
      max-width: 18ch;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      text-decoration: underline;
      min-width: 130px;
      @media @sm {
        min-width: unset;
      }
    }
    .ant-btn {
      display: flex;
      align-items: center;
    }
    .ant-progress-line {
      padding-right: 5px;
      @media @sm {
        width: 136px;
      }
    }
    .ant-progress-bg {
      background-color: #000000;
    }
  }
  .topItemName__product {
    flex: 1;
    min-width: 130px;

    span {
      max-width: 100%;
      @media @sm {
        max-width: 20ch;
      }
    }
  }
  span:nth-child(3) {
    margin-left: 5px;
  }
}

.topItemName__notClickable {
  &:global {
    &.ant-btn > span {
      text-decoration: none !important;
      cursor: default;
    }
  }
}

.allReports {
  background-color: #19566b;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #4a7381;
  display: flex;
  justify-content: center;
  margin: 10px -16px -16px -16px;
  padding: 4px 0px;

  &:hover {
    background-color: #4a7381;
  }

  button {
    padding: 0;
    opacity: 0.85;
    font-weight: 400;
    .roboto();
    color: #fff;
    font-size: 14px;
  }

  :global {
    .ant-btn > span {
      text-decoration: underline;
      font-weight: bold;
    }
    .ant-btn-link:hover {
      color: #fff;
    }
  }
}

.reportCount {
  margin-left: 2px;
  min-width: 2.25em;
  text-align: right;
}

.reportStats {
  min-width: 61px;
  display: flex;
  justify-content: flex-end;
  color: #fff;
}

.loading {
  color: #fff;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;