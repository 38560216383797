@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.popup {
  position: fixed;
  right: 12px;
  left: 12px;
  background-color: #006b99;
  z-index: 10000;
  .termina(500);
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  // hidden by default
  pointer-events: none;
  bottom: -25px;
  opacity: 0;
  transition: all 150ms ease-out;

  @media @sm {
    width: 350px;
    left: auto;
    right: 24px;
  }
  *::selection {
    background-color: rgba(255, 255, 255, 0.509);
  }
}
.popup__visible {
  opacity: 1;
  pointer-events: all;
  bottom: 0;
}

.header {
  background-color: #042930;
  padding: 12px;
  text-align: center;
  position: relative;
}
.heading {
  font-size: 24px;
  margin-bottom: 0;
}
.close {
  position: absolute;
  right: 6px;
  top: 6px;
}

.body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form {
  .roboto();
}

.button.button {
  background-color: #e84b25;
  border: 2px solid #fff;
  .textColor(#fff);
  font-size: 16px;

  &:hover,
  &:active {
    border-color: #fff;
  }
}

.code {
  .roboto();
  font-weight: 700;
  font-size: 32px;
  display: block;

  &::selection {
    background-color: rgba(255, 255, 255, 0.509);
  }
}

.instructions {
  .roboto();
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;