@import (reference) '../../styles/media.less';

.modal {
  :global {
    .ant-modal-content {
      max-width: 530px;
      border-radius: 10px;
      margin-left: auto;
      margin-right: auto;
    }

    .ant-modal-body {
      padding: 0;
      border-radius: 8px; // this has to match the fishing report card border radius
      overflow: hidden;
    }

    .ant-modal-close {
      right: 0;
      top: 0;
      background-color: #fff;
      border-bottom-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .ant-modal-close-x {
      font-size: 13px;
      width: 35px;
      height: 35px;
      line-height: 35px;
    }
  }
}

.fishingReportCard.fishingReportCard {
  margin-top: 0;
}

.modalWrapper.modalWrapper {
  background-color: rgba(0, 0, 0, 0.6);
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;