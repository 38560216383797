@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';

.wrapper {
  width: 100%;
  font-size: 16px;
}

.searchModalWrapper.searchModalWrapper {
  :global {
    .ant-modal-footer {
      display: none;
    }
    .ant-modal {
      min-height: 100%;
      padding-bottom: 20px;
    }
    .ant-modal-content {
      min-height: calc(100vh - 30px);
    }
    .ant-modal-body {
      padding: 7px;
      @media @sm {
        padding: 24px;
      }
    }
    .ant-modal-close-x {
      font-size: 22px;
    }
    .ant-modal-centered .ant-modal {
      padding-bottom: 20px;
    }
  }
}

.searchWrapper {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 24px;
  margin-bottom: 12px;
  text-align: center;
}

.icon {
  color: #fff;

  @media @sm {
    color: unset;
    font-size: unset;
    padding: 0;
  }
}

.iconWrapper {
  padding: 10.5px;

  @media @sm {
    background-color: #fff;
    color: #042930;
    padding: 8px 12px 7px 22px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .antialias();
  }
}

.iconText {
  display: none;
  @media @sm {
    display: inline-block;
  }
}

.input {
  padding: 8px 11px;

  :global {
    .ant-input {
      font-size: 18px;
    }
    .ant-input-suffix {
      font-size: 18px;
    }
    .ant-input-clear-icon {
      font-size: 18px;
    }
  }
}

.tabContent {
  padding-top: 24px;
  padding-bottom: 48px;
}

.list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 -6px 24px -6px;

  li {
    width: 33.3%;
    padding: 0 6px;
    margin-bottom: 12px;

    @media @sm {
      width: 20%;
    }
  }
}
.list__wrap {
  flex-wrap: wrap;
}

.list__text {
  li {
    width: auto;
  }

  a {
    display: inline-block;
    background-color: #e6dede;
    border-radius: 100px;
    padding: 6px 12px;
  }
}
.list__subcategories a {
  background-color: #dee6e6;
}
.list__styles a {
  background-color: #e6e5de;
}

.list__brands {
  li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.list__products {
  li {
    width: 50%;

    @media @sm {
      width: 16.66%;
    }
  }
}

.topResultHeading {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.sectionHeading {
  font-size: 24px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}

.categoryHeader {
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: 0.5em;
  .termina(500);
  line-height: 1.2;
}

.subCategoryHeader {
  font-size: 1.17em;
  margin-top: 0;
  margin-bottom: 0.5em;
  .termina(500);
  line-height: 1.2;
}

.tabs__contentWrapper {
  padding-left: 0;
  white-space: nowrap;
  overflow: auto;
  @media @sm {
    padding-left: 12px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;