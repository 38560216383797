@import (reference) '../../styles/media.less';

.desktopOnly {
  display: none;
  @media @sm {
    display: unset;
  }
}

.mobileOnly {
  @media @sm {
    display: none;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;