@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.dividerNoMargin {
  margin: 0;
}
.dividerNoTopMargin {
  margin-top: 0;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.options {
  display: flex;
  flex-direction: column;
  padding: 24px;

  @media @sm {
    flex-direction: row;
  }
}

.heading {
  color: #000000;
  .termina();
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.84px;
  line-height: 24px;
}

.brandImg {
  flex: 0 0 auto;
  width: 100px;
  margin-left: 12px;
}
.brandImgLink {
  display: block;
  background-position: right center;
  background-size: contain;
  height: 100%;
  background-repeat: no-repeat;
}

.imgWrapper {
  margin-left: auto;
  margin-right: auto;
  flex: 0 0 auto;
  padding-right: 12px;
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.optionsSelector {
  padding-left: 12px;

  @media @sm {
    width: 50%;
  }
}
.optionsSelector__hasColors {
  padding-left: 0;
}

.fieldset {
  margin-bottom: 12px;
}

.optionLegend,
.stock {
  text-transform: uppercase;
  color: #938883;
  .termina();
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.56px;
  line-height: 18px;
}
.optionLegend__variantSelect {
  display: block;
  margin-bottom: 6px;
}

.optionLegend__color {
  .optionLegend();
  @media @sm {
    height: 30px;
  }
}

.optionName {
  color: #000;
  margin: 0 0.5em;
  text-transform: initial;
}

.optionRadio {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.optionUnavailable {
  opacity: 0.2;
}
.optionSelected {
  box-shadow: 0 0 0 2px #e84b25;
  position: relative;
  z-index: 1;
  color: #e84b25;
}

.colorsWrapper {
  display: flex;
  flex-wrap: wrap;
}

.colorLabel {
  display: block;
  position: relative;
  cursor: pointer;
  flex-basis: 20%;
  padding-right: 2%;

  @media @sm {
    flex-basis: 14%;
  }
}

.textLabel {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  min-width: 60px;
  text-align: center;
}

.variantSelect {
  width: 100%;
  max-width: 350px;
  margin-bottom: 12px;

  :global {
    .ant-select-selector {
      border-radius: 8px !important;
    }

    .ant-select-selection-item {
      font-weight: 500;
    }
  }
}

.priceQuantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  .termina();
  margin-bottom: 0;
  color: #657d7d;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.84px;
  line-height: 24px;
}
.originalPrice {
  .roboto();
  color: #000;
  font-size: 14px;
  line-height: 24px;
}
.priceCompare {
  margin-left: 0.3em;
  white-space: nowrap;
  color: #657d7d;
}
.price__sale {
  color: #d8363e;
}

.priceContainer {
  display: flex;
  flex-direction: column;
}

.quantityWrapper {
  .termina();
  text-align: right;
  font-size: 12px;
  font-weight: 500;

  @media @sm {
    margin-bottom: 12px;
  }
}
.quantityInput {
  width: 60px;
  border: 2px solid #000;

  &.disabled {
    border-color: unset;
  }
}

.bottomButtons {
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  @media @sm {
    flex-direction: row;
  }
}

.newReleaseTag {
  background-color: rgba(43, 237, 4, 0.5);
  border-radius: 2px;
  display: inline-block;
  padding: 0 2px;
  font-weight: normal;
  font-size: 12px;
  margin-left: 0.3em;
  word-break: keep-all;
  vertical-align: middle;
}

.variantTitle {
  .termina();
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.56px;
  line-height: 18px;
  margin-bottom: 12px;
  margin-right: 12px;
}

.detailLink {
  text-align: center;
}

.allColors {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
}

.allColorsItem {
  width: 40px;
}

.shippingContainer {
  justify-content: center;
  padding-bottom: 24px;

  @media @sm {
    display: flex;
  }

  svg {
    color: #237804;
    margin: 24px auto 0;
    @media @sm {
      margin: 0 1em;
    }
  }
  span {
    svg {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
.shipFast {
  color: #237804;
  margin: 0 0 12px 0;
  text-align: center;
  @media @sm {
    margin: 0 0 24px 0;
    text-align: left;
    margin-bottom: 0;
  }
}
.shipDrop {
  color: rgba(0, 0, 0, 0.6);
}

.shippingEstimate {
  text-align: center;
  margin: 12px 0 0 0;
  svg {
    margin: 0;
  }
  @media @sm {
    text-align: left;
    margin: 0 0 0 24px;
  }
}

.fullDetails {
  text-align: center;
  padding: 12px 0;
}

.clarityParent {
  display: flex;
  margin-bottom: 1.5em;
  line-height: 1.2;
  flex-wrap: wrap;
  gap: 12px;
}

.clarityLabel {
  display: inline-flex;
  align-items: center;
}

.linkTitle {
  margin: 0 0.1em;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;