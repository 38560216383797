@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.cardContainer {
  display: flex;
  justify-content: center;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  height: 100%;
  background: #e5e5e5;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 75%;
  button {
    padding: 12px;
    span {
      font-size: 15px;
    }
  }
  p {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    margin-top: 6px;
    line-height: 18px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;