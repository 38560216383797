@import (reference) '../../styles/media.less';

.shareButton {
  position: absolute;
  top: 3px;
  right: 4px;
  display: flex;
  appearance: none;
  background-color: rgba(0, 0, 0, 0.7);
  gap: 6px;
  align-items: center;
  color: #fff;
  border-radius: 70px;
  border: none;
  padding: 5px 8px;
  cursor: pointer;
  z-index: 5;

  &:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.9);
  }

  span {
    font-size: 14px;
  }

  svg {
    min-width: 16px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;