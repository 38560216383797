@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.badge {
  position: absolute;
  right: 4px;
  bottom: 4px;
  .roboto(600);
  color: #fff;
  .antialias();
  font-size: 12px;
  line-height: 1;
  padding: 2px 4px;
  border-radius: 4px;
}

.badge__bestSeller {
  background-color: #308800;
}
.badge__onSale {
  background-color: #ff9948;
}
.badge__new {
  background-color: #d81a1a;
}
.badge__trending {
  background-color: #ff9948;
  padding: 2px 6px;
  .antialias();
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;