@import (reference) './media.less';
@import (reference) './typography.less';

.flexRow(@flex-direction: row, @align-items: stretch, @justify-content: flex-start, @gap: 0) {
  display: flex;
  flex-direction: @flex-direction;
  align-items: @align-items;
  justify-content: @justify-content;
  gap: @gap;
}

.flexColumn(@flex-direction: column, @align-items: stretch, @justify-content: flex-start, @gap: 0) {
  display: flex;
  flex-direction: @flex-direction;
  align-items: @align-items;
  justify-content: @justify-content;
  gap: @gap;
}

.flexColumnResponsive(@align-items: stretch, @justify-content: flex-start, @gap: 0) {
  .flexColumn();
  align-items: @align-items;
  justify-content: @justify-content;
  gap: @gap;
  @media @sm {
    flex-direction: row;
  }
}

.flexRowResponsive(@align-items: stretch, @justify-content: flex-start, @gap: 0) {
  .flexRow();
  align-items: @align-items;
  justify-content: @justify-content;
  gap: @gap;
  @media @sm {
    flex-direction: column;
  }
}

.flexWrap() {
  display: flex;
  flex-wrap: wrap;
}

.flexCenterCenter(@flex-direction: column, @align-items: center, @justify-content: center, @gap: 0) {
  display: flex;
  flex-direction: @flex-direction;
  align-items: @align-items;
  justify-content: @justify-content;
  gap: @gap;
}

.gridPlaceCenter() {
  display: grid;
  place-content: center;
}

.buttonAsText() {
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
}

.buttonAsLink() {
  .buttonAsText();
  color: #006b99;
  cursor: pointer;

  &:hover {
    color: #1b81a6;
  }
  &:active {
    color: #004c73;
  }
}

.antButtonOrange() {
  background-color: #e84b25;
  border-color: #e84b25;
  color: #fff;

  &:hover,
  &:focus {
    background-color: lighten(#e84b25, 5%);
    border-color: lighten(#e84b25, 5%);
    color: #fff;
  }

  &:active {
    background-color: #e84b25;
    border-color: #e84b25;
    color: #fff;
  }
}

.horizonLakeBlueBackground() {
  background: url('../assets/horizon-background-image.jpg');
  background-repeat: no-repeat;
  background-color: #004b66;
  background-blend-mode: multiply;
  background-size: cover;
}

.buttonOrange() {
  background-color: @omnia_orange_primary;
  border-color: @omnia_orange_primary;
  color: #fff;
  &:hover,
  &:focus {
    background-color: @omnia_orange_primary;
    border-color: @omnia_orange_primary;
    color: #fff;
  }
}

.antIconMatching() {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  .antialias();
}

.newBadge() {
  position: absolute;
  .roboto(400);
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 4px 6px;
  font-size: 10px;
  line-height: 1;
  background: #d81a1a;
}
