.h1 {
  font-size: 20px;
}
.h2 {
  font-size: 16px;
}
.product {
  display: flex;
  align-items: center;
}
.img {
  width: 30%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 12px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;