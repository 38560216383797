@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

.icon () {
  color: #fff;
  margin-right: 6px;
  font-size: 18px;

  @media @sm {
    min-width: 16px;
  }

  .nav_link__selected &,
  .nav_link__selected:hover & {
    color: rgba(37, 50, 56, 0.65);
  }
}
.navIcon {
  .icon();
}

.lockIcon {
  .icon();
  font-size: 16px;
}

.userIcon {
  .icon();
  font-size: 19px;
}

.header {
  .termina();
  background-color: @omnia_blue_primary;
  color: #fff;
  font-size: 14px;
  display: none;

  @media @sm {
    display: block;
  }

  @media print {
    display: none;
  }
}

.headerContent {
  display: flex;
}

.nav {
  list-style: none;
  margin: 0 0 0 auto;
  padding: 0;
  display: flex;
  flex-grow: 1;
}
.navListItem {
  display: flex;
  align-items: stretch;
  flex-grow: 0;

  & + & {
    border-left: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.accountButton {
  display: flex;
  flex-direction: column;
  padding-right: 1em;
  align-items: flex-end;
}
.navBadge {
  font-size: 8px;
  display: inline-flex;
  align-items: baseline;
}

.navListItem__search {
  max-width: 450px;
  padding: 12px 24px;
  margin-right: auto;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
}

.navLink {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: rgba(255, 255, 255, 1);
  transition: all 300ms ease-out;
  cursor: pointer;
  padding: 15px 24px;
  .antialias();

  &:hover {
    color: rgba(255, 255, 255, 0.65);
    background-color: rgba(255, 255, 255, 0.12);
  }
}

.navLink__selected,
.navLink__selected:hover {
  background-color: #fff;
  color: rgba(40, 66, 79, 0.85);
  svg {
    color: @omnia_blue_primary;
  }
  &:focus {
    text-decoration: none;
  }
}

.reportButton {
  display: flex;
  flex-direction: row;
  color: #fff;
  svg {
    margin-right: 0.5em;
    font-size: 17px;
  }
}

.navText {
  font-size: 12px;
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.cartWrapper {
  position: relative;
  margin-top: 3px;
  :global {
    .ant-badge-count {
      transform: translate(-50%, -50%);
    }
    .anticon-shopping-cart {
      font-size: 19px;
    }
  }
}

.logoLink {
  display: flex;
  align-items: center;
  padding: 16px 36px 16px 0;
  width: 170px;
  flex-shrink: 1;
}

.carouselContent {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.carouselIcon {
  margin-right: 0.2em;
  font-size: 20px;
}

.carousel {
  :global {
    .slick-next:before,
    .slick-prev:before {
      content: '';
    }

    .slick-prev:hover,
    .slick-next:hover,
    .slick-prev:focus,
    .slick-next:focus {
      color: #fff;
    }
    .slick-next,
    .slick-prev {
      color: #fff;
      font-size: 1.5em;
      z-index: 1;
    }
    .slick-next {
      right: -25px;
    }
    .slick-prev {
      left: -25px;
    }
  }
}

.searchWrapper {
  width: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
  padding: 12px;
  background: #042930;
  z-index: 103;
  opacity: 0;
  pointer-events: none;
  transition: all 150ms ease-out;
}
.searchWrapper__focused {
  opacity: 1;
  pointer-events: all;
}

.dot {
  span {
    margin-left: 0.5em;
  }
}

.popover {
  z-index: 302;
  padding: 0;
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }
  }
}

.navListItem__shopTackle {
  flex-grow: 0;
  display: grid;
  place-content: center;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding-inline: 20px;
  font-size: 12px;
  font-weight: 400;
  &:hover {
    color: rgba(255, 255, 255, 0.65);
    background-color: rgba(255, 255, 255, 0.12);
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;