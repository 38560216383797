@import (reference) './product_variant_selector.less';

.fieldset {
  .fieldset();
}

.optionLegend {
  .optionLegend();
}

.optionName {
  .optionName();
}

.textLabel {
  .textLabel();
}

.optionUnavailable {
  .optionUnavailable();
}

.optionSelected {
  .optionSelected();
}

.optionRadio {
  .optionRadio();
}

.optionNameWithList {
  font-size: 11.5px;
  color: rgba(0, 0, 0, 0.85);
  text-transform: uppercase;
  margin-right: 7px;
  letter-spacing: 0.56px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;