@import (reference) './media.less';
@import (reference) './mixins.less';
@import (reference) './typography.less';
@import (reference) './colors.less';

.tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 2px solid #333333;
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  background-color: #fff;

  .tabs__left & {
    text-align: left;
  }
}

.tabs__searchUniversal {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 2px solid #333333;
  text-align: center;
  width: 100%;
  background-color: #fff;

  .tabs__left & {
    text-align: left;
  }
}
.tabs__fullWidth {
  padding-left: 0;

  @media @sm {
    padding-left: calc(50vw - 667px);
  }
}

.tab {
  display: inline-block;
  padding: 14px;
  border-bottom: 3px solid white;
  cursor: pointer;
  position: relative;

  .tabs__small & {
    padding: 14px;
  }
}

.tab__active {
  color: @omnia_orange_primary;
  border-bottom-color: @omnia_orange_primary;
}

.tabHeading {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin: 0;
  .antialias();
  font-size: 16px;

  @media @sm {
    font-size: 18px;
  }

  .tab__active & {
    color: @omnia_orange_primary;
  }

  .tabs__small & {
    font-size: 14px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;