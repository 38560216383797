@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.downIcon {
  font-size: 12px;
  margin-top: 2px;
  margin-left: 3px !important;
  svg {
    transition: all 150ms ease;
  }
}

.popoverContainer {
  .downIcon__rotate {
    svg {
      transform: rotate(180deg);
    }
  }
}

.reportsIcon {
  margin: 0 5px 0 0;
  stroke-width: 1.5;
}

.popoverButtonContent {
  display: flex;
  gap: 2px;
  padding: 9px 13px;
}

.noPadding {
  padding: 0 !important;
}

.fishingReportsIcon {
  width: 17px;
  margin-right: 5px;
  margin-top: 1px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;