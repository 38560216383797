@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.colorsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.colorLabel {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 6px 14px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  img {
    border-radius: 8px;
  }
  &:hover {
    background-color: #f5f5f5;
  }
}
.optionSelected {
  box-shadow: 0 0 0 2px #e84b25;
  position: relative;
  z-index: 1;
  color: #e84b25;
  border-radius: 8px;
}

.optionSelected {
  box-shadow: 0 0 0 2px #e84b25;
  position: relative;
  z-index: 1;
  color: #e84b25;
  border-radius: 8px;
}
.optionUnavailable {
  opacity: 0.4;
}

.optionRadio {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.drawerColorName {
  font-size: 14px;
  margin-block: auto;
  margin-left: 11px;
  font-weight: 500;
  white-space: wrap;
}

.drawerContainer {
  :global {
    .ant-drawer-header {
      padding: 0;
      display: block;
    }
    .ant-drawer-close {
      position: absolute;
      top: 8px;
      left: 2px;
    }
    .ant-drawer-body {
      padding: 0;
    }
    .ant-drawer-content-wrapper {
      width: calc(100% - 18px) !important;
    }
  }
}

.colorSelectorButton {
  :global {
    .ant-icon-down {
      text-align: right;
      padding-right: 8px;
    }
  }
  margin-bottom: 14px;

  .colorName {
    flex: 1;
    text-align: left;
    padding-left: 8px;
    line-height: 1.2em;
    white-space: wrap;
  }
  .emptyColor {
    flex: 1;
    text-align: left;
    line-height: 50px;
  }
  img {
    width: 50px;
    height: 50px;
  }
}

.colorTag {
  .roboto(500);
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.28px;
  margin-bottom: 12px;
  padding-top: 12px;
  margin-top: -12px;
}

.newBadge {
  margin-left: 8px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;