@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

.fishingReportRow {
  display: flex;
  flex-direction: column;
  isolation: isolate;
  margin-top: 20px;
  @media @sm {
    flex-direction: row;
    margin-top: 30px;
  }
}

.mobileCard {
  transition: height 300ms ease;
  overflow: hidden;
}

.fishingReportCard {
  flex: 1;
}

.fishingReportSidebar {
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  background-color: @omnia_blue_primary;
  color: white;
  padding: 12px;
  @media @sm {
    box-shadow: 0px 2px 9px rgb(0 0 0 / 15%);
    border-radius: 8px 0 0 8px;
    padding: 12px 18px;
    flex: 0 0 330px;
  }
  @media @lg {
    flex: 0 0 400px;
  }
}

.statsHeader {
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 2px;
  color: white;
  text-align: left;
  gap: 6px;
  a {
    display: block;
    font-size: 24px;
    font-weight: 300;
    .textColor(#fff);
  }
}

.statsHeader__mobile {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  p {
    font-size: 20px;
    margin-bottom: 6px;
  }
  span {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 2px;
    display: block;
  }
}

.statsSection {
  border-radius: 10px;
}

.reportImprovements {
  display: inline-block;
  padding: 12px;
  background-color: #fafbfa;
  .roboto(500);
  font-size: 12px;
}

.improvementsItem {
  margin-top: 6px;
}

.blueSectionStyle {
  background-color: #104051;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  color: white;
  border-radius: 8px;
  margin-bottom: 16px;
}

.datesSection {
  .blueSectionStyle();
  padding: 16px;
  p {
    font-size: 16px;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
  }
}

.creditsSection {
  .blueSectionStyle();
  display: flex;
  justify-content: space-evenly;

  padding: 10px;
  margin-block: 16px 4px;
  @media @sm {
    padding: 16px;
    margin-block: 0 16px;
  }
}

.creditCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  p {
    font-size: 15px;
    margin-bottom: 0;
    @media @sm {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
  span {
    font-size: 15px;
    @media @sm {
      font-size: 20px;
    }
  }
}

.lowerStatsSection {
  height: 100%;
  display: flex;
}

.reportActionButtons {
  margin-top: auto;
  display: flex;
  gap: 12px;
  width: calc(100% - 8px);
  button {
    width: 50%;
  }
}

.mobileCardFooter {
  display: grid;
  place-content: center;
  height: 50px;
  font-size: 16px;
  border-top: 1px solid #d0d0d0;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 2px 9px rgb(0 0 0 / 15%);
  background-color: #fff;
  position: relative;

  span {
    display: flex;
  }

  :global {
    .anticon {
      width: 12px;
      margin-left: 4px;
    }
  }
}

.rotatedArrow {
  transition: transform linear 250ms;
  transform: rotate(180deg);
}

.rotatedArrow__reverse {
  transition: transform linear 250ms;
  transform: rotate(0deg);
}

.creditsAndActionsSection {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.mobileDetailsSection {
  background: #fff;
  padding: 4px 12px 12px;
  position: relative;
}

.mobileDetailsSection__collapsed {
  background-image: linear-gradient(to bottom, transparent, transparent 152px, white 176px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.invisible {
  visibility: hidden;
}

.orangeText {
  color: @omnia_orange_primary;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;