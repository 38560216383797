@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.page {
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
}

.header {
  background: url('./site_banner.jpg'), rgba(0, 0, 0, 0.5);
  background-position: center 15%;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding: 80px 0 36px 0;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 0 100vmax rgb(0 0 0 / 50%);
  font-size: 22px;
  line-height: 26px;

  @media @sm {
    padding: 80px 15% 36px 15%;
  }

  a {
    .textColor(#fff);
    text-decoration: underline;
  }

  p {
    font-size: 16px;
    margin-bottom: 1em;

    @media @sm {
      font-size: 22px;
      line-height: 1.3em;
    }
  }
}
.bestState,
.lakesSection {
  h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  p {
    margin-bottom: 1em;
  }
}

.map {
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-block: 0 40px;
  @media @sm {
    padding-block: 0 60px;
  }

  svg {
    width: 100%;
  }
  path:hover,
  circle:hover {
    cursor: pointer;
    fill: #006b99;
  }
}
.mapSelect {
  width: min(259px, 80%);
  margin-inline: auto;
  display: block;
  margin-block: 12px 24px;

  :global {
    .ant-select-selection-search-input {
      font-size: 16px;
    }
  }
}

.lakesSection {
  background-color: #c1cbca;
  padding: 48px 0;
}

.lakes {
  margin: 0 -12px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
}

.lake {
  padding: 0 12px;

  @media @sm {
    width: 25%;
  }

  img {
    margin-bottom: 6px;
  }
}

.aboutOmnia {
  font-size: 22px;
  text-align: left;

  p {
    margin-bottom: 1em;
  }

  a {
    color: #006b99;
  }
}

.h1 {
  .termina(500);
  text-align: center;
  font-size: 44px;
  line-height: 1.2em;
  margin-bottom: 0.5em;

  @media @sm {
    font-size: 64px;
  }
}

.h2 {
  .roboto();
  font-weight: normal;
  text-align: center;
  line-height: 1.2em;
  font-size: 22px;
  margin-bottom: 0.5em;

  @media @sm {
    font-size: 36px;
  }
}

.video {
  margin: 0 auto;
}

.lakeCard {
  a {
    font-size: 12px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;