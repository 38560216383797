@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';

.fieldset {
  margin-block: 20px 12px;
}

.optionLegend {
  display: inline-flex;
  text-transform: uppercase;
  letter-spacing: 0.56px;
  line-height: 18px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  gap: 6px;
  align-items: baseline;
  .roboto(500);
}

.optionName {
  color: #938883;
  text-transform: none;
}

.stock {
  .optionLegend();
  margin-right: 1em;
}
.optionLegend__variantSelect {
  display: block;
  margin-bottom: 6px;
}

.stockValue {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.stockWrapper {
  display: flex;
  align-items: baseline;
}

.optionRadio {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.colorsWrapper {
  display: flex;
  flex-wrap: wrap;
}

.colorLabel {
  display: block;
  position: relative;
}

.colorImageButtonContainer {
  padding-right: 2%;
  flex-basis: 20%;
  cursor: pointer;

  img {
    border-radius: 8px;
  }

  @media @sm {
    flex-basis: 14%;
  }
}

.textLabel {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  min-width: 60px;
  text-align: center;
}

.optionUnavailable {
  opacity: 0.2;
}
.optionSelected {
  box-shadow: 0 0 0 2px #e84b25;
  position: relative;
  z-index: 1;
  color: #e84b25;
  border-radius: 8px;
}

.variantSelect {
  width: 100%;
  max-width: 350px;
  margin-bottom: 12px;

  :global {
    .ant-select-selector {
      border-radius: 8px !important;
    }

    .ant-select-selection-item {
      font-weight: 500;
    }
  }
}

.gridLinkWrapper {
  text-align: center;
}

.gridLink {
  .buttonAsLink();
  display: inline-block;
  margin-top: 24px;
  font-weight: bold;
}

.priceQuantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  .termina(500);
  margin-bottom: 0;
  color: #657d7d;
  font-size: 18px;
  letter-spacing: 0.84px;
  line-height: 24px;
}
.originalPrice {
  .roboto();
  color: #000;
  font-size: 14px;
  line-height: 24px;
}
.priceCompare {
  margin-left: 0.3em;
  white-space: nowrap;
  color: #657d7d;
}
.price__sale {
  color: #d8363e;
}

.quantityWrapper {
  .roboto(500);
  text-align: right;
  font-size: 14px;

  :global {
    .ant-input-number-handler-wrap {
      border-radius: 0 8px 8px 0;
    }
  }

  @media @sm {
    margin-bottom: 12px;
  }
}
.quantityInput {
  width: 60px;
  border: 2px solid #000;
  border-radius: 8px;
  input {
    border-radius: 8px;
  }

  &.disabled {
    border-color: unset;
  }
}

.bottomButtons {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  row-gap: 12px;
  :global {
    .ant-btn > span {
      font-size: 12px;
    }
  }
}

.actionButton:not(:last-child) {
  margin-left: 12px;
}

.pillsWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  align-items: baseline;
}

.pill {
  display: inline-block;
  padding: 4px 10px;
  cursor: pointer;
  font-size: 12px;
  .textColor(#fff);
  background-color: #657d7d;
  border-radius: 15px;
  margin-bottom: 10px;
}

.fishingReportCount {
  .termina(500);
  font-size: 12px;
  color: #e84b25;
  margin-right: 12px;
  cursor: pointer;
}

.lakeList {
  margin: 0 0 0 1.3em;
  padding: 0;
}

.variantTitle {
  .termina(500);
  font-size: 12px;
  letter-spacing: 0.56px;
  line-height: 18px;
  margin-bottom: 7px;
  margin-right: 12px;
}

.fire {
  color: transparent;
  stroke-width: 2;
  stroke: #1b81a6;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: inline-block;
  margin-left: 0.3em;

  &:hover,
  &.hot {
    color: #d0021b;
    stroke: #d0021b;
  }
}

.shippingContainer {
  margin-top: 12px;

  svg {
    color: #237804;
    margin: 24px auto 0;
    flex: 0 0 auto;
    @media @sm {
      margin: 0 1em;
    }
  }
  span {
    svg {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  @media @sm {
    display: flex;
  }
}
.shipFast {
  color: #237804;
  margin: 0 0 12px 0;
  text-align: center;
  @media @sm {
    margin: 12px 0 24px 0;
    text-align: left;
    margin-bottom: 0;
  }
}
.shipDrop {
  color: rgba(0, 0, 0, 0.6);
}

.shippingEstimate {
  text-align: center;
  margin: 12px 0 0 0;
  flex: 0 0 auto;

  svg {
    margin: 0;
  }
  @media @sm {
    text-align: left;
    margin: 12px 0 0 24px;
  }
}

.pickup {
  padding-top: 24px;
}

.pickupHeading {
  font-weight: bold;
  font-size: 16px;
}
.pickupList {
  margin: 0;
  padding: 0;
  list-style: none;
}
.pickupListItem {
  padding-left: 1.5em;
  position: relative;
  color: #237804;
}
.pickupListIcon {
  position: absolute;
  transform: translate(-1.4em, 25%);
}
.pickupListLink {
  color: #237804;
}

.subTitleSection {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.priceContainer {
  display: flex;
  flex-direction: column;
}
.clarityParent {
  display: flex;
  margin-bottom: 1.5em;
  line-height: 1.2;
  flex-wrap: wrap;
  gap: 12px;
}

.clarityLabel {
  display: inline-flex;
  align-items: center;
}
.linkTitle {
  margin: 0 0.1em;
}
.svgIcon {
  color: #657d7d;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
}
