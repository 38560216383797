@import (reference) '../../../styles/media.less';
@import (reference) '../../../styles/typography.less';

.sortRadios.sortRadios {
  display: flex;
  flex-direction: column;
}

.radioLabel__product.radioLabel__product {
  display: flex;
  align-items: center;
  height: 50px;

  :global {
    span:nth-child(2) {
      display: flex;
      align-items: center;
    }
  }
}

.selectLabelDefault__product {
  height: 34px;
  :global {
    .ant-radio {
      top: 0.2em !important;
    }
  }
}

.radioLabelImg {
  img {
    max-width: 50px;
  }
  margin-right: 6px;
}

.selectLabelReportCount__product {
  position: absolute;
  right: 0;
  top: 1.05em;
}

.radioLabel.radioLabel {
  display: flex;
  align-items: baseline;
  height: 25px;
}

.selectLabelReportCount.selectLabelReportCount {
  position: absolute;
  right: 0px;
}

.checkboxLabel.checkboxLabel {
  display: flex;
  align-items: baseline;
  height: 25px;
  position: relative;
}

.checkboxGroup.checkboxGroup {
  :global {
    .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
}

.checkboxLabelReportCount {
  position: absolute;
  right: 0;
}

.mediaCheckboxes {
  display: flex;
  flex-direction: column;
  .checkboxLabelReportCount {
    position: absolute;
    right: 0;
  }
}

.buttonGroup {
  button {
    width: 47%;
    font-size: 15px;
  }
}

.variantRadios {
  display: flex;
  flex-direction: column;
  :global {
    .ant-btn-link {
      .roboto();
      color: #006b99;
      display: flex;
      font-size: 14px;
      padding: 0;
    }
  }
}

.radios,
.checkboxesContainer {
  display: flex;
  flex-direction: column;
  :global {
    .ant-btn-link {
      .roboto();
      color: #006b99;
      text-align: left;
      font-size: 14px;
      padding: 0;
      margin: 0 0 12px;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
}

.subheader {
  .roboto(500);
  font-size: 14px;
  margin: 12px 0 0;
}

.subheader__top {
  .roboto(500);
  font-size: 14px;
  margin: 0;
}

.radioName {
  line-height: 16px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;