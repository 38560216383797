@import (reference) '../../styles/media.less';

.card {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  max-width: 800px;
}

.cardContainer {
  border-radius: 8px;
}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.col1 {
  max-width: 400px;
  width: 100%;
}

.col2 {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media @sm {
    flex-direction: row;
  }
}

.col2__col {
  width: 100%;

  @media @sm {
    width: 50%;
    padding: 0 12px;
  }
}

.heading {
  font-size: 30px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 12px;
}

.joinGroupText {
  display: block;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 12px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;