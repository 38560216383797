@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) './product_variant_selector.less';

.variantSelect {
  width: 100%;
  max-width: 350px;
  margin-bottom: 12px;

  :global {
    .ant-select-selector {
      border-radius: 8px !important;
    }

    .ant-select-selection-item {
      font-weight: 500;
    }
  }
}

.optionLegend {
  display: inline-flex;
  text-transform: uppercase;
  color: #938883;
  .termina(500);
  font-size: 12px;
  letter-spacing: 0.56px;
  line-height: 18px;
}

.optionLegend__variantSelect {
  display: block;
  margin-block: 30px 6px;
  @media @sm {
    margin-block: 0 6px;
  }
}

.newBadge {
  display: none;
  margin-left: 6px;
}

.selectLabel {
  display: flex;
  align-items: center;
}

.dropdown {
  :global {
    .ant-select-item-option {
      :local {
        .newBadge {
          display: block;
        }
      }
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;