.modal {
  top: 10px;
  max-width: 885px;
}

.wrapper {
  margin: -24px;
}

.header {
  display: flex;
  text-align: center;
  padding: 24px 0;
  border-bottom: 1px solid grey;
}
.title {
  flex: 1;
  font-weight: bold;
}
.brand {
  flex: 1;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;