@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/seasons.less';

.product {
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 5px;
}

.productImgContainer {
  position: relative;
  margin-right: 5px;
  aspect-ratio: 1;
  width: 111px;
  @media @sm {
    width: 125px;
  }
}

.productImg {
  border: solid 1px #dcdcdc;
  border-radius: 8px;
  background-color: #ffffff;
}

.hotbaitContainer {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 2px;
  border-top-left-radius: 8px;
  background-color: #fb2422;
  color: #fff;
  clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 0% 100%);
  font-size: 11.5px;
  padding-inline: 4px 10px;
  .fire {
    color: #fff;
    font-size: 7px;
    margin-right: 4px;
    margin-top: -1px;
  }
}

.cardContents {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 1px;

  @media @sm {
    height: unset;
  }
  p,
  s {
    font-size: 12px;
    line-height: 1.2em;
    margin-bottom: 0;
    @media @sm {
      font-size: 14px;
    }
  }
  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    margin-bottom: 5px;
    color: #000000;
    font-size: 12.5px;
    line-height: 1.4em;

    &:hover {
      text-decoration: underline;
    }

    @media @sm {
      font-size: 14px;
      margin-bottom: unset;
    }
  }

  .price {
    color: #657d7d;
    margin-top: 2px;
  }

  .regPrice {
    margin-top: 2px;
  }
}

.sale {
  display: flex;
  .price {
    margin-right: 5px;
    color: #d8363e;
  }
}

.description {
  flex: 1;
}

.productCardFooter {
  :global {
    .anticon-shopping-cart,
    .anticon-mail {
      display: none;
      @media @md {
        display: block;
        margin-right: 1px;
      }
    }
    .anticon-mail {
      font-size: 13px;
      width: 13px;
    }
    .anticon-shopping-cart {
      width: 14px;
    }
  }

  button {
    width: 125px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;