@import (reference) '../../styles/media.less';

.name {
  em {
    font-weight: bold;
    font-style: normal;
  }
}

.link {
  padding: 4px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;