@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.footer {
  background-color: #f4f4f4;
  .termina();
  font-size: 12px;
  padding: 50px 0 10px;
  @media @lg {
    padding: 70px 0 20px 0;
  }
  color: #282829;

  @media print {
    display: none;
  }
}

.newsletterSection {
  background-color: #002b3a;
  font-size: 15px;
  line-height: 1.2;
  text-align: center;
  color: #fff;
  padding: 32px 0;
  font-weight: 300;

  strong {
    .termina();
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2em;
  }

  @media @lg {
    text-align: left;
  }

  :global {
    .ant-form-item-explain-error {
      text-align: center;
    }
  }

  p {
    width: 85vw;
    margin: 0 auto 16px;
    line-height: 1.6em;
    @media @xs {
      width: unset;
      margin: 2px 0 16px;
      line-height: 1.2em;
    }
    @media @lg {
      margin: 2px 0 0;
    }
  }
}

.newsletterSection > div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.subheading {
  .termina();
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0.25em;
  color: rgba(40, 40, 41, 0.95);
  text-align: center;
  @media @lg {
    text-align: left;
  }
}

.links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media @lg {
    display: block;
    text-align: left;
  }
}

.customerSupport {
  margin-top: 20px;
  margin-bottom: 0.25em;
  .termina();
  font-weight: 500;
  font-size: 12px;
  color: rgba(40, 40, 41, 0.95);
  text-align: center;
  @media @lg {
    margin-top: 0;
    text-align: left;
  }
}

.link {
  .roboto();
  color: rgba(40, 40, 41, 0.95);
  line-height: 23px;
  display: inline-block;
  word-break: break-word;
  font-weight: 300;
  transition: all ease-in-out 0.1s !important;
  font-size: 16px;
  &:hover {
    color: #282829;
    font-weight: 400 !important;
    transform: scale(1.00005);
  }
}

.svg {
  display: block;
  max-width: 100%;
  height: auto;
}

.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media @lg {
    display: block;
  }
}

.appstores {
  display: flex;
  padding: 10px 0;

  li {
    transition: all ease-in-out 0.3s;
    &:hover {
      transform: scale(1.05);
    }
  }
}

.appleStoreIcon {
  margin-left: 3px;
  @media @lg {
    margin-left: 0;
  }
}
.googleplayIcon {
  margin-left: 8px;
}

.socials {
  display: flex;
  height: 30px;
  padding: 10px 0 40px;
  @media @lg {
    padding: 10px 0 45px;
  }
  span {
    font-size: 23px;
    transition: all ease-in-out 0.3s;
    margin: 0 6px;
    @media @lg {
      margin: 0 10px 0 0;
    }
    &:hover {
      transform: scale(1.15);
    }
  }
}

.mobileGutter {
  margin-bottom: 24px;
  @media @sm {
    margin-bottom: unset;
  }
}

.contact {
  .roboto();
  margin-top: 20px;
  font-weight: 300;
  p {
    margin-bottom: 0;
    font-size: 16px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media @lg {
    display: block;
    text-align: left;
  }
}

.subfooter {
  .termina();
  font-size: 10px;
  background-color: #f4f4f4;
}

.subfooter__extraPadding {
  padding-block: 10px;
}

.subfooterList {
  .roboto();
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 0;
  align-items: center;
  flex-direction: row;

  @media @lg {
    justify-content: flex-start;
  }

  li > * {
    display: block;
    color: rgba(40, 40, 41, 0.95);
    padding: 0px 9px;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
  }

  :global {
    .ant-divider-vertical {
      border-left-color: rgba(40, 40, 41, 0.95);

      top: 0;
    }
  }
}

.currentYear > span {
  line-height: 20px;
  text-align: center;
  @media @xs {
    padding: 0 32px 0 0;
  }
  @media @sm {
    padding: 0 18px 0 0;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;