@import (reference) '../../styles/media.less';

.banner {
  background-color: #e84b25;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  padding: 24px 24px 12px;
  color: #fff;
  margin-bottom: 24px;
}

.bannerHeading {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: bold;
}

.bannerLink {
  font-weight: bold;
  color: #fff;
}

.item.item {
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
  :global {
    .ant-row {
      .ant-form-item-label {
        padding-bottom: 2px;
      }
    }
    .ant-form-item-row {
      padding-top: 3px;
    }
  }
}

.selectItem {
  @media @sm {
    width: 60%;
  }
}

.label {
  font-weight: bold;
}

.signupBlurb {
  padding: 12px 0;
  div {
    border-bottom: solid 1px rgba(0, 0, 0, 0.7);
    strong {
      margin-bottom: 3px;
      display: block;
      font-size: 20px;
    }
  }
  p {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.passwordField {
  margin-bottom: 0;
}

.form.form {
  button {
    width: auto;
    margin-top: 0;
  }
  :global {
    .ant-alert-error {
      margin: 12px 0;
    }
  }
}

.labelHack {
  padding: 9px 15px;
}

.noPadding {
  padding: 0;
}

.existingFormDetail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  padding-bottom: 3px;
  @media @sm {
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
  strong {
    font-weight: 500;
  }
  border-bottom: 1px solid #eaeaea;
}

.formEdit {
  p {
    .existingFormDetail();
  }
  p:last-of-type {
    border-bottom: none;
    padding-bottom: 8px;
    @media @sm {
      padding-bottom: 15px;
    }
  }
}

.existingFormDetail__comments {
  margin-bottom: 10px;
  span {
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: block;
    color: #7f7f7f;
  }
  font-size: 13px;
}

.existingFormDetail__waterbody {
  border-bottom: none;
  margin-bottom: 12px;
  strong {
    font-weight: 600;
  }
  font-size: 16px;
}

.seasonValueIcon {
  display: block;
}
.seasonIcon {
  position: relative;
  margin-right: 2px;
  top: 2px;
}

.speciesValueIcon {
  display: flex;
  img {
    width: 40px;
    margin-right: 6px;
  }
}

.carouselWrapper {
  overflow: hidden;
  margin-block: -8px 10px;
  margin-right: -24px;
}

.outingDateRow {
  display: flex;
  gap: 40px;
}

.seasonItem {
  display: block;
  font-weight: 500;
}

.waterbodySeason {
  display: inline-flex;
  margin-top: 6px;
  div {
    margin-right: 4px;
  }
}

.productCardWrapper {
  margin-right: 14px;
  width: 108px;
}

.productCard {
  margin-top: 8px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 110px;
}

.productImgContainer {
  margin-right: 5px;
  aspect-ratio: 1;
  width: 110px;
}

.productImg {
  border: solid 1px #dcdcdc;
  border-radius: 8px;
  background-color: #ffffff;
}

.productImgLoading {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productTitle {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 15px;
}

.closeCircleIcon {
  position: absolute;
  top: -7px;
  right: -11px;
  background-color: white;
  border-radius: 50%;
  border: 0.5px solid black;
  padding: 6px;
  font-size: 9px;
}

.closeIcon {
  .closeCircleIcon();
}

.productCard__hotbait {
  margin-top: 15px;
  gap: 13px;
  display: flex;
  flex-direction: row;
  position: relative;

  .productTitle {
    font-size: 14px;
  }
  .closeIcon {
    .closeCircleIcon();
    right: unset;
    left: 100px;
    width: 20px;
  }

  .productImgContainer {
    flex: 0 0 110px;
  }
}

.carousel.carousel {
  :global {
    .flicking-arrow-prev {
      &:before {
        content: url('../../assets/active_arrow.png');
        top: 24%;
        left: -6%;
        transform: rotate(180deg) scale(0.7);
      }
      &:after {
        display: none;
      }
    }
    .flicking-arrow-next {
      &:before {
        content: url('../../assets/active_arrow.png');
        top: 24%;
        right: -6%;
        transform: scale(0.7);
      }
    }
  }
}

.hotbaitContainer {
  display: flex;
  position: absolute;
  top: -5px;
  background-color: #fb2422;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  padding: 2px 4px;
  .fire {
    color: #fff;
    font-size: 8px;
    margin-right: 3px;
    @media @sm {
      font-size: 8px;
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;