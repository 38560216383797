@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';
@import (reference) '../../styles/seasons.less';

.reportDetails {
  display: flex;
  padding-bottom: 6px;
  flex-direction: column;
  @media @sm {
    gap: 0 12px;
    padding-top: 4px;
    padding-bottom: 0;
    flex-direction: row;
    justify-content: space-between;
  }
}

.reportDetailsColumnOne {
  @media @sm {
    min-width: 290px;
    padding-right: 36px;
  }
}

.outingDate {
  color: #657d7d;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 1px;
  p {
    margin-bottom: 0;
  }
}

.waterTemp {
  color: #657d7d;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 1px;
  p {
    margin-bottom: 0;
    display: flex;
  }
  .thermIcon {
    margin-top: 2px;
  }
}

.reportDetailsColumnTwo {
  @media @sm {
    display: flex;
    flex-direction: column;
    //this width works with grid only
    width: 100%;
  }
  @media @lg {
    width: unset;
  }
}

.reportDetailsColumnTwo__small {
  @media @sm {
    width: 100%;
  }
}

.details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-inline-start: 0;

  @media @sm {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(123px, max-content));
    margin-left: auto;
    width: 96%;
    gap: 10px 14px;
  }

  @media @md {
    justify-content: end;
  }

  @media @lg {
    display: flex;
    grid-template-columns: unset;
    justify-content: flex-start;
    gap: 12px 31px;
    padding-right: 5px;
    width: 100%;
    margin-left: unset;
  }

  li {
    line-height: unset !important;
    width: 100%;
    margin-bottom: 6px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    @media @sm {
      justify-content: flex-start;
      border-bottom: none;
      width: auto;
      flex-direction: column;
    }
  }

  li:last-of-type {
    border-bottom: none;
  }

  .species {
    .detailsDetail {
      display: flex;
      flex-wrap: wrap;
    }
    img {
      width: 40px;
      margin-right: 6px;
    }
  }

  .technique {
    p:last-child {
      text-align: right;
      @media @sm {
        text-align: unset;
      }
    }
    p:first-child {
      padding-right: 12px;
      @media @sm {
        padding-right: unset;
      }
    }
  }
}

.details__small {
  @media @lg {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(123px, max-content));
    margin-left: auto;
    width: 96%;
    gap: 12px 24px;
    justify-content: end;
  }
}

.detailsCategory {
  font-weight: 600;
  margin-bottom: 0;
  @media @sm {
    margin-bottom: unset;
  }
}

.detailsDetail {
  margin-bottom: 6px;
  @media @sm {
    margin-bottom: unset;
  }
}

.seasonIcon {
  position: relative;
  top: 3px;
  margin-right: 7px;
}

.spring {
  .spring();
}

.summer {
  .summer();
}

.fall {
  .fall();
}

.winter {
  .winter();
}

.ice {
  .ice();
}

.subDetails {
  display: flex;
  align-items: center;
  gap: 0 15px;
  padding-bottom: 16px;
  min-width: 290px;
}

.outingDateMobile {
  padding-top: 4px;
  @media @sm {
    padding-top: unset;
  }
  :global {
    .anticon-calendar {
      color: #657d7d;
    }
  }
}
.outingDateMobile__noMedia {
  padding-top: 6px;
  border-top: 1px solid #eaeaea;
}

.waterTempMobile {
  display: flex;
  .thermIcon {
    margin-top: 4px;
    margin-right: 3px;
  }
}
.userDetails {
  color: #657d7d;
}

.seasonLabel__noOutingDate {
  margin-top: 4px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;