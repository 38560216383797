@import (reference) '../../styles/media.less';
@import (reference) '../../styles/colors.less';
.drawer {
  z-index: 1001;
  :global {
    .ant-drawer-body {
      background-color: #f7f7f7;
      padding: 0;
    }
    .ant-drawer-content-wrapper {
      z-index: 1001;
    }
  }
}

.cart {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cartHeaderBanner {
  border: 2px solid @green;
  background-color: #edf9e8;
  margin-top: 12px;
}

.cartHeaderContent {
  display: flex;
  align-items: center;
  padding: 12px;

  p {
    margin-bottom: 0;
    text-align: left;
  }
  :global {
    .anticon {
      font-size: 27px;
      margin-right: 7px;
      margin-top: -19px;
      display: none;
      @media @sm {
        display: block;
      }
    }
    svg {
      color: @green;
    }
  }
}

.items {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.header {
  flex: 0 0;
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6;
  padding: 12px 24px;
  text-align: center;
}

.footer {
  flex: 0 0;
  background-color: #fff;
  border-top: 1px solid #d6d6d6;
  padding: 12px 24px;
  text-align: center;
}

.body {
  padding-top: 24px;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
}

.section {
  margin-bottom: 24px;
  padding: 12px 24px;
  background-color: #fff;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}
.sectionHeading {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: bold;
}

.sectionBreakout {
  margin-left: -24px;
  margin-right: -24px;
}
.sectionBreakout__vertical {
  margin-top: -12px;
  margin-bottom: -12px;
}

.sectionNoPadding {
  padding-left: 0;
  padding-right: 0;
}

.headerHeading {
  margin-bottom: 0;
}
.itemsCount {
  font-size: 12px;
  margin-bottom: 0;
}

.summaryTitle,
.premiumTitle,
.discountsHeading {
  font-size: 16px;
}

.table {
  width: 100%;

  td:last-child {
    text-align: right;
  }
}

.rewards {
  em {
    font-style: normal;
    text-decoration: underline;
  }
}

.premiumImg {
  float: left;
  width: 42px;
  margin-right: 12px;
  color: #e84b25;
}

.premiumText {
  overflow: hidden;
}

.premiumBlurb {
  font-size: 12px;

  p {
    margin-bottom: 1em;
  }
  strong {
    color: #e84b25;
    font-size: 13px;
  }
}

.premiumActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rebateButton {
  margin-bottom: 12px;
}

.buttonAsLink {
  color: #006b99;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.promoCode {
  display: flex;
  justify-content: space-between;
}

.removePromo {
  appearance: none;
  border: 0;
  padding: 0;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0.6;
  background-color: transparent;
  margin-left: 1em;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.errorMessage {
  display: inline-block;
  color: #f5222d;
  font-size: 12px;
}

.shipWrapper {
  display: flex;
  align-items: flex-start;
  p {
    margin-bottom: 1em;
  }
}

.shipIcon {
  color: #237804;
  flex: 0 0 auto;
  margin-right: 12px;
  width: 42px;
  height: auto;
}

.shipFast {
  color: #237804;
}

.lineItems {
  margin-bottom: 24px;
}
.lineItem {
  & + & {
    margin-top: -1px;
  }
}

.promosSpace {
  width: 100%;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;