@import (reference) '../../styles/media.less';

.miniMapModal {
  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-content {
      border-radius: 8px;
      max-height: 93.5dvh;
      @media @sm {
        max-height: 100%;
      }
    }
    .ant-modal-close {
      background: white;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      border: 1px solid #104051;
      top: -16px;
      right: -6px;
      @media @sm {
        top: -18px;
        right: -18px;
      }
    }
    .ant-modal-close-x {
      width: 33px;
      height: 33px;
      font-size: 13px;
      line-height: 33px;
      color: rgba(0, 0, 0, 0.75);
    }
  }
}

.mapModalWrapper {
  position: relative;
  border-radius: 8px;
  width: 100%;

  @media @sm {
    aspect-ratio: 1;
    max-height: 85vh;
  }
}

.map {
  :global {
    .mapboxgl-map {
      border-radius: 8px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      max-height: 93dvh;
      .mapbox-improve-map {
        display: none;
      }
    }
  }

  height: calc(90dvh);
  @media @sm {
    height: auto;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;