@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.sectionHeading {
  color: #000000;
  p {
    .roboto();
    font-size: 14px;
  }
}

.fishingReportList {
  margin: 0;
  padding-inline-start: 0;
  list-style: none;
  padding-bottom: 40px;
}

.fishingReportsFilters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media @lg {
    justify-content: flex-start;
  }
  position: sticky;
  background-color: #ffffff;
  z-index: 99;
  padding: 12px 10px 12px 10px;
  margin-bottom: 0;
  margin-left: -10px;
  margin-right: -10px;
  top: 48px;

  @media @sm {
    top: 0;
    padding: 20px 10px 20px 10px;
  }
}
.waterbodyFilter {
  flex: 1 100%;
  margin-top: 20px;
  div {
    .roboto();
    font-size: 12px;
    padding: 8px;
  }
  span {
    font-size: 1.25em;
  }
}
.selects.selects {
  width: calc(100vw - 24px);
  @media @sm {
    width: 100%;
  }
  :global {
    .ant-select-selector {
      height: 50px !important;
    }
  }
}

.productSelect {
  min-width: 360px;
}

.seasonSelect.seasonSelect {
  @media @sm {
    min-width: 136px;
    max-width: 155px;
  }
}

.speciesSelect {
  @media @sm {
    min-width: 136px;
    max-width: 155px;
  }
}

.sortingSelect {
  max-width: 126px;
  margin-left: auto;
}

.selectContainer {
  padding-right: 10px;
  :global {
    .ant-radio-group {
      &:not(.ant-select-open) {
        .ant-radio,
        .ant-radio-wrapper > input {
          display: none;
        }
        :local {
          .selectLabelReportCount,
          .selectLabelReportCount__default,
          .selectLabelReportCount__season,
          .selectLabelReportCountSeason__default {
            display: none;
          }
          .selectLabel {
            height: 50px;
            align-items: center;
          }
          .selectLabel__default {
            margin-top: 0;
            height: 50px;
            align-items: center;
            :global {
              .ant-radio-wrapper {
                display: none;
              }
            }
          }
        }
      }
    }
    span.ant-radio + * {
      padding-left: 0;
    }
  }
}

.selectLabelReportCount.selectLabelReportCount {
  position: absolute !important;
  right: 0 !important;
}

:global {
  .species_select {
    .ant-select-item {
      max-height: 35px;
    }
  }
}

.selectContainer__sorting {
  :global {
    .ant-radio-group {
      &:not(.ant-select-open) {
        .ant-radio {
          display: none;
        }
        :local {
          .selectLabelReportCount {
            display: none;
          }
          .selectLabel {
            height: 50px;
            align-items: center;
          }
        }
      }
    }
    span.ant-radio + * {
      padding-left: 0;
    }
  }
}

.selectContainer__product {
  padding-right: 14px;
  :global {
    .ant-space-item {
      &:not(.ant-select-open) {
        :local {
          .selectLabelImg {
            max-width: 36px;
          }
          .selectLabelReportCount__product {
            display: none;
          }
          .selectText {
            max-width: 260px;
            @media @sm {
              max-width: unset;
            }
          }
        }
      }
    }
  }
}

.fishingReport {
  margin-bottom: 24px;
}

.selectLabel.selectLabel {
  display: flex;
  align-items: baseline;
  height: 25px;
}

.selectLabel__product {
  display: flex;
  align-items: center;
  height: 50px;
}

.selectLabelImg {
  max-width: 50px;
  margin-right: 6px;
}

.selectLabelReportCount__product {
  position: absolute;
  right: 17px;
  top: 20px;
}

.allFiltersButton {
  :global {
    .ant-btn {
      border: rgb(217, 217, 217) solid 1px;
      .roboto() !important;
      height: 50px;
      svg {
        font-size: 12px;
      }
      span:last-child {
        font-size: 14px;
        font-weight: 400;
      }
      display: block;
      &:focus {
        border-color: rgb(217, 217, 217);
        color: #000000;
      }
    }
  }
}

.filters__desktop {
  display: none;
  @media @lg {
    display: flex;
  }
}

.filters {
  display: flex;

  :global {
    .ant-btn-link {
      color: unset;
      .roboto();
      align-self: center;
      font-size: 14px;
      padding: 5px 12px;
      span {
        text-decoration: underline;
      }
      &:hover,
      &:focus {
        color: unset;
      }
    }
  }
}

.emptyReportContainer {
  border: solid 1px #d9d9d9;
  min-height: 128px;
  color: #bfbfbf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  svg {
    margin-bottom: 6px;
  }
  p {
    margin-bottom: 0;
    line-height: 20px;
  }
}

.loadMoreButton {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}

.loading {
  margin-top: 36px;
}

// drawer styles
.drawerContainer {
  :global {
    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      padding: 0 12px 0;
    }
    .ant-collapse {
      background-color: unset;
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 12px 0px;
          font-size: 16px;
          font-weight: 600;
          .ant-collapse-arrow {
            margin-right: -12px;
            svg {
              position: absolute;
              right: 0;
              top: 20px;
            }
          }
        }
      }
    }
    .ant-collapse-content-box.ant-collapse-content-box {
      padding: 0;
    }

    .ant-collapse-borderless
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding-top: 0;
      padding-bottom: 12px;
    }
    .ant-drawer-header {
      .ant-drawer-header-title {
        position: relative;
        .ant-drawer-close {
          position: absolute;
          top: -9px;
          left: -17px;
        }
        .ant-drawer-title {
          text-align: center;
          padding-top: 19px;
        }
      }
    }
    .ant-radio-wrapper {
      margin-right: 0;
    }
  }
  h3 {
    .roboto();
  }
}

.buttonGroup {
  display: flex;
  justify-content: space-evenly;
  position: sticky;
  bottom: 0;
  background: #ffffff;
  padding: 10px 0;
  border-top: 1px solid #d9d9d9;
  margin: 0 -12px;

  button {
    width: 47%;
    font-size: 15px;
  }
}

.submitButton.submitButton {
  background-color: #e84b32;
  border-color: #e84b32;
  &:hover {
    background-color: #e84b32;
    border-color: #e84b32;
  }
}

.card {
  padding-right: 14px;
  list-style: none;
  border-radius: 8px;
  height: 804px;
}

.fishingReportCard {
  width: 387px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;