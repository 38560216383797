@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.productDetailsContainer {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

.productDetails {
  font-size: 15px;
  letter-spacing: 0.7px;
  line-height: 1.5;
  margin-bottom: 30px;

  @media @sm {
    margin-bottom: 50px;
  }

  p {
    margin-bottom: 1em;
  }

  ul {
    list-style: disc;
    margin-bottom: 1em;
    padding-left: 1em;
  }

  a {
    color: #006b99;
  }
}

.productTags {
  margin-bottom: 14px;
  @media @sm {
    margin-bottom: 50px;
  }
}

.sidebarList {
  & + & {
    margin-top: 14px;
    @media @sm {
      margin-top: 48px;
    }
  }
  margin-bottom: 0;
}

.sidebarHeading {
  .termina();
  display: block;
  color: #938883;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.itemIcon__withCount {
  .itemIcon();
  &:hover {
    cursor: pointer;
  }
}

.itemName {
  display: block;
  .roboto(700);
  margin-left: 9px;
  .textColor(#000000d9);
}

.itemCount {
  display: block;
  .roboto(400);
  margin-left: 4px;
  .textColor(#000000d9);
}

.itemIcon {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0em;
  margin-bottom: 9px;
  img {
    width: 60px;
  }
}

.showMoreItemsButton {
  margin: 0 0 0 -12px;
  padding: 12px;
}

.descriptionData.descriptionData {
  margin-bottom: 50px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media @sm {
    align-items: center;
  }

  table {
    border-collapse: collapse;
    border: 0;
    margin-bottom: 50px;
  }
  thead {
    .termina();
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.65px;
    line-height: 24px;
  }
  th,
  td {
    border: 1px solid #000;
    padding: 0.6rem;
    min-width: 6rem;
    text-align: left;
    font-size: 15px;
    margin: 0;
  }

  iframe {
    width: calc(100vw - 24px);
    height: calc((100vw - 24px) * 0.562);
    max-width: 640px;
    max-height: 360px;
    margin-bottom: 50px;
  }

  :global {
    .omnia_wistia_wrapper {
      width: 100%;
      max-width: 640px;
    }
  }
}

.itemIcon__techniques {
  .itemIcon();
  margin-bottom: 0;
  img {
    width: 45px;
  }
}

.itemIcon__techniquesWithCount {
  .itemIcon__techniques();
  &:hover {
    cursor: pointer;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;