@import (reference) './media.less';
@import (reference) './mixins.less';
@import (reference) './typography.less';

a:focus:not(:focus-visible) {
  text-decoration: none;
}

.ant-badge-count {
  box-shadow: none;
}

.ant-btn {
  .termina();
  font-size: 12px;
  font-weight: 500;
  border-color: #000000;
  color: #000000;

  &:hover {
    border-color: #222;
    color: #222;
  }

  &:active {
    border-color: #000;
    color: #000;
  }

  &.btn--active {
    background-color: #000000;
    border-color: #000000;

    &:hover,
    &:focus {
      background-color: #000000;
      border-color: #000000;
    }
  }
}
.ant-btn-primary {
  color: #fff;
  border-color: #006b99;

  &:hover,
  &:focus {
    color: #fff;
    border-color: #1b81a6;
  }

  &:active {
    color: #fff;
    border-color: #004c73;
  }
}

.ant-btn-link {
  color: #006b99;
  border-color: transparent;
  background: transparent;
  box-shadow: none;

  &:hover {
    color: #1b81a6;
    border-color: transparent;
  }
  &:active {
    color: #004c73;
    border-color: transparent;
  }
}

.ant-btn-text {
  border-color: transparent;
  background: transparent;
  &:hover {
    border-color: transparent;
  }
  &:active {
    border-color: transparent;
  }
}

.ant-form-item-hidden {
  display: none;
}
.ant-form-item-label > label {
  cursor: pointer;
}
.ant-select-selection-search-input {
  font-size: 16px;
}

.btn--shop {
  .termina();
  font-size: 10px;
  letter-spacing: 0.47px;
  text-transform: none;
  .antButtonOrange();

  @media @sm {
    font-size: 12px;
    letter-spacing: 0.56px;
  }
}

.ant-btn-group {
  display: inline-flex;
}

.ant-card {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.ant-carousel {
  color: currentColor;
}

.ant-collapse--no-padding-content {
  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }
}

.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ant-drawer-body {
  display: flex;
  height: 100%;
  min-height: 0; // fix for firefox
}

.ant-form legend {
  color: inherit;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  border: 0;
}

.ant-message {
  font-size: 20px;
}

.ant-modal {
  top: 50px;
}

.ant-tabs-bar {
  margin-bottom: 0;
  background-color: #fff;
}

.ant-tag-checkable {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-tree > li:first-child {
  padding-top: 0;
}
.ant-tree li ul {
  padding-left: 10px;
}
.ant-tree li .ant-tree-node-content-wrapper {
  color: rgba(40, 66, 79, 0.85);
  text-transform: capitalize;
}

.ant-tree-switcher_open .anticon-plus path:first-of-type {
  opacity: 0;
}

//
// Checkable Tag overrides
//

.style {
  &.ant-tag-checkable-checked {
    background-color: #faad14;
  }
  &.ant-tag-checkable:active {
    background-color: inherit;
  }
  &.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: inherit;
  }
}

.species {
  &.ant-tag-checkable-checked {
    background-color: #389e0d;
  }
  &.ant-tag-checkable:active {
    background-color: inherit;
  }
  &.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: inherit;
  }
}

.season {
  &.ant-tag-checkable-checked {
    background-color: #0077b3;
  }
  &.ant-tag-checkable:active {
    background-color: inherit;
  }
  &.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: inherit;
  }
}

.big-pill {
  line-height: 30px;
  height: 32px;
  padding: 0 15px;
  margin-bottom: 8px;

  border-radius: 30px;

  &.ant-tag-checkable-checked {
    background-color: #1890ff;
  }
  &.ant-tag-checkable:active {
    background-color: #1890ff;
  }
  &.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #04537a;
  }

  @media @sm {
    line-height: 40px;
    height: 42px;
    padding: 0 20px;
  }
}

// weird wrapping on columns
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
}

.ant-divider {
  border-top-color: #c1cbca;
}
.ant-divider-vertical {
  border-left-color: #c1cbca;
}

//
// pagination
//

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  color: #e84b25;
  .termina();
  font-size: 12px;
}

.ant-pagination-prev {
  margin-right: 50px;
}
.ant-pagination-next {
  margin-left: 50px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
  background-color: transparent;
}

.ant-pagination-prev,
.ant-pagination-next {
  a {
    color: #e84b25;
    text-decoration: underline;
    &:hover {
      color: #e84b25;
      text-decoration: underline;
    }
  }

  &.ant-pagination-disabled a {
    color: rgba(0, 0, 0, 0.25);
    text-decoration: none;
  }
}

.ant-pagination-item {
  .termina();
  font-size: 12px;
  border: none;
  background-color: transparent;

  a {
    color: #e84b25;
    text-decoration: underline;

    &:hover {
      color: #e84b25;
      text-decoration: underline;
    }
  }
  &.ant-pagination-item-active a {
    text-decoration: none;
  }
}

.ant-affix {
  z-index: 100;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;