@import (reference) '../../styles/media.less';

.zoomControls {
  width: 30px;
  position: absolute;
  bottom: 60px;
  z-index: 99;
  right: 12px;

  @media @sm {
    top: 60px;
    left: 10px;
  }
}

.zoomControls__geoCoderHidden {
  @media @sm {
    top: 10px;
    left: 10px;
  }
}

// keep these for non-map pin usage?
.pin {
  cursor: pointer;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
}
.pin__selected {
  z-index: 1;
}

.searchSelect {
  margin: 9px;
  width: 37px;

  @media @sm {
    width: 240px;
  }
  :global {
    .ant-select-selector {
      border-radius: 5px !important;
      height: 37px !important;
      width: 103% !important;
      z-index: 98;
      .ant-select-selection-item {
        max-width: 198px;
        div {
          width: 220px;
          font-size: 15px;
          padding-left: 10px;
          padding-top: 1px;
          color: rgba(0, 0, 0, 0.75);
          div {
            flex-direction: row;
            line-height: unset;
            strong {
              font-weight: 400;
              padding-right: 3px;
            }
          }
        }
      }
      .ant-select-selection-placeholder {
        opacity: 0;
        @media @sm {
          opacity: 1;
          padding-top: 3px;
          font-size: 15px;
          letter-spacing: 0.45px;
          padding-left: 22px;
          color: rgba(0, 0, 0, 0.55);
        }
      }

      .ant-select-selection-search-input {
        height: 35px !important;
        padding-left: 19px !important;
      }
    }
    .ant-select-arrow {
      right: unset;
      left: 10px;
      color: rgba(0, 0, 0, 0.55);
      font-size: 15px;
      z-index: 98;
    }
    .ant-select-clear {
      z-index: 99;
    }
  }
}

.backToMapLink {
  width: 30px;
  height: 33px;
  background-color: #fff;
  position: absolute;
  bottom: 164px;
  right: 12px;
  border-radius: 8px;
  border: unset;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);

  @media @sm {
    bottom: unset;
    top: 12px;
  }

  span {
    display: block;
    width: 40px;
    height: 40px;
    margin-left: -2px;
  }
}

.mapboxgl-ctrl-bottom-right > .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
  &:after {
    right: 100px;
  }
}

.geoCoderCentered {
  :global {
    .ant-select.ant-select {
      @media @sm {
        margin: 9px 0 0;
        width: 400px;
        left: calc(47vw - 206px);
      }
      .ant-select-selection-item {
        max-width: 378px;
      }
      .ant-select-selector > .ant-select-selection-item > div > div {
        max-width: 400px;
      }
    }
  }
}

.temporaryMarker__selected {
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.3));
  svg {
    transform: scale(1.75);
  }
}

.temporaryMarker__animated {
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.3));
  svg {
    animation: pulse 1.6s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.5);
    animation-timing-function: ease-out;
  }
  30% {
    transform: scale(1.75);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: scale(1.5);
    animation-timing-function: ease-in-out;
  }
}

.boatLanding__selected {
  svg {
    transform: scale(1.75);
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;