@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) './product_variant_selector.less';

.fieldsetPdp {
  margin-block: 20px 12px;
  @media @sm {
    margin-block: 0 12px;
  }
}

.optionLegendColor {
  .optionLegend();
  margin: 0 0 -12px -2px;
  padding: 12px 0 12px 2px;
  width: 101%;
  @media @sm {
    padding: 17px 0;
  }
}

.optionLegendColor__sticky {
  background-color: #fff;
  position: sticky;
  z-index: 2;
  top: 51px;
  @media @sm {
    top: 0;
  }
}

.optionNameColor {
  color: #000;
  font-size: 15px;
  text-transform: none;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;