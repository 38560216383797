@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.newsletterButton {
  height: 38px;
  padding: 6.5px 15px;
  font-size: 15px;
  min-width: 90vw;
  @media @sm {
    min-width: unset;
  }
}
.newsletterForm {
  :global {
    @media @lg {
      .ant-form-item {
        margin-right: 0;
      }
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-control-input {
      min-height: 41px;
    }
    .ant-input-prefix {
      margin-right: 9px;
      margin-left: 4px;
    }
    .ant-input {
      .roboto();
      color: rgba(0, 43, 58, 0.5);
    }
    .ant-input-affix-wrapper {
      border-radius: 8px;
    }
  }
}

.newsletterForm__newsletterPage {
  font-weight: 300;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media @md {
    display: flex;
    flex-direction: row;
    align-items: unset;
  }

  :global {
    .ant-input-affix-wrapper {
      border: 1px rgba(9, 44, 59, 1) solid;
      height: 38px;
      font-size: 19px;
      width: 87vw;
      margin-bottom: 8px;
      @media @md {
        width: 351px;
        margin-right: 14px;
        margin-bottom: 14px;
      }
    }
    .ant-input {
      font-size: 14px;
    }
    .ant-form-item-explain-error {
      .roboto();
      text-align: center;
      margin-top: -5px;
    }
  }
}

.newsletterForm__footer {
  display: flex;
  flex-direction: column;
  gap: 11px;
  @media @sm {
    flex-direction: row;
    gap: 0;
  }

  justify-content: center;
  @media @lg {
    padding-left: 30px;
  }

  :global {
    .ant-input-affix-wrapper {
      height: 38px;
      font-size: 19px;
      width: 90vw;
      max-width: 351px;
      @media @sm {
        width: 40vw;
      }
      @media @lg {
        width: 24vw;
        margin-right: 16px;
      }
    }
    .ant-input {
      font-size: 16px;
    }
  }
}

.newsletterForm__modal {
  font-weight: 300;
  justify-content: center;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  @media @sm {
    display: flex;
    flex-direction: row;
    align-items: unset;
  }

  :global {
    .ant-input-affix-wrapper {
      border: 1px rgba(9, 44, 59, 1) solid;
      height: 38px;
      font-size: 19px;
      width: 90vw;
      @media @sm {
        width: 351px;
      }
    }
    .ant-input {
      font-size: 16px;
    }
  }
}

.newsletterForm__newsletter_popup {
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: center;

  button {
    height: 33px;
    font-size: 14px;
  }
}

.submitButton {
  justify-content: unset;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;