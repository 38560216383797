@import (reference) '../../styles/media.less';

.mediaModal {
  :global {
    .ant-modal-content {
      z-index: 9;
      width: fit-content;
      margin-inline: auto;
      border-radius: 8px;
      transition: height 350ms ease, width 350ms ease;
    }
    .ant-modal-body {
      padding: 6px;
    }
    .ant-modal-close {
      left: 0;
      top: 0;
      background-color: #fff;
      right: unset;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    .ant-modal-close-x {
      font-size: 13px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      @media @sm {
        font-size: 15px;
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }
    .plyr--video {
      aspect-ratio: unset;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50dvh;
  width: 70dvw;
  @media @sm {
    height: 50vh;
    width: 45vw;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;