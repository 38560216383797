@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.amboMini {
  p:first-of-type {
    font-size: 18px;
    @media @sm {
      font-size: 24px;
    }
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 3px;
  }
  p:nth-of-type(2) {
    color: #657d7d;
    a span {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.amboProfileContainer {
  @media @sm {
    margin-bottom: -10px;
  }
}

.noActivityAmbos {
  padding: 12px 16px;
  background-color: #fff;
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  @media @sm {
    grid-gap: 0;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.noActivityCallOut {
  text-align: center;
  color: #657d7d;
  padding: 16px 36px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  margin-bottom: 0;

  @media @sm {
    grid-column: 2;
    align-self: center;
    justify-self: center;
  }
}

.noMarginBlock {
  margin-block: 0;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;