@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';
@import (reference) '../../styles/mixins.less';

.text {
  padding-block: 12px;
}

.imgWrapper {
  position: relative;
}
.img {
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 6px;
  overflow: hidden;
  position: relative;
  aspect-ratio: 3/2;
}

.title {
  font-size: 16px;
  line-height: 20px;
  word-break: break-word;
  margin-bottom: 0;
}

.publicationDate {
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-transform: uppercase;
  color: #657d7d;
  display: flex;
  gap: 4px;
  align-items: center;

  :global {
    .anticon {
      font-size: 16px;
    }
  }
}

.badge {
  position: absolute;
  .roboto(400);
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 4px 6px;
  font-size: 10px;
  line-height: 1;
}

.badge__topLeft {
  top: 4px;
  left: 4px;
}

.badge__bottomLeft {
  bottom: 8px;
  left: 4px;
}

.badgeIcon {
  width: 15px;
  margin-right: 4px;
}

.newBadge {
  .newBadge();
  .badge__topLeft();
  background: #d81a1a;
}

.badge__live {
  background-color: #00b62d;
}

.iconPlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.source {
  position: absolute;
  top: 4px;
  right: 2px;
  width: 15%;
  padding-top: 9%;
  background-size: contain;
  background-repeat: no-repeat;
}

.link {
  color: #000;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;

  &:hover,
  &:focus {
    color: #000;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;