@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';

.carouselWrapper {
  margin: 12px 0 0 0;
  padding: 0;
  list-style: none;
  width: 92vw;
  @media @sm {
    width: auto;
  }
}

.carousel {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel__viewport {
  overflow: hidden;
  margin-inline: 8px;
  position: relative;
  padding: 4px;
}

.carousel__viewport:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 10px);
  width: 10px;
  height: 100%;
}

.carousel__viewport:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 3px);
  width: 3px;
  height: 100%;
  z-index: 2;
}

.carousel__container {
  display: flex;
  flex-direction: row;
}

.item {
  margin-block: 0;
  margin-right: 6px;
  cursor: pointer;
}

.image {
  max-width: 60px;
  min-width: 60px;
  min-height: 60px;
  @media @sm {
    min-height: 80px;
    max-width: 80px;
    min-width: 80px;
  }
}

.item__selected {
  outline: rgb(40, 66, 79) solid 3px;
}

.button360,
.buttonVideo {
  appearance: none;
  display: block;
  padding: 0 5px;
  margin-inline: auto;
  background-color: #006b99;
  border: none;
  cursor: pointer;
  height: 60px;
  width: 60px;
  @media @sm {
    height: 80px;
    width: 80px;
  }
  img {
    margin-inline: auto;
  }
}

.buttonVideo {
  color: #fff;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buttonVideoText {
  font-size: 12px;
  text-transform: uppercase;
}

.rotateImagesWrapper {
  padding: 0 20px 8px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  :global {
    .ant-slider-handle {
      width: 36px;
      height: 36px;
      margin-top: -16px;
      margin-left: -18px;
    }
  }
}

.rotateImagesWrapper__modal {
  padding: 0 40px 8px;
}

.imgZoomed {
  z-index: 1000;
  background-color: #fff;
  border-radius: 8px;
  img {
    max-width: none;
  }
}

.mediaItem {
  width: 90px;
  @media @sm {
    width: 120px;
  }
  position: relative;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.85);
}

.mediaItemImg {
  height: 60px;
  min-width: 90px;
  @media @sm {
    min-width: 120px;
    height: 80px;
  }
}

.omniaVideoContainer {
  width: 100%;
  @media @sm {
    width: 83%;
  }
  @media @md {
    width: 100%;
  }
}

.modalContent {
  margin-top: 16px;
  @media @lg {
    width: 100%;
  }
}

.productMediaContainer {
  margin: auto;
  width: 69%;
  @media @sm {
    width: auto;
  }
}

.hoverImageContainer {
  flex: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.magnifyContainer {
  margin-inline: auto;
  @media @sm {
    max-width: 345px;
  }
  > div {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    img {
      border-radius: 8px;
    }
  }
}
.threeSixtyContainer {
  margin-inline: auto;
}

.threeSixtyContainer__modal {
  margin-inline: auto;
  @media @sm {
    max-width: 382px;
  }
}

.mediaCardContainer {
  width: 100%;
  margin-block: auto;
  @media @sm {
    margin-inline: auto;
    width: 509px;
  }
}

.miniMediaCarousel {
  padding-top: 0;
  padding: 0 12px 30px;
  max-width: 100%;
  @media @sm {
    padding: 25px 25px 40px;
    width: 490px;
  }
  @media @md {
    width: 650px;
    min-width: 650px;
  }
  @media @lg {
    width: 650px;
    min-width: 650px;
  }

  :global {
    .flicking-viewport {
      padding: 3px;
    }
  }
}

.omniaMediaHeroContainer {
  .flexColumn(@align-items: center, @justify-content: center);
}

.publicationDate {
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-transform: uppercase;
  color: #657d7d;
  display: flex;
  gap: 4px;
  align-items: center;

  :global {
    .anticon {
      font-size: 16px;
    }
  }
}
.text {
  padding-top: 6px;
  min-height: 75px;
  @media @sm {
    padding-bottom: 12px;
    align-self: flex-start;
  }
}

.title {
  font-size: 21px;
  line-height: 20px;
  word-break: break-word;
  margin-bottom: 6px;
}

.wistiaContainer {
  .flexColumn(@justify-content: center);
}

.wistiaContainer__modal {
  margin-inline: auto;
  @media @sm {
    width: 525px;
  }
}

.wistia {
  border-radius: 8px;
}

.modal {
  top: 23px !important;

  @media @sm {
    top: 0 !important;
  }
  :global {
    .ant-modal-close-x {
      width: 40px;
      line-height: 40px;
      @media @sm {
        width: 54px;
        line-height: 54px;
      }
    }
    .ant-modal-footer {
      padding: 0;
      border: none;
      display: flex;
      justify-content: center;
    }
    .ant-modal-header {
      padding: 15px 18px 0;
      @media @xs {
        padding: 26px 24px 0;
      }
      border: none;
      @media @sm {
        margin-bottom: -10px;
      }
    }
    .ant-modal-content {
      @media @sm {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100dvw - 32px);
      }
      @media @lg {
        width: 958px;
      }
    }
  }
}

.center {
  display: flex;
  justify-content: center;
}

.carouselButton {
  display: grid;
  place-content: center;
  border-radius: 50%;
  background: white;
  color: #000;
  outline: 1.5px lightgrey solid;
  border: none;
  :global {
    .anticon {
      width: 13px;
    }
  }
}

.carouselButton__disabled {
  opacity: 0.5;
}

.heroImgContainer {
  display: grid;
  grid-template-rows: 1fr auto;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;