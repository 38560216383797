@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

// EDIT CAREFULLY THIS IS TIED TO DASHBOARD AMBASSADORS
.topActionButtons {
  display: flex;
  justify-content: space-between;

  padding-block: 12px;
  @media @sm {
    padding-block: 24px;
  }

  div {
    display: flex;
  }
}

.waterbodyHeader {
  display: flex;
  justify-content: space-between;
}

.fileReportButton__noWaterbodies {
  height: 35px;
  @media @sm {
    margin-right: 12px;
  }
}

.waterbodyHeaderLinks {
  margin-block: 0;
  .lakeName {
    margin-right: 6px;
  }
}

.waterbodyHeaderLinks__noLakes {
  margin-block: 0;
}

.lakeName {
  .roboto();
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.lakeStates {
  .lakeName();
  white-space: nowrap;
}

.collapseContainer {
  :global {
    .ant-collapse-ghost > .ant-collapse-item {
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid #f0f0f0;
      margin-bottom: 24px;
    }
    .ant-collapse-icon-position-end
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      border-radius: 50%;
      border: 1px solid #d9d9d9;
      background: #fff;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
      padding: 8px;
    }
    .ant-collapse-header {
      border-bottom: 1px solid #f0f0f0;
    }
    .ant-collapse-icon-position-end
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      top: 29px;
      @media @sm {
        top: 50%;
      }
    }
    .ant-collapse > .ant-collapse-item:last-child,
    .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
      border-radius: 8px;
    }
    .ant-collapse-content-box {
      border-radius: 0 0 8px 8px;
      padding-right: 0;
    }
  }
  section:nth-of-type(2) {
    margin-top: 24px;
  }
  section:last-of-type {
    margin-bottom: -10px;
  }
}

.collapseContainer__waterbody {
  .collapseContainer();
  :global {
    .ant-collapse-icon-position-end
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      @media @sm {
        margin-top: -1px;
      }
    }
  }
}

.list {
  margin-block-start: 0;
  padding-inline-start: 0;
}

.mediaWrapper {
  margin-bottom: -10px;
}

.miniHeader {
  .roboto();
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: baseline;
  gap: 12px;
  margin-bottom: 16px;

  > p {
    margin-bottom: 1em;
  }
}

.redCircle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #d81a1a;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  min-width: 20px;
  font-weight: 600;
}

.lakeLink {
  padding-left: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  @media @sm {
    margin-top: -5px;
  }
  span {
    font-size: 17px;
  }
}

.noReportsWaterbodyGrid {
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  display: grid;
  grid-gap: 12px;
  padding: 12px 16px;
  background-color: #fff;
  margin-bottom: 24px;
  grid-template-areas: 'grid__1 grid__2 grid__3';
  grid-template-columns: 5fr 1fr;
  grid-template-rows: auto minmax(0, max-content);
  grid-template-areas:
    'grid__1 grid__3'
    'grid__2 grid__2 ';
  @media @sm {
    grid-gap: 0;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'grid__1 grid__2 grid__3';
  }
}

.grid__1 {
  grid-area: grid__1;
  @media @sm {
    grid-column: 1;
    align-self: start;
  }
}

.grid__2 {
  grid-area: grid__2;
  text-align: center;
  color: #657d7d;
  padding: 16px 36px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  margin-bottom: 0;

  @media @sm {
    grid-column: 2;
    align-self: center;
    justify-self: center;
  }
}

.grid__3 {
  grid-area: grid__3;
  display: flex;
  justify-content: flex-end;
  @media @sm {
    align-items: center;
    margin-right: 10px;
  }
}

.collapseAllButton {
  font-size: 14px;
  font-weight: 400;
}

.carousel {
  margin-right: -16px;
  @media @sm {
    margin-right: 0;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;