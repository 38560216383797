@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.carousel {
  overflow: hidden;
  max-width: 1330px;
  margin-bottom: 40px;
  @media only screen and (max-width: 1330px) {
    max-width: calc(100vw - 20px);
  }
  :global {
    .flicking-camera {
      margin-left: -12px;
    }
    .flicking-arrow-next {
      background: linear-gradient(272deg, #ffffff 23%, rgba(255, 255, 255, 0) 83%);
      height: 246px;
      width: 98px;
      @media @sm {
        height: 446px;
      }
    }
    .flicking-arrow-prev {
      background: linear-gradient(90deg, #ffffff 19%, rgba(255, 255, 255, 0) 83%);
      height: 246px;
      width: 102px;
      @media @sm {
        height: 446px;
      }
    }
  }
}

.carouselRelatedProducts {
  padding-bottom: 50px;
}

.productTile {
  width: 50%;
  padding-left: 14px;
  padding-right: 10px;
  flex: 0 0 auto; // for SSR flex container sizing

  @media @sm {
    width: 16.65%;
  }
}

.sectionHeading {
  font-family: termina, sans-serif;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.045em;
  line-height: 24px;
  padding: 18px 0 24px;
  border-top: 1px solid #c1cbca;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;