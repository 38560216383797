@import (reference) '../../styles/media.less';

.card {
  list-style: none;
  border-radius: 8px;
  width: min(100%, 430px);
  @media @md {
    width: calc(50% - 6px);
    height: 774px;
  }
  @media @lg {
    width: calc(33.33% - 8px);
  }
}

.carousel {
  margin-right: -16px;
  @media @sm {
    margin-right: 0;
  }
}

.frRows {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  row-gap: 20px;
  @media @md {
    justify-content: flex-start;
  }
}

.loadMoreButton {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;