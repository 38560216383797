@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/mixins.less';

@heroMobileHeight: 263px;
@cardWidth: 370px;
@mobileCardWidth: 320px;
@heroHeight: 327px;

.cardContainer {
  list-style: none;
  border-radius: 8px;
  width: 85%;
  margin-right: 8px;
  padding-bottom: 4px;
  width: @mobileCardWidth;
  @media @sm {
    margin-right: 14px;
    width: @cardWidth;
    margin-bottom: 0;
  }
}

.heroContainer {
  height: @heroMobileHeight;
}

.card {
  height: 100%;
  padding-bottom: 4px;
  width: @mobileCardWidth;
  list-style: none;
  @media @sm {
    width: @cardWidth;
  }
}

.productCardContainer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 75%;
  @media @sm {
    width: 100%;
  }
}

.productCardImageContainer {
  width: 99px;
}

.carousel {
  overflow: hidden;

  :global {
    .flicking-camera {
      margin-left: -7px;
      padding-left: 9px;
    }
    .flicking-viewport {
      padding-bottom: 9px;
      padding-right: 3px;
    }
  }
}

.fileReportCard {
  > div {
    max-width: 85%;
  }
}

.productCard.productCard {
  margin-bottom: 5px;

  button {
    width: 150px;
  }

  & > div > div > a > p {
    -webkit-line-clamp: 2;
  }
  max-width: 373px;
  @media @sm {
    max-width: 500px;
  }
}

.card__extraFooter {
  height: 756px;
  max-height: 756px;
}

.footer {
  display: flex;
  justify-content: center;
  background: #f0f0f0;
  padding: 5px;
  margin: 2px -14px -4px;
  cursor: pointer;
}

.productsModalWrapper {
  :global {
    .ant-modal-content {
      z-index: 9;
      width: fit-content;
      margin-inline: auto;
      border-radius: 8px;
    }
    .ant-modal-body {
      padding: 6px;
    }
    .ant-modal-close {
      left: -18px;
      top: -20px;
      background-color: #fff;
      right: unset;
      border-radius: 50%;
    }

    .ant-modal-close-x {
      background: white;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(0, 0, 0, 0.7);
      border-radius: 50%;
      font-size: 13px;
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
    .plyr--video {
      aspect-ratio: unset;
    }
  }
}

.productsModal {
  color: #000000;
  font-size: 14px;
  line-height: 21px;
  padding: 12px;

  strong {
    display: flex;
    gap: 5px;
  }
  a {
    display: block;
    .textColor(currentColor);
    &:hover {
      text-decoration: underline;
    }
  }
}

.commentsButtonsWrapper {
  display: flex;
  justify-content: space-between;

  button {
    font-size: 13px;
  }
}

.chipDetailsContainer__withBorder {
  border: 0.5px rgb(177, 177, 177) solid;
  border-radius: 8px;
}

.newBadge {
  .newBadge();
  top: 4px;
  left: 6px;
}

.productContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.map {
  height: @heroMobileHeight;
  @media @sm {
    height: @heroHeight;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;