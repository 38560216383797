.productCircles {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 4px;
}

.product {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  max-height: 48px;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  svg {
    color: #fb2422;
    position: absolute;
  }
}

.remainingProductCount {
  display: grid;
  place-content: center;
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px;
  margin-left: -8px;
  border: 1px solid #dcdcdc;
  font-size: 16px;
  color: #657d7d;
  background-color: #fff;
}

.product:not(:last-child) {
  margin-left: -8px;
  background-color: #fff;
}

.product .img {
  width: 100%;
  display: block;
  background-color: #fff;
}

.product__hotbait {
  .product();
  width: 64px;
  max-height: 64px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;