@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

.img {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  margin-bottom: 16px;
  aspect-ratio: 412 / 300;
}

.name {
  .typography(@font-size: 16px, @line-height: 22px, @color: #006b99);
  text-decoration: underline;
  margin-bottom: 0;
}

.locations {
  .typography(@font-size: 14px, @line-height: 22px, @color: @faded_text_color);
  margin-bottom: 0;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;