@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.colorImageButtonContainer {
  padding-right: 2%;
  flex-basis: 20%;
  padding-bottom: 2.5px;
  cursor: pointer;

  img {
    border-radius: 8px;
  }
  @media @sm {
    flex-basis: clamp(65px, 10.95vw, 87px);
  }
  @media @md {
    flex-basis: clamp(65px, 8.8vw, 87px);
  }
  @media @lg {
    flex-basis: clamp(63px, 6.4vw, 87px);
  }
}

.colorsWrapper {
  margin-top: 14px;
  display: flex;
  flex-wrap: wrap;
}

.colorLabel {
  display: block;
  position: relative;
}
.optionSelected {
  position: relative;
  img {
    box-shadow: 0 0 0 2px #e84b25;
    z-index: 1;
    color: #e84b25;
    border-radius: 8px;
  }
}
.optionUnavailable {
  opacity: 0.2;
}

.optionRadio {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;