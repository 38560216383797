@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

.list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  @media @sm {
    justify-content: unset;
    margin: 1px 0 0 -12px;
  }
}

.listItem {
  & + & {
    @media @micro {
      margin-left: 8px;
    }
  }
}

.link {
  .roboto(500);
  color: @omnia_blue_primary;
  .antialias();
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 8px 6px;
  @media @micro {
    padding: 10px;
  }
  @media @sm {
    padding: 8px 12px;
  }
  @media @md {
    padding-inline: clamp(4px, 1vw, 12px);
  }

  &:hover,
  &.link__selected {
    color: @omnia_orange_primary;
    text-decoration: underline !important;
  }

  .icon {
    color: #e84b25;
    min-width: 16px;
    font-size: 16px;
    display: block;
  }
}

.link__map {
  @media @sm {
    padding-left: 12px;
  }
}

.icon__map {
  color: #e84b25;
  min-width: 16px;
  width: 16px;
  margin-right: -1.5px;
}

.mediaIcon {
  margin-top: 2px;
  @media @sm {
    margin-top: 0;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;