@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/media.less';

.promoBar {
  background-color: #e84b25;
  color: #fff;
  text-align: center;
  .termina(500);
  font-size: 12px;
  max-height: 14dvh;
  p {
    margin-bottom: 8px;
  }

  a {
    .textColor(#fff);
    text-decoration: underline;
  }

  :global {
    .flicking-arrow-prev,
    .flicking-arrow-next {
      width: 38px;
      height: 38px;
    }
    .flicking-arrow-prev::before,
    .flicking-arrow-prev::after,
    .flicking-arrow-next::before,
    .flicking-arrow-next::after {
      width: 14px;
    }
    .flicking-arrow-prev::before,
    .flicking-arrow-prev::after {
      left: 11px;
    }
    .flicking-arrow-next::before,
    .flicking-arrow-next::after {
      right: 11px;
    }
    .flicking-arrow-prev::before,
    .flicking-arrow-prev::after,
    .flicking-arrow-next::before,
    .flicking-arrow-next::after {
      background-color: #fff;
    }
    .flicking-arrow-disabled {
      opacity: 0.4;
    }
    .flicking-arrow-disabled::before,
    .flicking-arrow-disabled::after,
    .flicking-arrow-disabled::before,
    .flicking-arrow-disabled::after {
      background-color: #fff;
    }
    .flicking-camera {
      min-height: 54px;
      @media @sm {
        min-height: unset;
      }
    }
  }
}

.promoBarContent {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.promoBarPanel {
  padding: 8px 22px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media @sm {
    padding: 8px 0 0;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;