@gutter: 24px;

.marginBottom__gutter {
  margin-bottom: @gutter;
}
.marginTop__gutter {
  margin-top: @gutter;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textCapitalize {
  text-transform: capitalize;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;