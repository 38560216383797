@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/media.less';

.proCheck {
  .flexColumn(@align-items: center);
  width: 100%;
  margin-left: auto;
  background: #2ff3ff;
  padding: 24px 12px;
}

.standardCheck {
  .flexColumn(@align-items: center);
  padding: 12px;
  width: 87px;

  @media @sm {
    width: 100px;
  }
}

.comparisonTable {
  li {
    line-height: 22px;
    display: flex;
    align-items: center;

    span {
      .roboto(400);
      font-size: clamp(15px, 2.5vw, 17px);
      line-height: 1.2;
    }
  }
  li:not(:last-child) {
    border-bottom: 1px solid #fff;
  }

  li:last-child {
    .proCheck {
      padding: 12px 0;
      border-radius: 0 0 20px 20px;
    }
  }
}

.header.header {
  justify-content: flex-end;
  p {
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    width: 87px;
    line-height: 1.2;

    @media @sm {
      font-size: 15px;
      width: 100px;
    }
  }

  p:first-of-type {
    background: #2ff3ff;
    border-radius: 20px 20px 0 0;
    padding: 12px 6px 6px;
  }
}

.comparisonHeading {
  .roboto(500);
  color: #fff;
  background: #030e1e;
  font-size: clamp(15px, 2.5vw, 18px);
  line-height: 22px;
  display: block;
  padding-block: 8px;
  border-radius: 5px;
  padding-left: 6px;
  margin-inline: -6px;
  @media @sm {
    padding-left: 24px;
    margin-inline: -24px;
  }
}

.item {
  flex: 1;
  display: block;
}

.itemText {
  display: block;
  padding-right: 8px;
  @media @sm {
    padding-right: 32px;
  }
}

.proCheckContainer {
  display: flex;
  flex: 0 0 87px;
  @media @sm {
    flex: 0 0 100px;
  }

  svg {
    background-color: #fff;
    border-radius: 50%;
    min-width: 24px;
  }
}

.checkmarkBlurb.checkmarkBlurb {
  font-size: 11px;
  line-height: 1.2;
  font-weight: 300;
  text-align: center;
  margin-block: 9px -9px;
}

.checkmarkBlurb__pro.checkmarkBlurb__pro {
  font-size: 11px;
  line-height: 1.2;
  font-weight: 300;
  text-align: center;
  margin-block: 9px -9px;

  @media @sm {
    font-size: 12px;
    margin-left: 0;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;