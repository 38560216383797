@import (reference) '../../styles/media.less';

.listDetails {
  display: flex;
  flex-direction: column;
  padding-inline-start: 0;
  margin-bottom: 0;
  li {
    line-height: unset;
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    font-size: 14px;
    @media @sm {
      font-size: 14.5px;
    }

    p {
      margin-bottom: 4px;
    }
  }

  li:last-of-type {
    border-bottom: none;
  }

  .listDetail {
    .detail {
      display: flex;
      flex-wrap: wrap;
    }
    img {
      width: 40px;
      margin-right: 6px;
    }
  }

  .technique {
    p:last-child {
      text-align: right;
    }
    p:first-child {
      padding-right: 12px;
    }
  }
}

.reportProperty {
  font-weight: 600;
}

.seasonIcon {
  position: relative;
  margin-right: 7px;
}

.waterTemp {
  color: #657d7d;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 1px;
  p {
    margin-bottom: 0;
    display: flex;
  }
  .thermIcon {
    margin-top: 2px;
  }
}

.subDetails {
  display: flex;
  align-items: center;
  gap: 0 15px;
  padding-bottom: 16px;
  min-width: 290px;
}

.waterTempMobile {
  display: flex;
  .thermIcon {
    margin-top: 4px;
    margin-right: 3px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;